@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';
@use './settings/animation';

.instrument-nav {
  background-color: brand.$grey-35;

  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    opacity: 0;
    transition: opacity 375ms 500ms ease;

    .animated & {
      opacity: 1;
    }

    @include mq(bp-sm) {
      justify-content: center;
    }
  }

  &__item {
    padding: 15px 20px;
    display: block;
    color: brand.$white;
    white-space: nowrap;
    font-size: 16px;
    position: relative;

    &:focus {
      color: brand.$white;
      text-decoration: none;
    }

    @include mq(bp-md) {
      &:hover {
        color: brand.$green;
        text-decoration: none;

        &:after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 4px;
          background-color: brand.$green;
        }
      }
    }

    @include mq($until: bp-md) {
      &:hover {
        color: brand.$white;
        text-decoration: none;
      }
    }

    &.active {
      color: brand.$green;
      text-decoration: none;

      &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 4px;
        background-color: brand.$green;
      }
    }
  }
}
