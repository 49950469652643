@use '~sass-mq/mq' as *;
@use '../settings/brand';
@use '../settings/layers';
@use '../helpers/fonts';
@use '../helpers/global';

.tag-runtime {
  text-align: left;

  &--1,
  &--2,
  &--3 {
    @include fonts.lato-black;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-style: italic;
    line-height: 1;
  }

  &--1 {
    font-size: 17px;
    padding-left: 3px;
    margin-bottom: -5px;

    @include mq($from: bp-sm) {
      font-size: 34px;
    }
  }

  &--2 {
    color: brand.$brand-cddb00;
    font-size: 50px;
    line-height: 0.9;

    @include mq($from: bp-sm) {
      font-size: 100px;
    }

    &:after {
      content: '*';
      display: inline-block;
      font-size: 20px;
      vertical-align: top;
      padding-left: 5px;
    }
  }

  &--3 {
    font-size: 12px;
    padding-left: 3px;

    @include mq($from: bp-sm) {
      font-size: 24px;
    }
  }
}

.tag-cuts {
  text-align: left;

  &--1,
  &--2,
  &--3 {
    @include fonts.lato-black;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-style: italic;
    line-height: 1;
  }

  &--1 {
    margin-bottom: -5px;
    font-size: 40px;
    line-height: 0.9;

    @include mq($from: bp-sm) {
      font-size: 80px;
    }
  }

  &--2 {
    font-size: 25px;
    color: brand.$brand-cddb00;

    @include mq($from: bp-sm) {
      font-size: 50px;
    }
  }

  &--3 {
    font-size: 14px;
    padding-left: 3px;
    font-weight: normal;
    text-transform: none;

    span {
      color: brand.$brand-cddb00;
    }
  }
}

.tag-cuts-to {
  text-align: left;

  &--1,
  &--2 {
    @include fonts.lato-black;
    margin-bottom: 0px;
    font-style: italic;
    line-height: 1;
  }

  &--1 {
    font-size: 17px;
    padding-left: 3px;
    margin-bottom: -5px;
    text-transform: uppercase;

    @include mq($from: bp-sm) {
      font-size: 34px;
    }
  }

  &--2 {
    color: brand.$brand-cddb00;
    font-size: 50px;
    line-height: 0.9;

    @include mq($from: bp-sm) {
      font-size: 100px;
    }

    em {
      color: brand.$white;
      font-size: 20px;

      @include mq($from: bp-sm) {
        font-size: 40px;
      }
    }
  }
}

.tag-generic {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  text-align: center;
  align-items: center;

  &--1,
  &--2,
  &--3 {
    @include fonts.lato-black;
    margin-bottom: 0px;
    font-style: italic;
    line-height: 1;
  }

  &--1 {
    font-size: 17px;
    padding-left: 3px;
    margin-bottom: 4px;
    text-align: center;

    @include mq($from: bp-sm) {
      font-size: 34px;
      padding-left: 3px;
      margin-bottom: 8px;
    }

    sup {
      font-size: 50%;
      line-height: 0;
      position: relative;
      vertical-align: middle;
    }
  }

  &--2 {
    color: brand.$brand-cddb00;
    line-height: 0.9;
    font-size: 35px;

    @include mq($from: bp-sm) {
      font-size: 90px;
    }

    sub,
    em {
      position: static;

      font-size: 14px;
      margin-left: -7px;

      @include mq($from: bp-sm) {
        font-size: 28px;
        margin-left: -20px;
      }
    }
  }

  &--3 {
    font-size: 25px;

    @include mq($from: bp-sm) {
      font-size: 50px;
    }
  }

  .log-cover {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 15px 0px;

    @keyframes log-left {
      0% {
        transform: translateX(0px);
      }
      10% {
        transform: translateX(0px);
      }
      40% {
        transform: translateX(20px) translateX(29%);
      }
      60% {
        transform: translateX(20px) translateX(29%);
      }
      90% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(0px);
      }
    }

    @keyframes log-right {
      0% {
        transform: translateX(0px);
      }
      10% {
        transform: translateX(0px);
      }
      40% {
        transform: translateX(-20px) translateX(-29%);
      }
      60% {
        transform: translateX(-20px) translateX(-29%);
      }
      90% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(0px);
      }
    }

    @keyframes inner-circle {
      0% {
        opacity: 1;
      }
      35% {
        opacity: 1;
      }
      40% {
        opacity: 0;
      }
      60% {
        opacity: 0;
      }
      65% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }

    .log-left {
      margin-right: 20px;
      animation: log-left 4000ms 0ms ease forwards infinite;
      position: relative;
      z-index: 2;
      align-self: flex-end;
      margin-top: auto;
      max-width: 100%;

      @include mq($from: bp-sm) {
        max-width: 101px;
      }

      /* IE - */
      @media all and (-ms-high-contrast: none) {
        height: 130px;
      }

      .inner-circle {
        animation: inner-circle 4000ms 0ms linear forwards infinite;
      }
    }

    .log-right {
      margin-left: 20px;
      animation: log-right 4000ms 0ms ease forwards infinite;
      position: relative;
      z-index: 1;
      align-self: flex-end;
      margin-top: auto;
      max-width: 100%;

      @include mq($from: bp-sm) {
        max-width: 120px;
      }

      .inner-circle {
        animation: inner-circle 4000ms 0ms linear forwards infinite;
      }
    }
  }

  .grass-cover {
    height: 62px;
    width: 164px;

    @include mq(bp-sm) {
      height: 62px;
      width: 164px;
    }
  }

  svg {
    fill: brand.$brand-primary;
    transform: translateZ(0);

    .saw-spin {
      will-change: transform;
      animation: saw-spin 2000ms 0ms linear infinite;
      transform: rotate(0deg);
      transform-origin: 50% 50% 0;
    }

    @keyframes saw-spin {
      100% {
        transform: rotate(-360deg);
      }
    }

    .grass-sway {
      will-change: transform;
      animation: grass-sway 5000ms 0ms ease-in-out infinite;
      transform-origin: 50% 100% 0;
      transform: skew(20deg);

      &--1 {
        animation-delay: 800ms;
      }
      &--2 {
        animation-delay: 400ms;
      }
    }

    @keyframes grass-sway {
      0%,
      100% {
        transform: skew(20deg);
      }
      50% {
        transform: skew(-15deg);
      }
    }
  }
}
