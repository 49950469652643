@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use './settings/animation';
@use './settings/brand';
@use './settings/breakpoints' as *;

.classification {
  background-color: brand.$grey-dark;

  &__container {
    padding: global.vr(6) global.vr(4);

    @include mq($until: bp-xs) {
      padding: global.vr(6) global.vr(1);
    }
  }

  &__title {
    @include animations.fade-up($mode: self, $delay: 0.2s);
    @include fonts.lato-semi-bold();
    font-size: 40px;
    line-height: 48px;
    color: brand.$green;
    text-align: center;
    padding-bottom: global.vr(6);
  }

  &__content {
    display: flex;
    justify-content: space-between;

    @include mq($until: bp-sm) {
      flex-direction: column;
      align-items: center;
    }

    @include mq($until: bp-xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__left {
    @include animations.fade-right($mode: self, $delay: 0.2s);
    width: 100%;
    max-width: 400px;

    @include mq(bp-sm) {
      padding-right: global.vr(10);
    }

    &-content {
      @include fonts.lato-regular();
      font-size: 16px;
      line-height: 26px;
      color: brand.$white;
      padding-bottom: global.vr(2);
    }

    &-title {
      @include fonts.lato-regular();
      font-size: 16px;
      line-height: 26px;
      color: brand.$white;
      padding-bottom: global.vr(2);
    }
  }

  &__filters {
    display: flex;

    @include mq($until: bp-sm) {
      flex-wrap: wrap;
    }
  }

  &__filter {
    @include fonts.lato-black();
    font-size: 18px;
    color: brand.$white;
    border: 1px solid brand.$white;
    padding: global.vr(0.5) global.vr(2);
    border-radius: 50px;
    margin-bottom: global.vr(2);
    margin-right: global.vr(2);
    cursor: pointer;

    &.is-active {
      border-color: brand.$green;
      background-color: brand.$green;
      color: brand.$grey-max-power;
    }
  }

  &__table-container {
    position: relative;
    height: 225px;
  }

  &__table-overview {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.6s animation.$ease-in-out-cubic;

    &.is-active {
      opacity: 1;
    }

    &-content {
      display: flex;
    }

    &-level,
    &-remove {
      width: 100%;
    }

    &-header {
      @include fonts.lato-black();
      font-size: 16px;
      line-height: 22px;
      color: brand.$grey-max-power;
      background-color: brand.$green;
      padding: global.vr(1) global.vr(1);
      display: flex;
    }

    &-header-text {
      width: 50%;
    }

    &-row {
      display: flex;
      @include fonts.lato-bold();
      font-size: 14px;
      line-height: 21px;
      color: brand.$grey-max-power;
      padding: global.vr(1) global.vr(1);
      background-color: #f4f4f4;

      &:nth-child(2n) {
        background-color: brand.$white;
      }
    }

    &-col {
      flex-basis: 50%;
    }
  }

  &__table {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.6s animation.$ease-in-out-cubic;
    background-color: brand.$white;
    padding: global.vr(3);

    &.is-active {
      opacity: 1;
    }

    &-header {
      @include fonts.lato-black();
      color: brand.$green;
      font-size: 21px;
      line-height: 22px;
      padding-bottom: global.vr(1);
    }

    &-content {
      display: flex;
    }

    &-title {
      @include fonts.lato-black();
      color: brand.$grey-max-power;
      font-size: 18px;
      line-height: 22px;
    }

    &-content {
      @include fonts.lato-bold();
      color: brand.$grey-max-power;
      font-size: 14px;
      line-height: 21px;
    }
  }

  &__graph {
    @include animations.fade-left($mode: self, $delay: 0.2s);
    width: 100%;
    max-width: 715px;

    &-inner {
      position: relative;
    }

    &-line {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: brand.$white;
    }

    &-level {
      position: absolute;
      transition: left 0.4s animation.$ease-in-out-cubic 0.4s;

      &.is-active {
        &.classification__graph-level--1A,
        &.classification__graph-level--1B,
        &.classification__graph-level--2,
        &.classification__graph-level--3,
        &.classification__graph-level--4 {
          left: 50%;
        }

        .classification__graph-level-text {
          opacity: 1;
        }

        svg {
          &#level-1A,
          &#level-1B,
          &#level-2,
          &#level-3,
          &#level-4 {
            transform: scaleY(1);
          }
        }
      }

      &-text {
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 50%);
        @include fonts.lato-black();
        color: brand.$grey-max-power;
        font-size: 18px;
        line-height: 22px;
        opacity: 0;
        transition: opacity 0.3s animation.$ease-in-out-cubic;
      }

      &--1A {
        left: 50%;

        @include mq($until: bp-xxs) {
          left: 62.5%;
        }
      }

      &--1B {
        left: 80%;

        @include mq($until: bp-xxs) {
          left: 90%;
        }
      }

      &--2 {
        left: 25%;

        @include mq($until: bp-xxs) {
          left: 35%;
        }
      }

      &--3 {
        left: 40%;

        @include mq($until: bp-xxs) {
          left: 50%;
        }

        .classification__graph-level-text {
          color: brand.$white;
        }
      }

      &--4 {
        left: 65%;

        @include mq($until: bp-xxs) {
          left: 75%;
        }

        .classification__graph-level-text {
          color: brand.$white;
        }
      }
    }

    &-lines {
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 3;

      &.is-active {
        .classification__graph-level-text {
          opacity: 1;
        }
        svg {
          transform: scaleY(1);
        }
      }

      svg {
        stroke: brand.$white;
        stroke-width: 4;
        fill: transparent;
        transform: scaleY(0);
        transition: transform 0.4s animation.$ease-in-out-cubic;
        transform-origin: top;

        &#level-1A {
          width: 30px;

          @include mq($until: bp-xs) {
            width: 20px;
            height: 36px;
          }
        }

        &#level-1B {
          width: 50px;

          @include mq($until: bp-xs) {
            width: 26px;
            height: 65px;
          }
        }

        &#level-2 {
          width: 64px;

          @include mq($until: bp-xs) {
            width: 35px;
            height: 136px;
          }
        }

        &#level-3 {
          width: 40px;

          @include mq($until: bp-xs) {
            width: 22px;
            height: 200px;
          }
        }

        &#level-4 {
          width: 60px;

          @include mq($until: bp-xs) {
            width: 34px;
            height: 265px;
          }
        }
      }
    }
  }

  &__category {
    width: 100%;
    position: relative;
    height: 100px;

    @include mq($until: bp-xs) {
      height: 80px;
    }

    &--one {
      background-color: #dfe97f;
    }

    &--two {
      background-color: #cedc35;
    }

    &--three {
      background-color: #859023;

      .classification__category-title {
        color: brand.$white;
      }
    }

    &--four {
      background-color: #7f7f7f;

      .classification__category-title {
        color: brand.$white;
      }
    }

    &-title {
      @include fonts.lato-black();
      font-size: 21px;
      line-height: 22px;
      color: brand.$grey-max-power;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;

      @include mq($until: bp-xs) {
        font-size: 14px;
        left: 15px;
      }
    }
  }
}
