@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './helpers/fonts';

.skewed-title {
  position: relative;
  display: inline-flex;
  align-self: flex-start;
  transform: skewX(-10deg);
  margin-left: 12px;
  margin-bottom: 10px;
  background: brand.$brand-cddb00;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: -12px;
    height: 100%;
    width: 24px;
    transform: skewX(10deg);
    background: brand.$brand-cddb00;
  }

  &__inner {
    transform: skewX(10deg);
    padding: 20px 24px 20px 4px;

    @include mq(bp-xs) {
      padding: 20px 34px 20px 14px;
    }

    @include mq(bp-md) {
      padding: 35px 60px 35px 40px;
    }
  }

  &__text {
    @include fonts.lato-black;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 33px;

    @include mq(bp-xs) {
      font-size: 35px;
      line-height: 42px;
    }

    @include mq(bp-md) {
      font-size: 47px;
      line-height: 59px;
    }
  }
}
