@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use '../settings/animation';
@use './settings/brand';
@use './settings/breakpoints' as *;

.split-competition {
  &__banner {
  }

  &__banner-image {
    @include animations.image-fade($mode: parent);
    width: 100%;
  }

  &__container {
    background-size: cover;
    background-position-y: bottom;
  }

  &__header {
    position: relative;
    z-index: 3;
    padding: global.vr(4) global.vr(6);
    overflow: hidden;
    width: auto;
    display: inline-block;

    &.animated {
      &::after {
        width: 100%;
      }
    }

    @include mq($until: bp-xs) {
      padding: global.vr(2) global.vr(4);
    }

    &--title {
      @include animations.fade-up($mode: parent, $delay: 0.5s);

      @include fonts.lato-semi-bold();
      color: brand.$white;
      font-size: 42px;
      line-height: 55px;
      position: relative;
      z-index: 1;
      display: inline-block;

      @include mq($until: bp-xs) {
        font-size: 30px;
        line-height: 43px;
      }

      @include mq($until: bp-xxs) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background: #4e4e50;
      transform-origin: 100% 0;
      transform: skew(-25deg);
      transition: width 0.5s animation.$ease-in-out-cubic;

      @include mq($until: bp-xs) {
        transform: skew(-10deg);
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    padding: global.vr(4) global.vr(6) global.vr(12);

    @include mq($until: bp-sm) {
      flex-direction: column;
    }

    @include mq($until: bp-xs) {
      padding: global.vr(4) global.vr(2) global.vr(12);
    }

    &--column {
      &:nth-child(1) {
        flex: 1 0 40%;
        @include mq($until: bp-sm) {
          order: 2;
        }
      }

      &:nth-child(2) {
        flex: 1 0 60%;
        @include mq($until: bp-sm) {
          order: 1;
        }
      }
    }
  }

  &__intro {
    &--title {
      @include animations.fade-up($mode: parent, $delay: 0.3s);
      @include fonts.lato-bold();
      font-size: 32px;
      padding-bottom: global.vr(3);
    }

    &--content {
      @include animations.fade-up($mode: parent, $delay: 0.3s);
      @include fonts.lato-regular();
      font-size: 16px;
      line-height: 26px;
      padding-bottom: global.vr(5);
    }
  }

  &__image-container {
    position: sticky;
    top: 50px;
    padding-left: global.vr(6);
    max-width: 650px;

    @include mq($until: bp-sm) {
      margin-bottom: global.vr(2.5);
      padding-left: 0;
    }
  }

  &__image {
    @include animations.image-fade($mode: parent);
    width: 90%;
    @include mq($until: bp-sm) {
      width: 64%;
    }
  }

  &__image-tag {
    position: absolute;
    left: 65%;
    top: 20%;
    z-index: -1;
    padding: global.vr(1) global.vr(6);
    overflow: hidden;
    max-width: 260px;
    width: 100%;
    display: inline-block;

    .animated & {
      &::after {
        width: 100%;
      }
    }

    @include mq($until: bp-xs) {
      display: inline-flex;
      padding: 8px 32px 6px;
      max-width: 56%;
      width: auto;
    }

    @include mq($until: bp-sm) {
      left: 44%;
    }

    &--title {
      @include animations.fade-up($mode: parent, $delay: 0.6s);
      @include fonts.lato-semi-bold();
      color: brand.$white;
      font-size: 40px;
      line-height: 60px;
      position: relative;
      z-index: 1;
      display: inline-block;

      @include mq($until: bp-xs) {
        font-size: 13px;
        line-height: 17px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background: #4e4e50;
      transform-origin: 100% 0;
      transform: skew(-25deg);
      transition: width 0.5s animation.$ease-in-out-cubic;
    }
  }

  &__form {
    &-container {
      @include animations.fade-up($mode: parent, $delay: 0.5s);
      background-color: brand.$white;
      padding: global.vr(2) global.vr(4);
      box-shadow: 0px 3px 6px rgba(brand.$black, 0.16);

      @include mq($until: bp-xs) {
        padding: global.vr(2) global.vr(2);
      }
    }

    &-header {
      position: relative;
      z-index: 3;
      padding: global.vr(1) global.vr(5);
      overflow: hidden;
      width: auto;
      display: inline-block;
      left: -40px;

      .animated & {
        &::after {
          width: 100%;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #575757;
        transform-origin: 100% 0;
        transform: skew(-25deg);
        transition: width 0.5s animation.$ease-in-out-cubic;
      }
    }

    &-title {
      @include animations.fade-up($mode: parent);
      @include fonts.lato-semi-bold();
      color: brand.$white;
      font-size: 24px;
      line-height: 29px;
      position: relative;
      z-index: 1;
      display: inline-block;
      vertical-align: middle;
    }

    &-sub-title {
      @include animations.fade-up($mode: parent, $delay: 0.5s);
      @include fonts.lato-bold();
      color: brand.$grey-max-power;
      font-size: 14px;
      line-height: 20px;
      padding: global.vr(3) 0;
    }

    &-fields {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;

      .ds-form__field {
        @include animations.fade-up($mode: parent, $delay: 0.5s);
        .ds-form__label {
          font-size: 12px;
          line-height: 20px;
          margin-bottom: global.vr(1);
        }
      }

      .ds-btn {
        margin-bottom: -40px;
      }
    }
  }

  &__question {
    @include animations.fade-up($mode: parent, $delay: 0.3s);
    @include fonts.lato-semi-bold();
    color: brand.$grey-max-power;
    font-size: 26px;
    line-height: 34px;
    padding-bottom: global.vr(2);
  }

  &__question-container {
    @include animations.fade-up($mode: parent, $delay: 0.3s);
    input[type='radio'] {
      display: none;

      + label {
        cursor: pointer;
        position: relative;
        padding-left: 34px;
        font-size: 12px;
        line-height: 20px;
        color: brand.$grey-20;
        @include fonts.lato-regular();

        &:before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          padding: 5px;
          border-radius: 50%;
          border: 1px solid brand.$green;
          background-color: brand.$white;
        }
      }

      &:checked + label:before {
        background-color: brand.$green;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
