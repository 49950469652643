@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use './settings/animation';
@use './settings/brand';
@use './settings/breakpoints' as *;

.ds-calendar-tabs {
  padding: global.vr(10) 0 0;
  &__intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    padding-bottom: global.vr(3);
    svg {
      width: 54px;
      height: 50px;
    }
  }

  &__title {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-black();
    font-size: 30px;
    line-height: 34px;
    color: brand.$grey-max-power;
    padding-bottom: global.vr(3);
    max-width: 820px;
    text-align: center;

    &--green {
      color: brand.$green;
    }
  }

  &__content {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-regular();
    font-size: 16px;
    line-height: 26px;
    color: brand.$grey-max-power;
    padding-bottom: global.vr(5);
    max-width: 820px;
    text-align: center;

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &__season-ribbon {
    @include animations.fade-up($mode: self, $delay: 0.2s);
    width: 100%;
    display: flex;
    align-items: center;
    background-color: brand.$green;
  }

  &__season-container {
    max-width: 880px;
    padding: 30px 0 15px;
  }

  &__season {
    @include animations.fade-right($mode: parent, $delay: 0.6s);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq($until: bp-xxs) {
      padding: 0 global.vr(2);
    }

    img {
      max-width: 150px;
    }

    &-icon {
      width: 84px;
      height: 84px;

      @include mq($until: bp-xxs) {
        width: 76px;
        height: 76px;
      }
      &::before {
        content: '';
        position: absolute;
        width: 84px;
        height: 84px;
        background-repeat: no-repeat;

        @include mq($until: bp-xxs) {
          width: 76px;
          height: 76px;
        }
      }

      .ds-calendar-tabs__season--spring & {
        &::before {
          background-image: url('~@img/icons/scarifier/icon-spring.svg');
        }
      }
      .ds-calendar-tabs__season--summer & {
        &::before {
          background-image: url('~@img/icons/scarifier/icon-summer.svg');
        }
      }
      .ds-calendar-tabs__season--autumn & {
        &::before {
          background-image: url('~@img/icons/scarifier/icon-autumn.svg');
        }
      }
      .ds-calendar-tabs__season--winter & {
        &::before {
          background-image: url('~@img/icons/scarifier/icon-winter.svg');
        }
      }
    }

    &-content {
      @include fonts.lato-bold();
      font-size: 21px;
      line-height: 28px;
      color: brand.$grey-max-power;
      margin-left: global.vr(3);
      max-width: 245px;
    }
  }

  &__indicators {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
  }

  &__indicator {
    @include animations.fade-right($mode: parent, $delay: 0.6s);
    padding: global.vr(1) global.vr(6);
    border: 2px solid brand.$white;
    box-shadow: 0 3px 6px rgba(brand.$black, 0.16);
    background-color: brand.$grey-max-power;
    width: auto;
    min-width: 150px;
    margin-bottom: global.vr(2);

    &-content {
      position: relative;
      @include fonts.lato-bold();
      font-size: 16px;
      color: brand.$white;

      &::before {
        content: '';
        position: absolute;
        width: 11px;
        height: 11px;
        background-image: url('~@img/icons/scarifier/icon-white-cross.svg');
        left: -20px;
        top: 7px;
        background-repeat: no-repeat;
      }
    }

    &.active {
      .ds-calendar-tabs__indicator-content {
        color: brand.$grey-max-power;

        &::before {
          content: '';
          width: 14px;
          height: 11px;
          background-image: url('~@img/icons/scarifier/icon-black-tick.svg');
        }
      }

      background-color: #cad94a;
    }
  }

  &__tab-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    padding: global.vr(4) global.vr(1) global.vr(8);
  }

  &__find-out-more {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-regular();
    font-size: 14px;
    color: #575757;
    margin-bottom: global.vr(1);
  }

  &__tabs {
    width: 100%;
    max-width: 820px;
    display: flex;
  }

  &__tab {
    @include fonts.lato-bold();
    font-size: 12px;
    flex: 1 0 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: global.vr(2) 0;
    background-color: brand.$green;
    cursor: pointer;
    user-select: none;
    color: brand.$grey-max-power;

    &:nth-child(1) {
      @include animations.fade-up($mode: parent, $delay: 0.2s);
    }
    &:nth-child(2) {
      @include animations.fade-up($mode: parent, $delay: 0.6s);
    }
    &:nth-child(3) {
      @include animations.fade-up($mode: parent, $delay: 1s);
    }
    &:nth-child(4) {
      @include animations.fade-up($mode: parent, $delay: 1.4s);
    }

    &.active {
      background-color: brand.$white;
    }

    &-title {
      text-align: center;
      padding: 0 20px;
      line-height: 15px;
      text-transform: uppercase;
      @include mq($until: bp-xs) {
        display: none;
      }
    }

    svg {
      width: 57px;
      height: 57px;
      margin-bottom: global.vr(1);

      &.svg--large {
        width: 100px;
        height: 100px;
      }
    }

    img {
      max-width: 100px;
    }
  }

  &__tab-items {
    @include animations.fade-up($mode: parent, $delay: 1.6s);
    width: 100%;
    max-width: 820px;
    background-color: brand.$white;
    overflow: hidden;
  }

  &__item {
    opacity: 0;
    height: 0;
    visibility: hidden;
    transition: opacity 0.3s animation.$ease-in-out-cubic, height 0.3s animation.$ease-in-out-cubic;

    &.active {
      padding: global.vr(4) global.vr(4);
      opacity: 1;
      height: auto;
      visibility: visible;
    }

    &-mobile-tab-title {
      display: block;
      @include fonts.lato-bold();
      font-size: 12px;
      padding-bottom: global.vr(3);

      @include mq(bp-xs) {
        display: none;
      }
    }

    &-icons {
      display: flex;
      margin-bottom: global.vr(2);
    }

    &-icon {
      margin-right: global.vr(1);
      img {
        width: 100%;
        max-width: 44px;
      }
    }

    &-title {
      @include fonts.lato-black();
      font-size: 24px;
      line-height: 30px;
      color: brand.$grey-max-power;
      padding-bottom: global.vr(2);

      &--green {
        color: brand.$green;
      }
    }

    &-content {
      @include fonts.lato-regular();
      font-size: 16px;
      line-height: 24px;
      color: brand.$grey-max-power;

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
