@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.mp-split {
  position: relative;
  display: block;
  background: brand.$brand-cddb00 url('~@img/svg/36v-bg.svg') no-repeat center center;
  background-size: cover;
  color: brand.$grey-max-power;
  overflow: hidden;
  padding: global.vr(12) 0 global.vr(10);

  &__grid-container {
    display: flex;
    flex-direction: column;

    @supports (display: grid) {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(12, 1fr);
    }

    @include mq(bp-sm) {
      display: flex;
      flex-direction: row;

      @supports (display: grid) {
        display: grid;
        gap: 28px;
        grid-template-columns: repeat(12, 1fr);
      }
    }
  }

  &__grid-column {
    min-width: auto;
    text-align: center;

    &:nth-child(1) {
      @supports (display: grid) {
        grid-column: 1 / span 12;
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      @supports (display: grid) {
        grid-column: 1 / span 12;
        margin-left: 0;
      }
    }

    @include mq(bp-sm) {
      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(2) {
        text-align: right;
      }
    }

    @include mq(bp-sm) {
      &:nth-child(1) {
        flex-basis: percentage(1/2);
        margin-right: 14px;

        @supports (display: grid) {
          grid-column: 1 / span 6;
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        flex-basis: percentage(1/2);
        margin-left: 14px;

        @supports (display: grid) {
          grid-column: 7 / span 6;
          margin-left: 0;
        }
      }
    }

    @include mq(bp-md) {
      &:nth-child(1) {
        flex-basis: percentage(1/3);
        margin-right: 14px;

        @supports (display: grid) {
          grid-column: 1 / span 4;
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        flex-basis: percentage(2/3);
        margin-left: 14px;

        @supports (display: grid) {
          grid-column: 5 / span 8;
          margin-left: 0;
        }
      }
    }
  }

  &__title-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

    @include mq(bp-sm) {
      align-items: flex-start;
      margin: 0;
    }

    &--reverse {
      flex-direction: column-reverse;
    }
  }

  &__title {
    display: block;
    @include fonts.lato-black();
    font-size: 30px;
    @include fonts.ls(-10);
    line-height: 32px;
    margin-bottom: global.vr(3);
    max-width: 380px;
    text-transform: uppercase;
  }

  &__logo {
    display: inline-block;
    width: 140px;
    margin-bottom: global.vr(3);
  }

  &__content {
    display: block;
    @include fonts.lato-regular();
    font-size: 16px;
    @include fonts.ls(20);
    line-height: 26px;
    margin: 0 auto global.vr(3);
    max-width: 380px;

    @include mq(bp-sm) {
      margin: 0 0 global.vr(3);
    }
  }

  &__disclaimer {
    font-size: 12px;
    margin-top: 50px;
  }

  &__image {
    display: inline-block;
    max-width: 100%;
    flex-shrink: 0;
    align-self: center;
  }

  &__media {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @include mq($from: bp-sm) {
      justify-content: flex-end;
    }

    @include mq($from: bp-md) {
      flex-wrap: nowrap;
    }

    &-copy {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .tag-runtime {
        margin-bottom: 24px;
      }

      @include mq($from: bp-xxs) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0px;
      }

      @include mq($from: bp-md) {
        width: 200px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__bullets {
    text-align: left;
    color: brand.$brand-cddb00;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      padding-left: 16px;

      @include mq(bp-md) {
        white-space: nowrap;
      }

      &:before {
        content: '\f067';
        font-family: 'FontAwesome';
        position: absolute;
        top: 6px;
        left: 6px;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        font-size: 12px;
        color: brand.$white;
      }
    }
  }

  &--dark {
    background-color: brand.$grey-max-power;
    color: brand.$white;
  }

  &--reverse {
    .mp-split__grid-column {
      @include mq(bp-sm) {
        &:nth-child(1) {
          order: 2;
          margin-right: 14px;
          text-align: left;

          @supports (display: grid) {
            grid-column-start: 7;
            margin-right: 0;
          }
        }

        &:nth-child(2) {
          order: 1;
          margin-left: 14px;
          text-align: left;

          @supports (display: grid) {
            grid-column-start: 1;
            margin-left: 0;
          }
        }
      }

      @include mq(bp-md) {
        &:nth-child(1) {
          @supports (display: grid) {
            grid-column-start: 9;
            margin-left: 0;
          }
        }

        &:nth-child(2) {
          @supports (display: grid) {
            grid-column-start: 1;
            margin-right: 0;
          }
        }
      }
    }
  }
}
