@use "./settings/brand";
@use "./helpers/fonts";
@use "./helpers/global";
@use './settings/animation';
@use "./settings/breakpoints" as *;
@use './helpers/mixins';

.listing-card {
  .layout--1 & {
    width: 25%;
  }

  .layout--2 & {
    width: 50%;
  }
}

.whats-new-card {
  padding: 20px;
  margin: 0;
  background-color: brand.$brand-primary;
  height: 100%;
  position: relative;

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: 0;
    background-image: url('~@img/whats-new/shards.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;

    @include mq($from: bp-sm) {
      height: 190px;
    }
  }

  &__content {
    position: relative;
    z-index: 3;

    h1 {
      font-size: 34px;
      @include fonts.lato;
      margin-bottom: 15px;
      text-transform: uppercase;
      letter-spacing: 6px;
      line-height: 1.4;
      color: brand.$grey-max-power;

      @include mq($until: bp-xs) {
        font-size: 14px;
      }
    }

    img {
      max-width: 370px;
      width: 100%;
    }
  }
}
