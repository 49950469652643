@use '../helpers/fonts';
@use '../helpers/global';

.ds-blogger-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: global.vr(5) global.vr(2);

  &__title {
    @include fonts.lato-bold;
    font-size: 33px;
    padding-bottom: global.vr(2);
  }

  &__content {
    font-size: 18px;
    max-width: 650px;
    text-align: center;
  }

  &__button {
    padding-top: 125px;
  }
}
