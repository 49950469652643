@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.mp-brushless-teaser {
  position: relative;
  background: brand.$brand-cddb00;
  padding-bottom: global.vr(13);
  overflow: hidden;

  @include mq(bp-sm) {
    padding-bottom: global.vr(9);
  }

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: -110px;
    left: 0;
    width: 100%;
    height: 194px;
    background: darken(brand.$green, 2.5%);
    transform: skewY(5deg);
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    z-index: 2;
    bottom: -112px;
    left: 0;
    width: 100%;
    height: 194px;
    background: darken(brand.$green, 5%);
    transform: skewY(-10deg);

    @include mq(bp-xs) {
      bottom: -83px;
      transform: skewY(-5deg);
    }
  }

  &__banner {
    width: 100%;
    @include mq(bp-sm) {
      display: flex;
      flex-direction: row;
    }
  }

  &__scrolling {
    position: relative;
    left: -50px;
    height: 88px;

    @include mq(bp-sm) {
      height: 128px;
    }

    &-inner {
      position: absolute;
      width: 820px;
      height: 100%;
      padding: 0 96px;
      top: 0;
      left: 0;
      white-space: nowrap;

      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 68px;
        height: 100%;
        transform: skewX(-11deg);
        background: brand.$grey-max-power;

        @include mq(bp-xxs) {
          width: 88px;
        }

        @include mq(bp-sm) {
          width: 140px;
        }
      }

      @include mq(bp-xxs) {
        padding: 0 146px;
        width: 1394px;
      }

      @include mq(bp-sm) {
        width: calc(100vw + 400px);
        min-width: 2390px;
        padding: 0 200px;
      }
    }

    &-item {
      width: calc(100% / 3);
      height: 100%;
      display: inline-block;
      transform: skewX(-11deg);
      background: brand.$grey-max-power;
      margin-right: 30px;

      @include mq(bp-xxs) {
        margin-right: 50px;
      }
    }
  }

  &__word-split {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    padding-left: 30px;
    transform: skewX(11deg);

    @include mq(bp-xxs) {
      padding-left: 76px;
    }

    &-left {
      @include fonts.lato-black-italic;
      font-size: 22px;
      padding-right: 30px;
      letter-spacing: 0.9px;
      color: brand.$green;

      @include mq(bp-xxs) {
        font-size: 34px;
        padding-right: 60px;
      }

      @include mq(bp-sm) {
        font-size: 64px;
      }
    }

    &-right {
      background: brand.$green;
      @include fonts.lato-black-italic;
      font-size: 19px;
      flex: 1;
      padding: 16px 0 16px 30px;
      margin-top: auto;
      transform: skewX(-11deg);

      @include mq(bp-xxs) {
        font-size: 26px;
        padding: 16px 0 16px 60px;
      }

      @include mq(bp-sm) {
        font-size: 56px;
        padding: 30px 0 30px 60px;
      }

      &--reverse {
        transform: skewX(11deg);
      }
    }
  }

  &__skewed-content {
    background: brand.$brand-cddb00;
    width: 100%;

    @include mq(bp-sm) {
      flex-basis: 36%;
      width: 36%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-inner {
      position: relative;
      z-index: 10;
      padding: global.vr(6);

      @include mq(bp-sm) {
        padding: global.vr(10) global.vr(4) global.vr(10) global.vr(14);
      }
    }

    h2 {
      @include fonts.lato-black;
      font-size: 24px;
      text-transform: uppercase;
    }

    .ds-btn {
      margin-top: global.vr(2);

      @include mq(bp-sm) {
        margin-top: global.vr(4);
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;

    @include mq(bp-sm) {
      flex-basis: 64%;
      width: 64%;
    }

    &:before {
      @include mq(bp-sm) {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: -92px;
        width: 180px;
        height: 100%;
        transform: skewX(-11deg);
        background: brand.$brand-cddb00;
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }

  &__icon {
    display: inline-block;
    max-height: 40px;
    width: auto;
    margin-bottom: global.vr(5);

    @include mq(bp-xs) {
      max-height: 112px;
    }
  }

  &__product-list {
    position: relative;
    z-index: 10;
    padding: global.vr(4) 0;

    @include mq(bp-xs) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: global.vr(8) 0;
    }
  }

  &__product {
    text-align: center;
    outline: none;

    @include mq(bp-xs) {
      width: 33.333%;
      flex-basis: 33.333%;
      padding: 0 global.vr(4);
    }

    &-inner {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }

    h2 {
      @include fonts.lato-black;
      font-size: 24px;
      text-transform: uppercase;
      min-height: 52px;
    }
  }

  .tns-nav {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    button {
      background: brand.$white;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin: 0 global.vr(1);
      padding: 0;

      &.tns-nav-active {
        background: brand.$grey-max-power;
      }
    }
  }
}
