@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/brand';
@use '../settings/breakpoints' as *;
@use '../settings/grid';
@use '../settings/type';

@function grid-column-flex-basis($span, $count: 12, $gap: 28px) {
  @return calc(#{percentage($span / $count)} - #{$gap});
}

@function gc-fb($span, $count: 12, $gap: 28px) {
  @return grid-column-flex-basis($span, $count, $gap);
}

.ds {
  &-section {
    position: relative;
    padding-top: global.vr(6);
    padding-bottom: global.vr(6);

    &--flush {
      margin-top: 0;
      margin-bottom: 0;
    }

    &--loose {
      @include mq(bp-md) {
        padding-top: global.vr(10);
        padding-bottom: global.vr(10);
      }
    }

    &--no-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    &--with-nav {
      padding-left: 320px;
    }

    &--bg-light {
      background: brand.$grey-95;
    }

    &--bg-primary {
      background: #c7d31b;
    }

    &--skew-both {
      transform-origin: left;
      margin-bottom: global.vr(6);
      background: inherit;

      @include mq(bp-md) {
        margin-bottom: global.vr(10);
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        transform: skewY(5deg);
        transform-origin: left;
        width: 100%;
        z-index: 1;
      }

      > * {
        position: relative;
        z-index: 2;

        &:first-child {
          margin-top: 4vw;
        }
      }

      &.ds-section--bg-light {
        &::before {
          background: brand.$grey-95;
        }
      }
    }

    &--skew-bottom {
      transform: skewY(5deg);
      transform-origin: left;
      margin-bottom: global.vr(6);

      @include mq(bp-md) {
        margin-bottom: global.vr(10);
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        transform: skewY(-5deg);
        transform-origin: left;
        width: 100%;
        z-index: -1;
      }

      > * {
        transform: skewY(-5deg);

        &:first-child {
          margin-top: -4vw;
        }
      }

      &.ds-section--bg-light {
        &::before {
          background: brand.$grey-95;
        }
      }
    }

    &--skew-top {
      margin-top: 11vw; // seems to work well...
      transform: skewY(5deg);
      transform-origin: right;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        height: 100%;
        transform: skewY(-5deg);
        transform-origin: right;
        width: 100%;
        z-index: -1;
      }

      > * {
        transform: skewY(-5deg);

        &:first-child {
          margin-bottom: -4vw;
        }
      }

      &.ds-section--bg-light {
        &::before {
          background: brand.$grey-95;
        }
      }
    }

    &__title {
      @include animations.fade-up();
      @include fonts.lato-black();
      display: block;
      color: brand.$grey-20;
      font-size: 21px;
      line-height: 26px;
      margin-bottom: global.vr(6);

      @include mq(bp-sm) {
        font-size: 32px;
        line-height: 40px;
      }

      &--center {
        text-align: center;
      }

      .ds-theme--roboyagi & {
        color: inherit;
      }
    }
  }

  &-site-wide {
    margin-left: auto;
    margin-right: auto;
    max-width: grid.$site-wide;
    padding-left: grid.$site-padding;
    padding-right: grid.$site-padding;
    width: 100%;

    @include mq(bp-md) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: flex-start;
      max-width: grid.$site-wide-md + (grid.$site-padding-md * 2);
      padding-left: grid.$site-padding-md;
      padding-right: grid.$site-padding-md;
      text-align: left;
    }

    @include mq(bp-lg) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: flex-start;
      max-width: grid.$site-wide-lg + (grid.$site-padding-md * 2);
      text-align: left;
    }
  }

  &-site-wide--narrow {
    max-width: 700px;
    width: 100%;
  }

  &-grid-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -14px;
    margin-right: -14px;

    > .ds-grid-column {
      flex: 1 1 gc-fb(12);
      padding-left: 14px;
      padding-right: 14px;
    }
  }

  &-grid-container--half {
    > .ds-grid-column:nth-child(1) {
      @include mq(bp-sm) {
        flex-basis: gc-fb(6);
      }
    }

    > .ds-grid-column:nth-child(2) {
      @include mq(bp-sm) {
        flex-basis: gc-fb(6);
      }
    }
  }

  &-grid-container--third {
    > .ds-grid-column:nth-child(1) {
      @include mq(bp-sm) {
        flex-basis: gc-fb(4);
      }
    }

    > .ds-grid-column:nth-child(2) {
      @include mq(bp-sm) {
        flex-basis: gc-fb(4);
      }
    }

    > .ds-grid-column:nth-child(3) {
      @include mq(bp-sm) {
        flex-basis: gc-fb(4);
      }
    }
  }

  &-grid-container--third-left {
    > .ds-grid-column:nth-child(1) {
      @include mq(bp-sm) {
        flex-basis: gc-fb(3);
      }
    }

    > .ds-grid-column:nth-child(2) {
      @include mq(bp-sm) {
        flex-basis: gc-fb(9);
      }
    }
  }

  &-grid-container--third-right {
    > .ds-grid-column:nth-child(1) {
      @include mq(bp-sm) {
        flex-basis: gc-fb(9, 12);
      }
    }

    > .ds-grid-column:nth-child(2) {
      @include mq(bp-sm) {
        flex-basis: gc-fb(3, 12);
      }
    }
  }

  &-grid-container--quarter {
    > .ds-grid-column {
      @include mq(bp-lg) {
        flex-basis: gc-fb(3);
      }

      @include mq($until: bp-lg) {
        flex-basis: gc-fb(4);
      }

      @include mq($until: bp-sm) {
        flex-basis: gc-fb(6);
      }

      @include mq($until: bp-xs) {
        flex-basis: gc-fb(12);
      }
    }
  }

  &-grid-container--signposts {
    > .ds-grid-column {
      @include mq(bp-lg) {
        flex-basis: gc-fb(4);
      }

      @include mq($until: bp-lg) {
        flex-basis: gc-fb(4);
      }

      @include mq($until: bp-sm) {
        flex-basis: gc-fb(6);
      }

      @include mq($until: bp-xs) {
        flex-basis: gc-fb(12);
      }
    }
  }

  @supports (display: grid) {
    &-grid-container {
      display: grid;
      gap: 28px;
      grid-template-columns: repeat(6, 1fr);
      margin-left: 0;
      margin-right: 0;

      @include mq(bp-sm) {
        grid-template-columns: repeat(12, 1fr);
      }

      > .ds-grid-column {
        grid-column: span 6;
        min-width: 0;
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        @include mq(bp-sm) {
          grid-column: span 12;
        }
      }
    }

    &-grid-container--full {
      > .ds-grid-column {
        grid-column: span 6;

        @include mq(bp-sm) {
          grid-column: span 12;
        }
      }
    }

    &-grid-container--half {
      > .ds-grid-column:nth-child(1) {
        @include mq(bp-sm) {
          grid-column: span 6;
        }
      }

      > .ds-grid-column:nth-child(2) {
        @include mq(bp-sm) {
          grid-column: span 6;
        }
      }
    }

    &-grid-container--third {
      > .ds-grid-column:nth-child(1) {
        @include mq(bp-sm) {
          grid-column: span 4;
        }
      }

      > .ds-grid-column:nth-child(2) {
        @include mq(bp-sm) {
          grid-column: span 4;
        }
      }

      > .ds-grid-column:nth-child(3) {
        @include mq(bp-sm) {
          grid-column: span 4;
        }
      }
    }

    &-grid-container--third-left {
      > .ds-grid-column:nth-child(1) {
        @include mq(bp-sm) {
          grid-column: span 3;
        }
      }

      > .ds-grid-column:nth-child(2) {
        @include mq(bp-sm) {
          grid-column: span 9;
        }
      }
    }

    &-grid-container--third-right {
      > .ds-grid-column:nth-child(1) {
        @include mq(bp-sm) {
          grid-column: span 9;
        }
      }

      > .ds-grid-column:nth-child(2) {
        @include mq(bp-sm) {
          grid-column: span 3;
        }
      }
    }

    &-grid-container--quarter {
      > .ds-grid-column {
        @include mq(bp-lg) {
          grid-column: span 3;
        }

        @include mq($until: bp-lg) {
          grid-column: span 4;
        }

        @include mq($until: bp-sm) {
          grid-column: span 6;
        }

        @include mq($until: bp-xs) {
          grid-column: span 12;
        }
      }
    }

    &-grid-container--signposts {
      > .ds-grid-column {
        @include mq(bp-lg) {
          grid-column: span 4;
        }

        @include mq($until: bp-lg) {
          grid-column: span 4;
        }

        @include mq($until: bp-sm) {
          grid-column: span 6;
        }

        @include mq($until: bp-xs) {
          grid-column: span 12;
        }
      }
    }
  }
}

.site-wide-1366 {
}

.site-wide-1920 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: grid.$site-wide-100;
  padding-left: grid.$site-padding;
  padding-right: grid.$site-padding;

  @include mq(bp-xs) {
    padding-left: (grid.$site-padding * 2);
    padding-right: (grid.$site-padding * 2);
  }

  @include mq(bp-md) {
    padding-left: grid.$site-wide-padding / 2;
    padding-right: grid.$site-wide-padding / 2;
  }

  &--85 {
    max-width: grid.$site-wide-85;
  }

  &--71 {
    max-width: grid.$site-wide-71;
  }

  &--60 {
    max-width: grid.$site-wide-60;
  }
}
