@use '../../helpers/animations';
@use '../../helpers/fonts';
@use '../../helpers/global';

@use '../../settings/brand';
@use '../../settings/breakpoints' as *;
@use '../../settings/type';

.ds-features {
  display: block;
  overflow: hidden;

  &__inner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 global.vr(-6);

    @include mq(bp-sm) {
      flex-direction: row;
    }
  }

  &__item {
    @include animations.fade-up();
    flex: 0 0 50%;
    margin-bottom: global.vr(6);

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      @include mq(bp-sm) {
        margin-bottom: 0;
      }
    }

    &-inner {
      margin: 0 global.vr(6);
    }

    &-title-wrapper {
      @include global.title-block();
    }

    &-title {
      @include fonts.lato-regular();
      color: brand.$grey-20;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: global.vr(3);
      padding-right: global.vr(7);
    }

    &-subtitle {
      @include fonts.lato-regular();
      @include fonts.ls(100);
      position: relative;
      color: brand.$grey-45;
      font-size: 13px;
      line-height: 13px;
      margin-bottom: global.vr(1);
      padding-left: global.vr(2.5);
      overflow: hidden;
      text-transform: uppercase;

      &::before {
        @include global.triangle(bottom, #c7d31b, 12px);
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-50%);
      }
    }

    &-content {
      @include fonts.lato-regular();
      color: brand.$grey-35;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
}
