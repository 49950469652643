@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-card--social {
  position: relative;
  margin-bottom: 0;
  cursor: pointer;

  .ds-card__tag {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: brand.$green;
    padding: global.vr(1) global.vr(4) global.vr(1) global.vr(2);
    @include fonts.lato-regular();
    font-size: 10px;
    @include fonts.ls(100);
    line-height: 12px;
    text-transform: uppercase;
    transform: translate(-4px, global.vr(2));
    z-index: 2;
  }

  .ds-card__icon {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    height: 30px;
    transform: translate(global.vr(-1), global.vr(-1));
    width: 30px;
    z-index: 2;

    @include mq(bp-xxs) {
      height: 44px;
      width: 44px;
    }

    svg {
      display: block;
      color: brand.$white;
      height: 30px;
      width: 30px;

      @include mq(bp-xxs) {
        height: 44px;
        width: 44px;
      }
    }
  }

  .ds-card__author {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    transform: translate(global.vr(2), global.vr(-1));
    color: brand.$white;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.6s animation.$ease-in-out-cubic;

    strong {
      display: block;
      font-size: 16px;
      line-height: 20px;
      @include fonts.lato-bold;
    }
  }

  .ds-card__media {
    position: relative;
    overflow: hidden;
    height: 100%;
    max-height: 120px;
    z-index: 1;

    @include mq(bp-xxs) {
      max-height: 300px;
    }

    &:before {
      content: ' ';
      position: absolute;
      z-index: 1;
      background-color: rgba(brand.$grey-max-power, 0.55);
      background-image: url('~@img/icons/icon-zoom.svg');
      background-position: center;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: opacity 0.6s animation.$ease-in-out-cubic;
    }

    img {
      width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      transform: scale(1);
      transition: transform 0.6s animation.$ease-in-out-cubic;
    }
  }

  &:hover {
    @include mq(bp-xs) {
      .ds-card__media:before {
        opacity: 1;
      }

      .ds-card__author {
        opacity: 1;
      }

      img {
        transform: scale(1.1);
      }
    }
  }
}
