@use '~sass-mq/mq' as *;
@use './settings/animation';
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.fs-icons {
  background-color: brand.$green;

  &--dark {
    background-color: brand.$grey-25;
  }

  &__inner {
    padding: 40px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding: global.vr(5) 0px global.vr(2);

    @include mq($from: bp-xxs) {
      padding: global.vr(9) 0px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: stretch;
    width: 100%;
    flex: 1 0 auto;
    color: brand.$grey-20;
    align-items: center;
    text-align: center;
    margin-bottom: global.vr(6);
    opacity: 0;
    transform: translate(0, 40px);
    transition: opacity 300ms ease, transform 300ms ease;
    will-change: transform;

    @include mq($from: bp-xxs) {
      width: 50%;
      margin-bottom: 0px;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          transition-delay: calc(500ms + (#{$i} * 150ms));
        }
      }

      &:nth-child(-n + 2) {
        margin-bottom: global.vr(5);
      }
    }

    @include mq($from: bp-md) {
      width: 25%;
      margin-bottom: 0px;
      transform: translate(40px, 0);

      &:nth-child(-n + 2) {
        margin-bottom: 0px;
      }
    }

    &.animated {
      opacity: 1;
      transform: translate(0);
    }

    h3,
    strong {
      @include fonts.lato-black;
      color: brand.$grey-35;
      font-size: 18px;
      max-width: 176px;

      .fs-icons--dark & {
        max-width: 242px;
      }

      @include mq(bp-xs) {
        font-size: 24px;
      }
    }

    h3 {
      text-transform: uppercase;
      margin-bottom: global.vr(2);

      .fs-icons--dark & {
        color: brand.$green;
      }
    }

    strong {
      margin-top: auto;

      .fs-icons--dark & {
        color: brand.$white;
      }
    }

    p {
      font-size: 14px;
      line-height: 21px;
      @include fonts.lato-bold;

      .fs-icons--dark & {
        color: brand.$white;
      }
    }
  }

  &__copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: stretch;
    max-width: 200px;
    margin: 0 auto;

    .fs-icons--dark & {
      max-width: 242px;
      margin: 0 auto;
    }
  }

  &__media {
    margin-bottom: global.vr(2);

    @include mq($from: bp-md) {
      margin-bottom: global.vr(5.5);
    }

    svg,
    img {
      display: block;
      margin: 0 auto;
      max-height: 75px;
      pointer-events: none;

      @include mq($from: bp-md) {
        max-height: 142px;
      }
    }

    &--vacuum {
      position: relative;

      svg {
        &.head {
          position: relative;
          z-index: layers.$layer-default;
          transform-origin: top center;
          animation: vacuum-head 1s linear forwards;
          animation-play-state: paused;
          will-change: transform;
        }

        &.dots {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          transform-origin: bottom;
          animation: vacuum-dots 1s linear forwards 100ms;
          animation-play-state: paused;
          will-change: transform;
        }
      }

      @keyframes vacuum-head {
        0% {
          transform: scale(1) translateY(0);
          transform-origin: top center;
        }
        20% {
          transform: scale(1.4) translateY(12px);
        }
      }

      @keyframes vacuum-dots {
        0% {
          transform: scaleY(1) scaleX(1);
          opacity: 1;
          transform-origin: bottom;
        }
        20% {
          transform: scaleY(1.8) scaleX(0.8);
          opacity: 0;
        }
      }

      .animated & svg,
      &:hover svg {
        &.head {
          animation-play-state: running;
          animation-delay: 1s;
        }

        &.dots {
          animation-play-state: running;
          animation-delay: 1.2s;
        }
      }

      &:hover svg {
        &.head {
          animation-iteration-count: infinite;
        }

        &.dots {
          animation-iteration-count: infinite;
        }
      }
    }

    &--flow {
      svg {
        .middle {
          transform: translate(-111.644px, -174.561px);
          transform-origin: left center;
          animation: flow-middle 2s linear forwards;
          animation-play-state: paused;
        }

        .top {
          transform: translate(-141.244px, -155.02px);
          transform-origin: left center;
          animation: flow-top 2s linear forwards 150ms;
          animation-play-state: paused;
        }

        .bottom {
          transform: translate(-141.244px, -209.55px);
          transform-origin: left center;
          animation: flow-bottom 2s linear forwards 150ms;
          animation-play-state: paused;
        }
      }

      .animated & svg {
        .middle {
          animation-play-state: running;
          animation-delay: 1s;
        }

        .top {
          animation-play-state: running;
          animation-delay: 1.15s;
        }

        .bottom {
          animation-play-state: running;
          animation-delay: 1.15s;
        }
      }

      &:hover svg {
        .middle {
          animation: flow-middle 2s linear infinite;
        }

        .top {
          animation: flow-top 2s linear infinite 150ms;
        }

        .bottom {
          animation: flow-bottom 2s linear infinite 150ms;
        }
      }

      @keyframes flow-middle {
        0% {
          transform: translate(-111.644px, -174.561px) scale(1);
          opacity: 1;
        }
        40% {
          opacity: 1;
        }
        65% {
          transform: translate(-50.644px, -85.561px) scale(0.5);
          opacity: 0;
        }
        66% {
          transform: translate(-111.644px, -174.561px) scale(1);
          opacity: 0;
        }
      }

      @keyframes flow-top {
        10% {
          transform: translate(-141.244px, -155.02px) scale(1);
          opacity: 1;
        }
        40% {
          opacity: 1;
        }
        60% {
          opacity: 0;
        }
        65% {
          transform: translate(-67.244px, -71.02px) scale(0.5);
          opacity: 0;
        }
        66% {
          transform: translate(-141.244px, -155.02px) scale(1);
          opacity: 0;
        }
      }

      @keyframes flow-bottom {
        10% {
          transform: translate(-141.244px, -209.55px) scale(1);
          opacity: 1;
        }
        40% {
          opacity: 1;
        }
        60% {
          opacity: 0;
        }
        65% {
          transform: translate(-66.244px, -103.55px) scale(0.5);
          opacity: 0;
        }
        66% {
          transform: translate(-141.244px, -209.55px) scale(1);
          opacity: 0;
        }
      }
    }

    &--gauge {
      svg {
        animation: bounce-scale 1s linear forwards;
        animation-play-state: paused;

        .pin {
          animation: pin-move 800ms linear forwards 200ms;
          animation-play-state: paused;
        }
      }

      .animated & svg {
        animation-play-state: running;
        animation-delay: 1s;

        .pin {
          animation-play-state: running;
          animation-delay: 1.2s;
        }
      }

      &:hover svg {
        animation-play-state: running;
        animation-iteration-count: infinite;

        .pin {
          animation-play-state: running;
          animation-iteration-count: infinite;
        }
      }

      @keyframes pin-move {
        0% {
          transform: translate(-1013.626 -68.858);
        }
        50% {
          transform: translate(-1027.626px, -64.858px);
        }
        100% {
          transform: translate(-1013.626 -68.858);
        }
      }

      @keyframes bounce-scale {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.75);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    &--container {
      svg {
        animation: scale-container 1s linear forwards 300ms;
        animation-play-state: paused;
      }

      .animated & svg {
        animation-play-state: running;
        animation-delay: 1s;
      }

      &:hover svg {
        animation-play-state: running;
        animation-iteration-count: infinite;
      }

      @keyframes scale-container {
        0% {
          transform: scale(1);
          transform-origin: center;
        }
        50% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    &--rpm {
      svg {
        .pin-pointer {
          transform-origin: 57% 77%;
          transform: rotate(45deg);
          animation: rpm-pointer 1s linear forwards 300ms;
          animation-play-state: paused;
        }
      }

      .animated & svg {
        .pin-pointer {
          animation-play-state: running;
          animation-delay: 1s;
        }
      }

      &:hover svg {
        .pin-pointer {
          animation-play-state: running;
          animation-iteration-count: infinite;
        }
      }

      @keyframes rpm-pointer {
        0% {
          transform: rotate(40deg);
        }
        30% {
          transform: rotate(-90deg);
        }
        40% {
          transform: rotate(-90deg);
        }
        60% {
          transform: rotate(40deg);
        }
      }
    }

    &--rotary-brushes {
      position: relative;
      $rotate-origin: center center;

      svg {
        &.frame {
          position: relative;
          top: -6px;
          z-index: 10;

          @include mq(bp-md) {
            top: -4px;
          }
        }

        &.brush {
          position: absolute;
          width: 36px;
          top: 0;
          transform-origin: $rotate-origin;
          will-change: transform;

          @include mq(bp-md) {
            width: 50%;
          }

          &--left {
            left: 35px;
            animation: rotate-brush-left 1s linear forwards 300ms;
            animation-play-state: paused;

            @include mq(bp-md) {
              left: 0;
            }
          }

          &--right {
            right: 35px;
            animation: rotate-brush-right 1s linear forwards 300ms;
            animation-play-state: paused;

            @include mq(bp-md) {
              right: 0;
            }
          }
        }
      }

      .animated & {
        .brush--left {
          animation-play-state: running;
          animation-delay: 1s;
          animation-iteration-count: 1.3;
        }

        .brush--right {
          animation-play-state: running;
          animation-delay: 1s;
          animation-iteration-count: 1.3;
        }
      }

      &:hover svg {
        &.brush {
          &--left,
          &--right {
            animation-play-state: running;
            animation-iteration-count: infinite;
          }
        }
      }

      @keyframes rotate-brush-left {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(-360deg);
        }
      }

      @keyframes rotate-brush-right {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    &--battery {
      svg {
        transition: transform 300ms ease;
        transform-origin: center;
        overflow: visible; // remove defs from svg

        .top {
          transform: translate(-132.378px, -82.18px);
          animation: battery-top 1s linear forwards 300ms;
          animation-play-state: paused;
        }

        .middle {
          transform: translate(-59.897px, -156.655px);
          transform-origin: center;
        }

        .bottom {
          transform: translate(-59.899px, -179.87px);
          animation: battery-bottom 1s linear forwards 300ms;
          animation-play-state: paused;
        }

        .bottom-text {
          transform: translate(-96.826px, -199.082px);
          animation: battery-text 1s linear forwards 300ms;
          animation-play-state: paused;
        }
      }

      .animated & svg {
        animation-play-state: running;

        .top {
          animation-play-state: running;
          animation-delay: 1.2s;
        }

        .bottom {
          animation-play-state: running;
          animation-delay: 1.2s;
        }

        .bottom-text {
          animation-play-state: running;
          animation-delay: 1.2s;
        }
      }

      &:hover svg {
        transform: scale(0.8);

        .top {
          animation-play-state: running;
          animation-iteration-count: infinite;
        }

        .bottom {
          animation-play-state: running;
          animation-iteration-count: infinite;
        }

        .bottom-text {
          animation-play-state: running;
          animation-iteration-count: infinite;
        }
      }

      @keyframes battery-scale {
        0% {
          transform: scale(1);
          transform-origin: center;
        }

        50% {
          transform: scale(0.8);
        }
      }

      @keyframes battery-top {
        0% {
          transform: translate(-132.378px, -82.18px);
          transform-origin: center;
        }
        50% {
          transform: translate(-132.378px, -100.18px);
        }
      }

      @keyframes battery-bottom {
        0% {
          transform: translate(-59.899px, -179.87px);
          transform-origin: center;
        }
        50% {
          transform: translate(-59.899px, -160.87px);
        }
      }

      @keyframes battery-text {
        0% {
          transform: translate(-96.826px, -199.082px);
          transform-origin: center;
        }
        50% {
          transform: translate(-96.826px, -178.082px);
        }
      }
    }
  }
}
