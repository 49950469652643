@use '~sass-mq/mq' as *;
@use './settings/animation';
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.po-icons {
  background-color: brand.$white;

  &--dark {
    background-color: brand.$grey-25;
  }

  &__inner {
    padding: 40px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding: global.vr(5) 0px global.vr(2);

    @include mq($from: bp-xxs) {
      padding: global.vr(9) 0px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: stretch;
    width: 100%;
    flex: 1 0 auto;
    color: brand.$grey-20;
    align-items: center;
    text-align: center;
    margin-bottom: global.vr(6);
    opacity: 0;
    transform: translate(0, 40px);
    transition: opacity 300ms ease, transform 300ms ease;
    will-change: transform;

    @include mq($from: bp-xxs) {
      width: 50%;
      margin-bottom: 0px;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          transition-delay: calc(500ms + (#{$i} * 150ms));
        }
      }

      &:nth-child(-n + 2) {
        margin-bottom: global.vr(5);
      }
    }

    @include mq($from: bp-md) {
      width: 25%;
      margin-bottom: 0px;
      transform: translate(40px, 0);

      &:nth-child(-n + 2) {
        margin-bottom: 0px;
      }
    }

    &.animated {
      opacity: 1;
      transform: translate(0);
    }

    h3,
    strong {
      @include fonts.lato-black;
      color: brand.$grey-35;
      font-size: 18px;
      max-width: 176px;

      .po-icons--dark & {
        max-width: 242px;
      }

      @include mq(bp-xs) {
        font-size: 24px;
      }
    }

    h3 {
      text-transform: uppercase;
      margin-bottom: global.vr(2);

      .po-icons--dark & {
        color: brand.$green;
      }
    }

    strong {
      margin-top: auto;

      .po-icons--dark & {
        color: brand.$white;
      }
    }

    p {
      font-size: 14px;
      line-height: 21px;
      @include fonts.lato-bold;

      .po-icons--dark & {
        color: brand.$white;
      }
    }
  }

  &__copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: stretch;
    max-width: 200px;
    margin: 0 auto;

    .po-icons--dark & {
      max-width: 242px;
      margin: 0 auto;
    }
  }

  &__media {
    margin-bottom: global.vr(2);

    @include mq($from: bp-md) {
      margin-bottom: global.vr(5.5);
    }

    svg,
    img {
      display: block;
      margin: 0 auto;
      max-height: 75px;
      pointer-events: none;
      max-width: 220px;

      @include mq($from: bp-md) {
        max-height: 142px;
      }
    }

    &--polisher-buff {
      svg {
        animation: polisher-buff 1s linear 2;
        animation-play-state: paused;
        transform-origin: center;
      }

      .animated & svg {
        animation-play-state: running;
        animation-delay: 1s;
      }

      &:hover svg {
        animation-play-state: running;
        animation-iteration-count: infinite;
      }

      @keyframes polisher-buff {
        0% {
          transform: translateX(0px);
        }
        40% {
          transform: translateX(-10px) rotateZ(0deg);
        }
        50% {
          transform: translateX(-20px) rotateZ(5deg);
        }
        100% {
          transform: translateX(0px);
        }
      }
    }

    &--rotate {
      svg {
        animation: rotate-svg 2s linear forwards;
        animation-play-state: paused;
        transform-origin: center;
      }

      .animated & svg {
        animation-play-state: running;
        animation-delay: 1s;
      }

      &:hover svg {
        animation-play-state: running;
        animation-iteration-count: infinite;
      }

      @keyframes rotate-svg {
        0% {
          transform: rotateZ(0deg);
        }
        100% {
          transform: rotateZ(360deg);
        }
      }
    }

    &--scale {
      svg {
        animation: scale-svg 2s linear forwards;
        animation-play-state: paused;
        transform-origin: center;
      }

      .animated & svg {
        animation-play-state: running;
        animation-delay: 1s;
      }

      &:hover svg {
        animation-play-state: running;
        animation-iteration-count: infinite;
      }

      @keyframes scale-svg {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.16);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    &--battery {
      svg {
        transition: transform 300ms ease;
        transform-origin: center;
        overflow: visible; // remove defs from svg

        .top {
          transform: translate(-132.378px, -82.18px);
          animation: battery-top 1s linear 2 300ms;
          animation-play-state: paused;
        }

        .middle {
          transform: translate(-59.897px, -156.655px);
          transform-origin: center;
        }

        .bottom {
          transform: translate(-59.899px, -179.87px);
          animation: battery-bottom 1s linear 2 300ms;
          animation-play-state: paused;
        }

        .bottom-text {
          transform: translate(-96.826px, -199.082px);
          animation: battery-text 1s linear 2 300ms;
          animation-play-state: paused;
        }
      }

      .animated & svg {
        animation-play-state: running;

        .top {
          animation-play-state: running;
          animation-delay: 1.2s;
        }

        .bottom {
          animation-play-state: running;
          animation-delay: 1.2s;
        }

        .bottom-text {
          animation-play-state: running;
          animation-delay: 1.2s;
        }
      }

      &:hover svg {
        transform: scale(0.8);

        .top {
          animation-play-state: running;
          animation-iteration-count: infinite;
        }

        .bottom {
          animation-play-state: running;
          animation-iteration-count: infinite;
        }

        .bottom-text {
          animation-play-state: running;
          animation-iteration-count: infinite;
        }
      }

      @keyframes battery-scale {
        0% {
          transform: scale(1);
          transform-origin: center;
        }

        50% {
          transform: scale(0.8);
        }
      }

      @keyframes battery-top {
        0% {
          transform: translate(-132.378px, -82.18px);
          transform-origin: center;
        }
        50% {
          transform: translate(-132.378px, -100.18px);
        }
      }

      @keyframes battery-bottom {
        0% {
          transform: translate(-59.899px, -179.87px);
          transform-origin: center;
        }
        50% {
          transform: translate(-59.899px, -160.87px);
        }
      }

      @keyframes battery-text {
        0% {
          transform: translate(-96.826px, -199.082px);
          transform-origin: center;
        }
        50% {
          transform: translate(-96.826px, -178.082px);
        }
      }
    }
  }
}
