@use '../helpers/fonts';
@use '../helpers/global';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-faqs {
  padding: global.vr(6) global.vr(2.5) global.vr(10);

  @include mq(bp-xs) {
    padding: global.vr(10) global.vr(2.5) global.vr(14);
  }

  &.ds-theme {
    &--white {
      background: brand.$white;
      color: #323232;
    }

    &--roboyagi {
      background: brand.$grey-max-power;
      color: brand.$white;
    }
  }

  &__inner {
    max-width: 700px;
    margin: 0 auto;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      order: 3;
      @include fonts.lato-regular;
      font-size: 16px;
      line-height: 24px;
      max-width: 620px;
      margin: global.vr(2) 0 0;
      text-align: center;
    }
  }

  &__small-title {
    order: 1;

    .ds-theme--white & {
      color: brand.$green;
      @include fonts.lato-bold;
      letter-spacing: 1px;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: global.vr(2);

      @include mq(bp-md) {
        font-size: 16px;
      }
    }

    .ds-theme--roboyagi & {
      text-transform: uppercase;
      color: #989898;
      letter-spacing: 2px;
      margin-bottom: 20px;
      @include fonts.lato-thin;
    }
  }

  &__title {
    order: 2;
    @include fonts.lato-black;
    font-size: 26px;
    letter-spacing: -1px;

    @include mq(bp-md) {
      font-size: 40px;
    }
  }

  &__logo {
    order: 2;
    width: auto;
    max-height: 24px;

    @include mq(bp-xs) {
      max-height: 43px;
    }
  }

  &__sub-title {
    order: 4;
    margin-right: auto;
    margin-top: global.vr(11);

    @include mq($until: bp-xs) {
      margin-bottom: global.vr(3);
    }
  }

  .ds-section__title {
    margin-bottom: global.vr(2);
  }

  .ds-accordion__item-header {
    padding: global.vr(4) 0;
    text-transform: none;
  }

  .ds-accordion__item-content-inner {
    padding-bottom: global.vr(4);
  }
}
