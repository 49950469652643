@use '~sass-mq/mq' as *;
@use './settings/animation';
@use './settings/brand';
@use './helpers/fonts';
@use './helpers/global';

.fs-product-breakdown {
  padding: global.vr(8) 0 global.vr(4);
  background: brand.$grey-95;

  @include mq(bp-sm) {
    padding: global.vr(9) 0;
  }

  &__title {
    text-align: center;
  }

  &__main {
    max-width: 600px;
    margin: 0 auto global.vr(5);
    opacity: 0;
    transform: scale(0.7);
    transition: opacity 600ms animation.$ease-in-out-cubic,
      transform 600ms animation.$ease-in-out-cubic;
    will-change: transform;

    &.animated {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    text-align: center;
    margin-bottom: global.vr(6);
    padding: 0 global.vr(1);
    width: 33.333%;
    opacity: 0;
    transform: translateY(40px);
    transition: opacity 300ms animation.$ease-in-out-cubic,
      transform 300ms animation.$ease-in-out-cubic;
    will-change: transform;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: 500ms + ($i * 100ms);
      }
    }

    @include mq(bp-sm) {
      max-width: 12.5%;
      width: auto;
      margin-left: global.vr(4);
      margin-right: global.vr(4);
      padding: 0;
    }

    .fs-product-breakdown__list.animated & {
      opacity: 1;
      transform: translateY(0px);
    }

    strong {
      display: block;
      font-size: 14px;
      line-height: 20px;

      @include mq(bp-sm) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  &__image {
    min-height: 100px;
    padding: 0 global.vr(1);

    @include mq(bp-sm) {
      padding: 0;
      min-height: 110px;
    }
  }
}
