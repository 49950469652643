@use '../helpers/fonts';
@use '../helpers/global';

@use '~sass-mq/mq' as *;
@use '../settings/brand';
@use '../settings/layers';

.banner-explore {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__image {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: layers.$layer-root;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &--top-right {
      background-position: top right;
    }

    &:before {
      content: ' ';
      display: block;
      background: rgba(#1c1c1c, 0.45);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    min-height: 310px;
    flex: 1;
    padding: global.vr(2.5) global.vr(3) global.vr(7.5);

    @include mq(bp-xs) {
      max-width: 600px;
      padding: global.vr(4.5) global.vr(5) global.vr(7.5);
    }

    @include mq(bp-sm) {
      max-width: 700px;
      min-height: 390px;
      padding: global.vr(7.5) global.vr(8);
    }

    @include mq(bp-lg) {
      max-width: 900px;
      min-height: 500px;
    }

    @include mq(bp-xl) {
      min-height: 590px;
    }
  }

  &__caption {
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: global.vr(1.5);
    color: brand.$white;
    @include fonts.lato-regular();

    @include mq(bp-md) {
      font-size: 14px;
    }
  }

  & &__title {
    font-size: 32px;
    line-height: 42px;
    font-weight: 600;
    color: brand.$white;
    @include fonts.lato-semi-bold();

    @include mq(bp-xs) {
      font-size: 38px;
      line-height: 48px;
    }

    @include mq(bp-md) {
      font-size: 48px;
      line-height: 58px;
    }

    @include mq(bp-lg) {
      font-size: 58px;
      line-height: 68px;
    }
  }

  &__tag {
    position: relative;
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: auto;
    color: brand.$white;
    @include fonts.lato-regular();

    @include mq(bp-md) {
      font-size: 14px;
    }

    &-animation {
      position: absolute;
      left: global.vr(2);
      top: calc(100% + #{global.vr(2)});
      height: 90px;
      width: 4px;
      transform: scaleY(0);
      transform-origin: top;
      background: #cddb00;
      animation: scale-line 2s infinite;
    }
  }
}

@keyframes scale-line {
  0% {
    transform: scaleY(0);
    transform-origin: 0 0;
  }

  33% {
    transform: scaleY(1);
    transform-origin: 0 0;
  }

  40% {
    transform: scaleY(1);
    transform-origin: 0 0;
  }

  40.01% {
    transform-origin: 100% 100%;
  }

  60% {
    transform-origin: 100% 100%;
    transform: scaleY(0);
  }
}
