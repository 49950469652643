@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';
@use './settings/animation';

.instrument-focus {
  background-color: brand.$green;
  padding: 30px 15px;
  margin: 0;

  @include mq(bp-md) {
    padding: 65px 15px;
  }

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;

    @include mq(bp-sm) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &__content {
    align-self: center;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    @include mq(bp-xs) {
      flex-direction: row;
    }

    .theme--stacked & {
      flex-direction: column;
    }
  }

  &__media {
    > * {
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          @include animations.fade-up($i * 50ms, parent);

          @include mq(bp-sm) {
            @include animations.fade-left($i * 100ms, parent);
          }
        }
      }
    }

    &.animated > * {
      opacity: 1;
      transform: translateX(0px);
    }

    max-width: 325px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      font-size: 16px;
      margin-bottom: 0px;
      @include fonts.lato-black-italic;
      line-height: 1.1;
      text-transform: uppercase;
      align-self: center;
    }

    em {
      color: brand.$white;
      font-size: 20px;
    }

    img {
      margin: 10px 0px;
    }

    &-icon {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      justify-content: center;
      align-self: center;

      @include mq(bp-sm) {
        display: block;
      }

      img {
        margin-right: 12px;

        @include mq(bp-sm) {
          margin-right: 0px;
        }
      }
    }
  }

  &__copy {
    > * {
      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          @include animations.fade-up($i * 50ms, parent);
        }
      }
    }

    &.animated > * {
      opacity: 1;
      transform: translateY(0px);
    }

    order: 2;

    @include mq(bp-xs) {
      max-width: 400px;
      order: unset;
    }

    h1 {
      margin-bottom: 15px;
      font-size: 24px;
      padding-bottom: 0px;

      @include mq(bp-md) {
        font-size: 30px;
      }
    }

    h2 {
      margin-bottom: 5px;
      color: brand.$white;
      font-size: 16px;
    }

    p {
      margin-bottom: 24px;
    }
  }

  &__technology {
    @include animations.fade-up(0s, self);

    @include mq(bp-xs) {
      @include animations.fade-left(0s, self);
    }

    flex: 1 0 auto;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    order: 1;
    margin-bottom: 20px;

    @include mq(bp-xs) {
      order: unset;
      max-width: 320px;
    }

    &-icons {
      @include fonts.lato-black-italic;
      width: 220px;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-end;
      padding: 40px 0px 10px 0px;
      margin-top: 22px;

      img {
        margin-left: auto;
        position: absolute;
        right: 8px;
        top: 0px;
      }

      h3 {
        font-size: 107px;
        color: brand.$white;
        margin-bottom: 0px;
        line-height: 80px;
      }

      span {
      }
    }

    &-text {
      font-size: 21px;
      color: brand.$grey-max-power;
      letter-spacing: normal;
      text-align: center;
      display: inline-block;
      width: 90px;
      height: 60px;
    }
  }

  &__new {
    display: inline-block;
    position: relative;
    @include fonts.lato-black-italic;
    font-size: 24px;
    padding: 8px 8px 8px 8px;
    color: brand.$green;
    background-color: brand.$grey-max-power;
    margin-bottom: 20px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 12px;
      height: 100%;
      background-color: brand.$grey-max-power;
      transform: skew(-13deg);
      transform-origin: 0 0;
    }
  }

  &__icons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq(bp-sm) {
      flex-direction: row;
      max-width: 650px;
    }
  }

  &__icon {
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        @include animations.fade-up($i * 50ms, parent);

        @include mq(bp-sm) {
          @include animations.fade-right($i * 100ms, parent);
        }
      }
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    max-width: 155px;

    &-large {
      max-width: 200px;
    }

    @include mq(bp-sm) {
      margin-right: auto;
      margin-bottom: 0px;
    }

    &-holder {
      padding: 15px 0px;
      width: 100%;
      flex: 0 0 auto;
      text-align: center;

      &--no-padding {
        padding: 0;
      }
    }

    img {
      max-height: 70px;
    }

    figcaption {
      margin-top: auto;
      font-size: 16px;
      @include fonts.lato-black-italic;
      line-height: 1.15;
      text-transform: uppercase;
      text-align: center;

      em {
        font-size: 20px;
      }
    }

    &-list {
      padding-bottom: global.vr(2);
    }

    &-list-item {
      position: relative;
      font-size: 26px;
      @include fonts.lato-black-italic;

      &:before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: brand.$grey-max-power;
        top: 50%;
        left: -25px;
        transform: translateY(-50%);
      }
    }

    &-inner {
      display: flex;
      align-items: center;
    }

    &-temperature-icon {
      margin-right: 10px;
      img {
        max-height: 95px;
      }
    }

    &-temperature {
      font-size: 50px;
      line-height: 50px;
      @include fonts.lato-black-italic;
    }
  }
}
