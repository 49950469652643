@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.mp-warranty {
  position: relative;
  display: block;
  background: brand.$brand-cddb00 url('~@img/svg/36v-bg.svg') no-repeat center center;
  background-size: cover;
  overflow: hidden;
  padding: global.vr(5) 0;

  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include mq(bp-sm) {
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
    }
  }

  &__logo {
    display: inline-block;
    margin-bottom: global.vr(2);

    @include mq(bp-sm) {
      margin-bottom: 0;
      margin-right: global.vr(5);
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: global.vr(2);

    @include mq(bp-sm) {
      margin-bottom: 0;
    }
  }

  &__title {
    @include fonts.lato-black();
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 global.vr(0.5);
    text-transform: uppercase;
  }

  &__content {
    @include fonts.lato-regular();
    font-size: 14px;
    @include fonts.ls(20);
    line-height: 24px;
    margin: 0;
  }

  @include mq(bp-sm) {
    .ds-btn {
      margin-left: auto;
    }
  }
}
