@use './helpers/animations';

// replacing wow animations with AOS animations using existing classes
.ds-content-with-image {
  &__content {
    @include animations.fade-right($mode: self);

    &--reverse {
      @include animations.fade-left($mode: self);
    }
  }

  &__image {
    @include animations.fade-left($mode: self);

    &--reverse {
      @include animations.fade-right($mode: self);
    }
  }
}

.fadeIn {
  @include animations.fade-in($mode: self);
}

.fadeInUp {
  @include animations.fade-up($mode: self);
}

.fadeInRight {
  @include animations.fade-right($mode: self);
}

.fadeInLeft {
  @include animations.fade-left($mode: self);
}

.fadeInDown {
  @include animations.fade-down($mode: self);
}
