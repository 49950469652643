@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-accordion {
  @include animations.fade-up(0.2s);
  position: relative;
  display: block;

  @include mq(bp-sm) {
    padding-top: 24px + global.vr(1);
  }

  hr {
    display: block;
    background: #cfcfce;
    border: 0;
    height: 1px;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &__toggle-all {
    @include fonts.lato-bold();
    @include fonts.ls(40);
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    font-size: 14px;
    line-height: 24px;

    .text--active {
      display: none;
    }

    .text--inactive {
      display: block;
    }

    &.is-active {
      .text--active {
        display: block;
      }

      .text--inactive {
        display: none;
      }
    }

    @include mq(bp-sm) {
      display: block;
    }
  }

  &__item {
    display: block;

    &-header {
      @include fonts.lato-semi-bold();
      @include fonts.ls(60);
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: none;
      flex-direction: row;
      border: 0;
      font-size: 16px;
      line-height: 19px;
      padding: global.vr(2) 0;
      text-transform: uppercase;
      width: 100%;
      text-align: left;

      &::after {
        content: '+';
        display: inline-flex;
        align-items: center;
        background: #c7d31b;
        border-radius: 50%;
        color: brand.$white;
        font-size: 23px;
        height: 28px;
        justify-content: center;
        margin-right: 0;
        margin-left: global.vr(1.5);
        text-indent: 1px;
        width: 28px;
        flex-shrink: 0;

        @include mq(bp-sm) {
          margin-right: global.vr(3);
          margin-left: global.vr(3);
        }
      }

      .is-active & {
        &::after {
          content: '-';
        }
      }
    }

    &-content {
      @include fonts.lato-regular();
      display: block;
      font-size: 14px;
      height: 1px;
      line-height: 24px;
      overflow: hidden;

      &-inner {
        display: block;
        padding: global.vr(0) 0 global.vr(3);

        @include mq(bp-sm) {
          padding: global.vr(0) global.vr(25) global.vr(3) 0;
        }
      }

      .is-active & {
        height: auto;
      }
    }

    &-specification {
      display: flex;
      margin-bottom: global.vr(0.25);
      padding-bottom: global.vr(0.25);
      border-bottom: 1px solid rgba(brand.$black, 0.05);

      &:last-of-type {
        border-bottom: none;
      }

      &-header {
        font-size: 14px;
        flex: 0 1 50%;

        @include mq(bp-xs) {
          flex: 1;
        }
      }
      &-value {
        font-size: 14px;
        flex: 1 50%;
        text-align: right;

        @include mq(bp-xs) {
          flex: 0 1 30%;
        }
      }
    }
  }
}
