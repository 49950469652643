@use '../helpers/fonts';
@use '../helpers/global';
@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

// FAB = Floating Action Button 🎉
.ds-fab {
  display: flex;
  align-items: center;
  border: 0;
  justify-content: center;
  margin: 0;
  padding: 0;

  &__icon-wrapper {
    display: inline-flex;
    align-items: center;
    background: #cddb00;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    height: 40px;
    justify-content: center;
    transition: box-shadow 0.3s animation.$ease-in-out-cubic,
      background-color 0.3s animation.$ease-in-out-cubic,
      transform 0.3s animation.$ease-in-out-cubic;
    transform: translateY(0);
    width: 40px;

    svg {
      display: block;
      height: 12px;
      width: 16px;
    }

    @include mq(bp-sm) {
      height: 50px;
      width: 50px;

      svg {
        display: block;
        height: 15px;
        width: 20px;
      }
    }
  }

  &__icon {
    display: block;
    height: 15px;
    width: 20px;
    transform: rotate(-90deg);
  }

  &__label {
    @include fonts.lato-regular();
    @include fonts.ls(100);
    display: inline-block;
    font-size: 14px;
    line-height: 1em;
    margin-left: global.vr(3);
    text-transform: uppercase;
  }

  &:focus,
  &:hover {
    .ds-fab__icon-wrapper {
      background: brand.$white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      text-decoration: none;
      transform: translateY(-2px);
    }
  }
}
