@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.banner-cta {
  background-color: brand.$grey-max-power;

  &__container {
    position: relative;
    overflow: hidden;
    background: brand.$brand-cddb00;
    padding: global.vr(3) global.vr(3.6);
    text-align: center;

    .banner-cta--minus-overlap & {
      top: -50px;
    }

    @include mq(bp-xs) {
      padding: global.vr(4.6) global.vr(5);
      text-align: left;
    }
  }

  &__circular-dotted-mask {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('~@img-ui/max-power/circular-dotted-mask.png') no-repeat center;
    background-size: cover;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;

    @include mq($from: bp-xs) {
      justify-content: space-between;
    }

    @include mq($from: bp-md) {
      flex-wrap: nowrap;
    }
  }

  &__details {
    margin-bottom: global.vr(4);

    @include mq($from: bp-md) {
      margin-bottom: 0px;
    }

    p {
      font-size: 16px;
      line-height: 20px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__title {
    font-size: 24px;
    @include fonts.lato-black();
    text-transform: uppercase;

    @include mq(bp-xs) {
      font-size: 30px;
    }
  }

  .banner-cta__button {
    align-self: center;
  }
}
