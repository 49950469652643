@use '../../settings/animation';
@use '../../settings/brand';
@use '../../settings/breakpoints' as *;
@use '../../settings/grid';
@use '../../settings/layers';

@use '../../helpers/fonts';
@use '../../helpers/global';

.ds-sections {
  position: relative;
  display: block;

  @include mq(bp-md) {
    display: flex;
    flex-direction: row;
  }

  @supports (display: grid) {
    display: block;

    @include mq(bp-md) {
      display: grid;
      grid-template-columns: 1px 1fr;
    }
  }

  + .ds-section {
    z-index: layers.$layer-default;
  }

  &__nav {
    position: sticky;
    z-index: layers.$layer-default;
    top: 0;
    flex: 0 0 1px;
    min-height: 1px;
    width: 1px;

    @include mq(bp-md) {
      position: relative;
      box-shadow: none;
      padding-bottom: 248px;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 16%);
    }

    @supports (display: grid) {
      text-align: center;
      width: auto;

      @include mq(bp-md) {
        text-align: left;
      }
    }

    .ds-nav-selected {
      cursor: pointer;
      display: block;
      position: relative;
      z-index: layers.$layer-default;
      padding: global.vr(1.5) 0;
      background-color: #f6f7cf;

      &.is-active:before {
        transform: translateY(-50%) translateY(-2px) rotateZ(-90deg);
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        display: block;
        background: url('~@img/icons/icon-arrow.svg') no-repeat center center;
        background-size: contain;
        height: 15px;
        width: 16px;
        transform: translateY(-50%) translateY(-2px) rotateZ(90deg);
      }

      @include mq(bp-md) {
        display: none;
      }
    }
  }

  &__scroll-top {
    position: fixed;
    display: none;
    bottom: global.vr(8);
    margin-left: calc((100vw - 1324px) / 2);
    margin-bottom: 40px;
    width: 320px;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms ease, transform 300ms ease;

    @include mq(bp-md) {
      @supports (display: grid) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 100px;
      }
    }

    @include mq(bp-xl) {
      margin-left: calc((100vw - #{grid.$site-wide-xl}) / 2);
    }

    @include mq(bp-xxl) {
      margin-left: calc((100vw - #{grid.$site-wide-xxl}) / 2);
    }

    &.is-visible {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__main {
    flex: 1 1 auto;

    @include mq(bp-md) {
      &::before {
        content: '';
        position: absolute;
        top: -#{global.vr(2)};
        left: calc((100vw - #{grid.$site-wide-md + (grid.$site-padding-md * 2)}) / 2);
        display: block;
        border-left: 2px solid #c7d31b;
        height: calc(100% - (248px - #{global.vr(2)}));
        z-index: 2;
      }
    }

    @include mq(bp-lg) {
      &::before {
        left: calc((100vw - #{grid.$site-wide-lg + (grid.$site-padding-md * 2)}) / 2);
      }
    }

    @include mq(bp-xl) {
      &::before {
        left: calc((100vw - #{grid.$site-wide-xl + (grid.$site-padding-md * 2)}) / 2);
      }
    }

    @include mq(bp-xxl) {
      &::before {
        left: calc((100vw - #{grid.$site-wide-xxl + (grid.$site-padding-md * 2)}) / 2);
      }
    }

    .ds-site-wide {
      @include mq(bp-md) {
        padding-left: 320px + grid.$site-padding-md;
      }

      @include mq(bp-xxl) {
        padding-left: calc((320px + #{grid.$site-padding-md}) / 2);
      }
    }
  }
}

.ds-nav-sticky {
  position: absolute;
  display: none;

  @supports (display: grid) {
    display: block;
  }

  @include mq($until: bp-md) {
    background-color: brand.$white;
    transform: scaleY(0);
    box-shadow: 0 0 8px rgba(brand.$black, 0.1);
    opacity: 0;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    transform-origin: top center;
    transition: opacity 500ms ease, transform 500ms ease;
  }

  @include mq(bp-md) {
    position: relative;
    padding: global.vr(1) global.vr(2) global.vr(1) global.vr(8);
    margin-top: global.vr(10);
    margin-left: calc((100vw - #{grid.$site-wide-md + (grid.$site-padding-md * 2)}) / 2);
    background-color: unset;
    width: 320px;
    z-index: 2;
    transition: unset;

    @supports (display: grid) {
      position: sticky;
      top: global.vr(10);
    }

    @supports (-ms-ime-align: auto) {
      position: relative;
      top: 0;
    }
  }

  @include mq(bp-lg) {
    margin-left: calc((100vw - #{grid.$site-wide-lg + (grid.$site-padding-md * 2)}) / 2);
  }

  @include mq(bp-xl) {
    margin-left: calc((100vw - #{grid.$site-wide-xl + (grid.$site-padding-md * 2)}) / 2);
  }

  @include mq(bp-xxl) {
    margin-left: calc((100vw - #{grid.$site-wide-xxl + (grid.$site-padding-md * 2)}) / 2);
  }

  .ds-nav-selected.is-active + & {
    opacity: 1;
    transform: scaleY(1);
  }

  &__item {
    @include fonts.lato-semi-bold();
    @include fonts.ls(100);
    position: relative;
    display: block;
    font-size: 13px;
    line-height: 23px;
    margin: 0;
    text-transform: uppercase;

    @include mq(bp-md) {
      padding: 0;
      margin-bottom: global.vr(2);
      transition: transform 0.4s animation.$ease-in-out-cubic;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: global.vr(-4);
        display: block;
        background: url('~@img/icons/icon-arrow.svg') no-repeat center center;
        height: 15px;
        opacity: 0;
        transform: translateY(-50%) translateY(-2px) scale(0.8);
        width: 20px;
        transition: opacity 0.4s animation.$ease-in-out-cubic,
          transform 0.4s animation.$ease-in-out-cubic;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.is-active {
      @include mq(bp-md) {
        transform: translateX(global.vr(4));

        &::before {
          opacity: 1;
          transform: translateY(-50%) translateY(-2px) scale(1);
        }
      }

      .ds-section-nav__link {
        color: brand.$grey-20;
        text-decoration: none;
      }
    }
  }

  &__link {
    display: block;
    color: brand.$grey-45;
    padding: global.vr(2);
    text-decoration: none;

    @include mq(bp-md) {
      display: inline-block;
      padding: 0;
    }

    &:focus,
    &:hover {
      color: brand.$grey-20;
      text-decoration: underline;
    }
  }
}
