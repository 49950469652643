@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.mp-batteries {
  position: relative;
  display: block;
  background: brand.$brand-cddb00 url('~@img/svg/36v-bg.svg') no-repeat center center;
  background-size: cover;
  overflow: hidden;
  padding: global.vr(6) 0 0;

  @include mq($from: bp-sm) {
    padding: global.vr(12) 0 global.vr(10);
  }

  &__grid-container {
    display: flex;
    flex-direction: column;

    @supports (display: grid) {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(12, 1fr);
    }

    @include mq(bp-lg) {
      display: flex;
      flex-direction: row;

      @supports (display: grid) {
        display: grid;
        gap: 28px;
        grid-template-columns: repeat(12, 1fr);
      }
    }
  }

  &__grid-column {
    min-width: auto;

    &:nth-child(1) {
      @supports (display: grid) {
        grid-column: 1 / span 12;
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      @supports (display: grid) {
        grid-column: 1 / span 12;
        margin-left: 0;
      }
    }

    @include mq(bp-lg) {
      &:nth-child(1) {
        flex-basis: 25%;
        margin-right: 14px;

        @supports (display: grid) {
          grid-column: 1 / span 4;
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        flex-basis: 75%;
        margin-left: 14px;

        @supports (display: grid) {
          grid-column: 5 / span 8;
          margin-left: 0;
        }
      }
    }
  }

  &__title {
    @include fonts.lato-black();
    font-size: 38px;
    @include fonts.ls(-10);
    line-height: 36px;
    margin-bottom: global.vr(3);
    text-align: center;
    text-transform: uppercase;

    span {
      display: block;
      color: brand.$grey-max-power;

      &:nth-child(even) {
        color: brand.$white;
      }
    }

    @include mq(bp-lg) {
      text-align: left;
    }
  }

  &__subtitle {
    color: brand.$grey-max-power;
    @include fonts.lato-black();
    font-size: 30px;
    @include fonts.ls(-10);
    line-height: 30px;
    margin-bottom: global.vr(3);
    text-align: center;
    text-transform: uppercase;

    @include mq(bp-lg) {
      text-align: left;
    }
  }

  &__content {
    @include fonts.lato-regular();
    font-size: 16px;
    line-height: 26px;
    margin: 0 auto;
    text-align: center;

    @include mq(bp-lg) {
      text-align: left;
      margin: 0;
    }
  }

  &__range {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: global.vr(6);
    padding: 0;
    width: 100%;

    @include mq(bp-xs) {
      flex-wrap: nowrap;
      margin-right: global.vr(-2);
      padding: 0 global.vr(8);
    }

    @include mq(bp-lg) {
      justify-content: space-around;
      padding: 0 global.vr(8) 0 global.vr(12);
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &-logo {
      position: relative;
      align-self: center;
      flex-basis: 50%;
      margin-right: none;
      padding: 0 global.vr(2);
      max-width: 184px;
      width: 184px;
      z-index: 2;

      @include mq(bp-xs) {
        flex-basis: auto;
        flex-wrap: nowrap;
      }

      @include mq(bp-lg) {
        margin: 0 auto;
        padding: 0;
        max-width: 160px;
        width: 160px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      align-items: flex-start;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 global.vr(2);
      text-align: left;
      max-width: 250px;
      width: 100%;
      z-index: 2;

      @include mq(bp-xs) {
        flex-basis: auto;
      }

      a:hover {
        text-decoration: none;
      }

      &--center {
        justify-content: center;
      }

      &-logo {
        display: inline-block;
        margin-bottom: global.vr(1);
        width: 100%;

        @include mq(bp-xs) {
          width: 140px;
        }
      }

      &-thumb {
        display: block;
        margin-bottom: global.vr(2);
        flex-shrink: 0;
      }

      &-title,
      &-subtitle,
      &-stat {
        @include fonts.lato-black();
        font-size: 16px;
        @include fonts.ls(20);
        line-height: 26px;
        margin: 0;
      }

      &-title,
      &-subtitle {
        color: brand.$grey-max-power;
        line-height: 22px;
      }

      &-stat {
        color: brand.$white;
      }
    }

    &--alt {
      padding: global.vr(2) 0;

      @include mq(bp-xs) {
        padding: global.vr(6) global.vr(8);
      }

      @include mq(bp-lg) {
        padding: global.vr(6) global.vr(8) global.vr(6) global.vr(12);
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        background: brand.$grey-max-power;
        height: 100%;
        width: calc(100% + 24px + 24px);
        margin-left: -24px;
        margin-right: -24px;
        z-index: 1;

        @include mq(bp-xs) {
          transform: skewX(-10.5deg);
          transform-origin: bottom right;
          width: 200vw;
        }
      }

      .mp-batteries__range-item-title,
      .mp-batteries__range-item-subtitle {
        color: brand.$brand-cddb00;
      }

      .mp-batteries__range-item-stat {
        color: brand.$white;
      }
    }
  }
}
