@use '../../helpers/animations';
@use '../../helpers/fonts';
@use '../../helpers/global';

@use '../../settings/brand';
@use '../../settings/breakpoints' as *;
@use '../../settings/grid';
@use '../../settings/layers';

.ds-product-detail {
  &__divider {
    border-bottom: 1px solid brand.$grey-80;
  }

  &__overview {
    @include mq(bp-md) {
      display: flex;
      flex-direction: row;
    }
  }

  &__breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: global.vr(3) global.vr(3) 0;

    @include mq(bp-md) {
      padding: global.vr(4) global.vr(5) 0;
    }

    &-item {
      font-size: 12px;
      color: brand.$black;
      margin: 0 global.vr(1);
    }
  }

  &__hero-images {
    @include mq(bp-md) {
      display: flex;
      flex-direction: column;
      flex: 1 0 52%;
    }
  }

  &__carousel {
    @include mq($until: bp-md) {
      padding-bottom: global.vr(5);
    }
  }

  &__carousel-item {
    text-align: center;
  }

  &__carousel-img {
    img {
      width: auto;
      max-height: 300px;

      @include mq(bp-xxs) {
        max-height: 400px;
      }

      @include mq(bp-md) {
        max-height: none;
        width: 500px;
      }
    }
  }

  &__carousel-video {
    position: relative;
    width: calc(100% - 20px);
    margin: 0 auto;
    height: 0;
    padding-bottom: 52.4%; // iframe video ratio

    @include mq(bp-md) {
      width: calc(100% - 60px);
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__carousel-controls {
    display: flex;
    justify-content: flex-end;
    padding: global.vr(3) global.vr(5);
    @include animations.fade-right(0.2s);

    @include mq(bp-md) {
      margin-bottom: -#{global.vr(11)};
    }

    &:focus {
      outline: none;
    }
  }

  &__carousel-control {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-image: url('~@img/icons/icon-arrow.svg');
    background-size: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 1;
    background-color: brand.$white;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.22);
    transition: background-color 200ms ease, box-shadow 200ms ease;

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(1.15);
      transition: transform 100ms ease;
    }

    &--next {
      margin-left: global.vr(2);
    }

    &--prev {
      transform: rotate(180deg);

      &:active {
        transform: scale(1.15) rotate(180deg);
      }
    }

    &:hover {
      background-color: brand.$green;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0);
    }
  }

  &__carousel-container {
    width: 100%;
    padding: global.vr(1) global.vr(2);
    @include animations.fade-up(0.25s);

    @include mq(bp-md) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding-left: global.vr(4);
      margin-top: global.vr(11);
    }

    .status {
      position: absolute;
      top: 20px;
      left: 145px;
      padding: 0 5px;
      background: brand.$green;
      color: brand.$grey-05;
      font-size: 16px;
      z-index: 4;
      line-height: 50px;
      text-transform: uppercase;
      font-weight: 700;

      @include mq($until: bp-xs) {
        left: 20px;
      }

      &--round {
        border-radius: 50%;
      }

      &--middle {
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }

    .tns-outer {
      flex: 1;
    }
  }

  &__carousel-nav {
    display: none;

    @include mq(bp-md) {
      display: block;
      overflow: hidden;
      flex-basis: 71px;

      &.is-masked {
        mask-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0,
          rgba(255, 255, 255, 1) 40px,
          rgba(255, 255, 255, 1) 40%,
          rgba(255, 255, 255, 0) 100%
        );
        margin-top: -#{global.vr(9)};
      }
    }
  }

  &__nav-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 67px;
    width: 67px;
    margin-bottom: global.vr(2);
    cursor: pointer;
    text-align: center;
    position: relative;
    border: 2px solid transparent;

    &.is-active {
      border-color: brand.$green;
    }

    &--video {
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(brand.$grey-45, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('~@img/icons/icon-play.svg');
        background-size: 20px;
        background-position: center center;
        background-repeat: no-repeat;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    &--reset-slide {
      cursor: pointer;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      outline: none;
      border: none;
      width: 67px;
      height: 67px;
      font-size: 10px;
      line-height: 10px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      @include fonts.lato();
      opacity: 0.6;
      transition: opacity 150ms ease;

      &:hover {
        opacity: 1;
      }
    }

    img {
      max-height: 63px;
      max-width: 63px;
    }

    &:focus {
      outline: none;
    }
  }

  &__icon-arrow {
    display: block;
    height: 13px;
    width: 18px;
    margin-bottom: global.vr(2);
    transform: rotateZ(-90deg);
  }

  &__overview-container {
    flex: 1;
    background-color: brand.$grey-95;
    box-shadow: 0px 3px 6px 0px rgba(brand.$black, 0.16);
    position: relative;
    overflow: hidden;

    @include mq(bp-md) {
      flex: 1 0 48%;
    }

    &.shards-animated {
      &:before,
      &:after {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url('~@img/shard--left.svg');
      background-size: 100%;
      background-position: bottom left;
      background-repeat: no-repeat;
      opacity: 0;
      transform: translateY(100%);
      transition: transform 500ms ease 0.3s, opacity 500ms ease 0.3s;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url('~@img/shard--right.svg');
      background-size: 100%;
      background-position: bottom right;
      background-repeat: no-repeat;
      opacity: 0;
      transform: translateY(100%);
      transition: transform 500ms ease 0.4s, opacity 500ms ease 0.4s;
    }
  }

  &__overview-content {
    position: relative;
    max-width: 750px;
    padding: global.vr(4) global.vr(3);
    z-index: layers.$layer-default;
    @include animations.fade-up(0.6s);

    @include mq($until: bp-md) {
      opacity: 1;
      transform: translateY(0);
    }

    @include mq(bp-md) {
      padding: global.vr(4) global.vr(5);
    }
  }

  &__model {
    @include fonts.lato-semi-bold();
    font-size: 13px;
    letter-spacing: 1px;
    color: brand.$grey-45;
  }

  &__product-name {
    font-size: 28px;
    line-height: 36px;
    color: brand.$grey-20;
    padding-bottom: global.vr(3);

    @include mq(bp-sm) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__reviews {
    display: flex;
    align-items: center;
    padding-bottom: global.vr(3);
  }

  &__review-stars {
    padding-right: global.vr(1);
  }

  &__review-star {
    width: auto;
    height: auto;
    margin-right: global.vr(0.5);
    display: inline-block;
    font-family: 'FontAwesome';
    background: none;
    margin-right: global.vr(0.25);
    font-size: 18px;

    @include mq(bp-xs) {
      margin-right: global.vr(0.5);
      font-size: 22px;
    }

    &::before {
      content: '\f005';
      display: inline-block;
      color: brand.$grey-80;
    }

    &.icon-star-full::before {
      color: brand.$green;
    }
  }

  &__reviews-number {
    @include fonts.lato-semi-bold();
    font-size: 14px;
    height: 18px;
    line-height: 18px;
    color: brand.$black;
    padding-right: global.vr(1);
    border-right: 1px solid brand.$black;
  }

  &__write-a-review {
    @include fonts.lato-regular-italic();
    font-size: 14px;
    color: brand.$black;
    padding-left: global.vr(1);
  }

  &__logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: global.vr(3) 0;
  }

  &__logo {
    display: inline-block;
    width: auto;
    padding-right: global.vr(2.5);

    @supports (display: grid) {
      height: 30px;
    }

    &--large {
      height: 100px;
    }
  }

  // IE
  &__logo-container img[src*='.svg'] {
    max-height: 30px;
    min-width: 100px;

    @supports (display: grid) {
      max-height: unset;
      min-width: unset;
    }
  }

  // IE
  &__logo-container img[src*='.png'] {
    max-height: 30px;

    @supports (display: grid) {
      max-height: unset;
    }
  }

  &__feature-container {
    padding: global.vr(3) 0;
  }

  &__feature-title {
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: brand.$grey-45;
    padding-bottom: global.vr(1);
  }

  &__feature {
    font-size: 14px;
    color: brand.$grey-35;
    padding-left: 28px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      top: 15px;
      left: 12px;
      transform: translate(-50%, -50%);
      background-color: brand.$green;
    }
  }

  &__show-more {
    @include fonts.lato-regular-italic();
    font-size: 13px;
    color: brand.$grey-20;
  }

  &__links {
    padding: global.vr(1) 0;
  }

  &__link {
    @include fonts.lato-semi-bold();
    font-size: 13px;
    color: brand.$grey-45;
    margin-right: global.vr(2);
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &__link-text {
    padding-left: global.vr(1);
  }

  &__link-icon {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    z-index: 2;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__variant-title {
    margin-top: global.vr(4);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: brand.$grey-45;
    font-size: 13px;
  }

  &__variant-model {
    font-size: 14px;
    color: brand.$grey-20;
    margin-bottom: global.vr(1);
  }

  &__variants {
    display: flex;
  }

  &__variant {
    width: 78px;
    height: 78px;
    background-color: brand.$white;
    border-radius: 50%;
    position: relative;
    border: 1px solid brand.$white;
    cursor: pointer;
    display: flex;
    margin: 0 auto;
    overflow: hidden;

    &:hover,
    &.is-active {
      border: 1px solid brand.$green;

      img {
        transform: translate(-50%, -50%) scale(1.6);
      }
    }

    img {
      position: absolute;
      width: 35px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      transition: transform 0.2s;
    }
  }

  &__variant-battery {
    height: auto;
    max-height: 30px;
    min-width: 100px;
    margin-top: 10px;

    @supports (display: grid) {
      height: 30px;
      max-height: unset;
      min-width: unset;
    }
  }

  &__variant-container {
    display: flex;
    flex-direction: column;
    margin-right: global.vr(4);
    text-align: center;

    p {
      @supports (display: grid) {
        flex: 1;
      }
      font-size: 14px;
      color: brand.$grey-20;
    }
  }

  &__tooltip-container {
    position: relative;

    &:hover .ds-product-detail__tooltip {
      opacity: 1;
      z-index: 10;
      transform: translateX(-72px) translateY(0);
      pointer-events: all;
    }
  }

  &__tooltip {
    display: none;
    background-color: brand.$brand-primary;
    color: brand.$black;
    width: 128px;
    font-size: 14px;
    text-align: center;
    padding: global.vr(0.6) global.vr(1.5);
    position: absolute;
    z-index: layers.$layer-default;
    top: calc(100% + 7px);
    left: 50%;
    transform: translateX(-72px) translateY(6px);
    opacity: 0;
    transition: opacity 150ms ease, transform 150ms ease;
    pointer-events: none;

    @include mq(bp-md) {
      display: block;
    }

    &:before {
      display: block;
      content: ' ';
      position: absolute;
      top: -7px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 7px 7px;
      border-color: transparent transparent brand.$brand-primary transparent;
    }

    &:after {
      display: block;
      content: ' ';
      position: absolute;
      top: -7px;
      left: 0;
      height: 7px;
      width: 100%;
    }
  }
}
