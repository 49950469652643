@use './helpers/animations';
@use './settings/brand';
@use './helpers/fonts';
@use './helpers/global';

.ds-three-column-content {
  &__media-container {
    max-width: 396px;

    &--left {
      @include animations.fade-left($mode: parent, $delay: 0.6s);
    }

    &--right {
      @include animations.fade-right($mode: parent, $delay: 0.6s);
    }
  }
  &__media {
    width: 100%;
    max-width: 396px;
  }

  &__media-title {
    @include fonts.lato-bold();
    font-size: 24px;
    line-height: 30px;
    background-color: brand.$grey-30;
    color: brand.$white;
    margin: 0 global.vr(3);
    padding: global.vr(2) 0;
    text-align: center;
    position: relative;
    top: -30px;
  }

  &__content-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include animations.fade-up($mode: parent, $delay: 0.2s);
  }

  &__arrow-container {
    display: flex;
    padding-bottom: global.vr(2);
  }

  &__arrow {
    width: 28px;
    height: 28px;
    margin: 0 global.vr(2);
    svg {
      fill: brand.$grey-max-power;
      width: 28px;
      height: 28px;
    }

    &--left {
      transform: rotate(180deg);
    }
  }

  &__title {
    @include fonts.lato-bold();
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: brand.$grey-max-power;
    padding-bottom: global.vr(2);
  }

  &__subtitle {
    @include fonts.lato-regular();
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: brand.$grey-max-power;
    width: 80%;
    padding-bottom: global.vr(2);
  }
}
