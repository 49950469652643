@use '~sass-mq/mq' as *;
@use '../settings/brand';
@use '../helpers/fonts';

/* These are rich text styles used by the QuillJS rich text editor.
   Add new colours to: '/sitefiles/formcontrols/vendor/quilljs/quill.custom.css' too.
*/

.ql-color-brandgreen {
  color: brand.$green !important;
}

.ql-color-brandwhite {
  color: brand.$white !important;
}

.ql-color-brandblack {
  color: brand.$black !important;
}

.ql-color-branddarkgrey {
  color: brand.$grey-max-power !important;
}
