@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './settings/layers';
@use './settings/animation';
@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

.fs-sold-as {
  padding: global.vr(6) 0 global.vr(2);

  @include mq($from: bp-sm) {
    padding: global.vr(9) 0;
  }

  h2 {
    text-align: center;
    margin-bottom: global.vr(1);

    @include mq($from: bp-md) {
      margin-bottom: global.vr(4.2);
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-y: hidden;
  }

  &__item {
    width: 100%;
    text-align: center;
    margin-bottom: global.vr(5);

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        @include animations.fade-up($i * 100ms, self);
      }
    }

    @include mq($from: bp-xs) {
      width: 50%;
      flex-basis: 50%;
    }

    &-inner {
      padding: 0 global.vr(0.5);
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      height: 100%;

      @include mq($from: bp-sm) {
        padding: 0 global.vr(1);
      }
    }

    small {
      display: block;
      font-size: 14px;
      color: brand.$grey-45;
    }

    strong {
      display: block;
      @include fonts.lato-semi-bold;
      font-size: 16px;
      margin-bottom: global.vr(2);

      @include mq($from: bp-sm) {
        font-size: 18px;
      }
    }

    .ds-btn {
      margin-top: auto;
    }
  }

  &__image {
    display: block;
    border: 1px solid brand.$grey-95;
    flex-shrink: 0;
    margin-bottom: global.vr(3.2);
  }

  .tns-controls {
    text-align: right;
  }

  [data-controls] {
    display: inline-flex;
    align-items: center;
    background: #cddb00;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    height: 40px;
    justify-content: center;
    transition: box-shadow 0.3s animation.$ease-in-out-cubic,
      background-color 0.3s animation.$ease-in-out-cubic,
      transform 0.3s animation.$ease-in-out-cubic;
    transform: translateY(0);
    width: 40px;

    &:focus,
    &:hover {
      background: brand.$white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      text-decoration: none;
      transform: translateY(-2px);
    }

    svg {
      display: block;
      height: 12px;
      width: 16px;
    }

    @include mq(bp-sm) {
      height: 50px;
      width: 50px;

      svg {
        display: block;
        height: 15px;
        width: 20px;
      }
    }
  }

  [data-controls='prev'] {
    svg {
      transform: rotate(180deg);
    }
  }

  [data-controls='next'] {
    margin-left: global.vr(2);
  }
}
