@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/brand';

.ds-pagination {
  display: flex;
  padding: global.vr(1) 0;

  &__item {
    @include fonts.lato-regular();
    display: inline-block;
    border-bottom: 1px solid transparent;
    margin: 0 global.vr(0.5);
    font-size: 16px;
    line-height: 19px;

    a {
      cursor: pointer;
      text-decoration: none;
      color: brand.$grey-20;
      font-size: inherit;
      transition: color 150ms ease;

      &:focus,
      &:hover {
        color: #cddb00;
        text-decoration: none;
      }
    }

    &--active {
      border-bottom-color: #cddb00;

      a {
        cursor: default;
        text-decoration: none;

        &:focus,
        &:hover {
          color: brand.$grey-20;
          text-decoration: none;
        }
      }
    }
  }
}
