@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use './settings/animation';
@use './settings/brand';
@use './settings/breakpoints' as *;
@use './settings/grid';

.pl-header {
  background-color: brand.$grey-95;
  margin-top: -10px;

  @include mq(bp-xs) {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -25px;
  }

  @include mq(bp-sm) {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 0px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    padding: 30px 0 0;

    @include mq(bp-sm) {
      padding: 0 0 0 60px;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    background-color: brand.$grey-95;
    transform: skewX(0);
    position: relative;
    z-index: 1;

    @include mq(bp-sm) {
      transform: skewX(-13deg);
    }

    &-inner {
      flex: 1 0 auto;
      align-self: center;
      width: 100%;
      transform: skewX(0);
      padding: 15px;

      @include mq(bp-sm) {
        transform: skewX(13deg);
        max-width: 500px;
        padding: 15px 0;
      }

      @include mq(bp-md) {
        max-width: 600px;
      }

      @include mq(bp-xl) {
        max-width: 750px;
      }
    }

    h1 {
      font-size: 22px;
      text-transform: none;
      margin-bottom: 22px;
      padding-bottom: 0px;
      @include animations.fade-up(250ms, parent);
      text-align: center;

      @include mq(bp-sm) {
        font-size: 40px;
        margin-bottom: 32px;
        text-align: left;
      }

      @include mq(bp-lg) {
        font-size: 66px;
      }
    }

    p {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 12px;
      @include animations.fade-up(350ms, parent);

      @include mq($until: bp-xs) {
        font-size: 14px;
      }
    }

    &-more {
      font-size: 16px;
      color: brand.$grey-60;
      display: inline-block;
      cursor: pointer;
      user-select: none;
      @include animations.fade-up(450ms, parent);
    }
  }

  &__media {
    user-select: none;
    @include animations.image-fade(500ms, parent);
    width: 100%;

    @include mq(bp-sm) {
      margin-left: -60px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-height: 430px;
      font-family: 'object-fit: cover;';
      object-position: center;
    }

    // IE
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex: 0 0 auto !important;
      max-width: 100% !important;

      @include mq(bp-sm) {
        max-width: calc(100% - 550px) !important;
      }

      @include mq(bp-md) {
        max-width: calc(100% - 650px) !important;
      }

      @include mq(bp-xl) {
        max-width: calc(100% - 750px) !important;
      }

      img {
        flex-shrink: 0;
      }
    }
  }

  &__toggle {
    padding: 15px;
    background-color: brand.$green;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-text {
      text-transform: uppercase;
      line-height: 1;
      font-size: 14px;
      padding-right: 30px;
      position: relative;
      user-select: none;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 6px;
        width: 13px;
        height: 7px;
        background-image: url('~@img/icons/icon-angle-down.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &.active .pl-header__toggle-text:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.pl-header-filters {
  background-color: brand.$grey-95;
  padding: 30px 15px 30px 15px;

  @include mq(bp-xs) {
    padding: 60px 40px 60px 40px;
  }

  @include mq(bp-sm) {
    padding: 60px 80px 60px 115px;
  }

  &__inner {
    display: flex;
    flex-direction: column;

    @include mq(bp-sm) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &__content {
    opacity: 0;
    text-align: center;
    animation: filter-fade-up 1s forwards;

    @include mq(bp-sm) {
      flex: 1 0 auto;
      max-width: 330px;
      margin-right: 40px;
      text-align: left;
    }

    h2 {
      font-size: 20px;
      margin-bottom: 15px;
      text-transform: uppercase;

      @include mq(bp-sm) {
        font-size: 32px;
      }
    }

    h3 {
      font-size: 12px;
      margin-bottom: 10px;
      text-transform: uppercase;

      @include mq(bp-sm) {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
  }

  &__options {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    @include mq(bp-sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__option {
    opacity: 0;
    padding: 14px;
    background-color: brand.$white;
    border-radius: 5px;
    border: 2px solid transparent;
    width: 100%;
    margin-bottom: 30px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 17px;
    cursor: pointer;
    user-select: none;
    text-align: left;
    animation: filter-fade-up 500ms forwards;

    @for $i from 1 to 10 {
      &:nth-child(#{$i}) {
        animation-delay: $i * 100ms;
      }
    }

    @include mq($until: bp-sm) {
      &:last-child {
        margin-bottom: 0px;
      }
    }

    @include mq(bp-md) {
      max-width: 350px;
    }

    @include mq(bp-sm) {
      font-size: 22px;
      padding: 20px 28px;
      width: calc(100% - 12px);
      margin-right: 24px;
      margin-bottom: 24px;
    }

    &.active,
    &:hover {
      border: 2px solid brand.$green;
    }
  }
}

@keyframes filter-fade-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
