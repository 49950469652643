@use '../../helpers/animations';
@use '../../helpers/fonts';
@use '../../helpers/global';

@use '../../settings/brand';
@use '../../settings/breakpoints' as *;
@use '../../settings/type';

.ds-awards {
  &__title {
    font-size: 32px;
    @include fonts.lato-medium();
    @include animations.fade-up(0s, $mode: self);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    @include mq(bp-xs) {
      margin-left: -20px;
      margin-right: -20px;
    }

    > * {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 40px;
      flex-basis: calc(#{percentage(1 / 2)} - 40px);

      @include mq(bp-sm) {
        flex-basis: calc(#{percentage(1 / 3)} - 40px);
      }
    }

    @supports (display: grid) {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      margin-left: 0;
      margin-right: 0;

      @include mq(bp-xs) {
        gap: 40px;
      }

      @include mq(bp-sm) {
        grid-template-columns: repeat(3, 1fr);
      }

      > * {
        margin-bottom: 0;
        padding: 0;
      }
    }
  }
}
