@use '~sass-mq/mq' as *;
@use '../settings/brand';
@use '../helpers/fonts';
@use '../helpers/global';
@use '../helpers/animations';
@use '../settings/animation';

.step-guide {
  &--roboyagi {
    background-color: brand.$grey-max-power;
    .step-guide__subtitle {
      color: brand.$white;
    }

    .step-guide__title {
      color: brand.$white;
    }

    .step-guide__tab-container {
      border: none;
    }

    .step-guide__tab {
      color: brand.$white;

      &:hover,
      &:focus {
        color: brand.$brand-primary;
      }

      &.active {
        color: brand.$brand-primary;

        &::after {
          background-color: brand.$brand-primary;
        }

        &:before {
          background-color: brand.$brand-primary;
        }
      }
    }

    .step-guide__item--title {
      color: brand.$white;
    }

    .step-guide__item--description {
      color: brand.$white;

      p {
        color: brand.$white;
      }
    }

    .step-guide__download-item {
      border-top: 1px solid brand.$white;
      border-bottom: 1px solid brand.$white;

      &--title {
        color: brand.$white;
      }

      &--size {
        color: brand.$white;
        opacity: 50%;
      }

      &--link {
        color: brand.$white;
      }
    }
  }

  &__container {
    padding: global.vr(6) 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__subtitle {
    @include fonts.lato-regular();
    font-size: 14px;
    color: brand.$grey-35;
    padding-bottom: global.vr(3);

    @include animations.fade-up();
  }

  &__title {
    @include fonts.lato-regular();
    font-size: 32px;
    color: brand.$grey-35;
    padding-bottom: global.vr(6);

    @include animations.fade-up();
  }

  &__tab-container {
    border-bottom: 1px solid brand.$brand-primary;
  }

  &__tabs {
    display: flex;
    justify-content: center;

    @include mq($until: bp-xs) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__tab {
    @include fonts.lato-regular();
    font-size: 18px;
    text-transform: uppercase;
    color: brand.$grey-35;
    padding: 0 global.vr(6) global.vr(2);
    cursor: pointer;
    position: relative;

    @include mq($until: bp-xs) {
      margin-bottom: global.vr(2);
    }

    &:nth-child(1) {
      @include animations.fade-up();
    }

    &:nth-child(2) {
      @include animations.fade-up(0.3s);
    }

    &:nth-child(3) {
      @include animations.fade-up(0.6s);
    }

    &:hover,
    &:focus {
      color: brand.$grey-35;
      text-decoration: none;
    }

    &:before {
      content: '';
      width: 0%;
      transition: width 0.3s animation.$ease-in-out-cubic;
    }

    &::after {
      content: '';
      width: 0%;
      transition: width 0.3s animation.$ease-in-out-cubic;
    }

    &.active {
      @include fonts.lato-black();
      padding: 0 global.vr(6) 14px;

      &::after {
        content: '';
        position: absolute;
        width: 50%;
        height: 2px;
        background-color: brand.$brand-primary;
        bottom: 0;
        left: 0;
      }
      &::before {
        content: '';
        position: absolute;
        width: 50%;
        height: 2px;
        background-color: brand.$brand-primary;
        bottom: 0;
        right: 0;
      }
    }
  }

  &__item {
    opacity: 0;
    height: 0;
    visibility: hidden;

    &.active {
      padding: global.vr(6) 0;
      opacity: 1;
      height: 100%;
      visibility: visible;
    }

    &-container {
      display: flex;

      @include mq($until: bp-xs) {
        flex-direction: column;
        align-items: center;
        padding: 0 global.vr(2);
      }
    }

    &--img {
      width: 50%;
      margin-right: global.vr(4);
      opacity: 0;
      transform: translateX(-50%);
      transition: all 0.6s animation.$ease-in-out-cubic;

      @include mq($until: bp-xs) {
        width: 100%;
        margin-right: 0;
        display: flex;
        justify-content: center;
      }

      .active & {
        opacity: 1;
        transform: translateX(0);
      }
    }

    &--media {
      display: block;
      width: 50%;
      margin-right: global.vr(4);
      opacity: 0;
      transform: translateX(-50%);
      transition: all 0.6s animation.$ease-in-out-cubic;

      @include mq($until: bp-xs) {
        width: 100%;
        margin-right: 0;
      }

      .active & {
        opacity: 1;
        transform: translateX(0);
      }

      .cover-image {
        position: relative;

        a {
          display: block;
          height: 0;
          padding-bottom: 56.25%;
          position: relative;
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: cover;

          &:after {
            content: '';
            display: block;
            width: 50px;
            height: 60px;
            background: url('~@img/icons/icon-play-stepguide.svg') no-repeat center;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include mq(bp-xs) {
              width: 86px;
              height: 100px;
            }
          }
        }
      }
    }

    &--content {
      width: 50%;
      opacity: 0;
      transform: translateX(50%);
      transition: all 0.6s animation.$ease-in-out-cubic;

      @include mq($until: bp-xs) {
        width: 100%;
      }

      .active & {
        opacity: 1;
        transform: translateX(0);
      }
    }

    &--title {
      @include fonts.lato-regular();
      font-size: 14px;
      color: brand.$grey-35;
      opacity: 0.5;
      text-transform: uppercase;
      padding-bottom: global.vr(1);
    }

    &--description {
      p {
        @include fonts.lato-regular();
        font-size: 14px;
        color: brand.$grey-35;
      }
    }
  }

  &__downloads {
    padding-bottom: global.vr(6);
  }

  &__download-item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid brand.$grey-20;
    border-bottom: 1px solid brand.$grey-20;
    padding: global.vr(2) global.vr(1);
    margin-top: -1px;

    @include animations.fade-up();

    &--left {
      display: flex;
      align-items: center;
    }

    &--icon {
      display: flex;
      padding-right: global.vr(1);
      svg {
        width: 20px;
        height: 30px;
      }
    }

    &--title {
      @include fonts.lato-regular();
      font-size: 18px;
      color: brand.$grey-35;
      padding-right: global.vr(1);
    }

    &--size {
      @include fonts.lato-regular();
      font-size: 14px;
      color: brand.$grey-35;
      opacity: 0.5;
    }

    &--right {
      display: flex;
      align-items: center;
    }

    &--link {
      @include fonts.lato-regular-italic();
      font-size: 16px;
      color: brand.$grey-35;
      text-decoration: underline;
      padding-right: global.vr(2);
      cursor: pointer;
    }

    &--arrow {
      display: flex;
      svg {
        width: 15px;
        height: 20px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq($until: bp-xxs) {
      flex-direction: column;
      align-items: center;
    }

    @include animations.fade-up();

    &--text {
      padding-right: global.vr(3);

      @include mq($until: bp-xxs) {
        padding-right: 0;
        padding-bottom: global.vr(2);
      }
    }
  }
}
