@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';
@use '../helpers/mixins';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-modal {
  position: fixed;
  display: flex;
  align-items: center;
  background: rgba(brand.$black, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 300ms ease;

  &.open {
    opacity: 1;
    height: 100%;
    padding: 125px 20px;
    z-index: 10;

    .ds-modal__container,
    .ds-modal__video-container {
      opacity: 1;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    width: 17px;
    height: 17px;
    background-image: url('~@img/icons/icon-cross--black.svg');
    font-size: 0;

    .ds-modal__video-container & {
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background: rgba(brand.$black, 0.8) url('~@img/icons/icon-cross.svg') no-repeat center;
    }
  }

  &__caption {
    display: block;
    font-size: 14px;
    line-height: 14px;
    color: brand.$black;
    margin-bottom: 14px;
    @include fonts.lato-regular-italic();
  }

  &__product-code {
    font-size: 13px;
    letter-spacing: 1px;
    color: brand.$grey-45;
    margin-bottom: 2px;
    @include fonts.lato-semi-bold();
  }

  &__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 22px;
    @include fonts.lato-regular();
  }

  &__container {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    background: brand.$white;
    border: 10px solid brand.$green;
    flex-direction: column;
    margin: 0 auto;
    height: 100vh;
    height: calc(100vh - 40px);
    max-width: 1000px;
    opacity: 0;
    transition: opacity 300ms ease 150ms;
    width: 100%;

    @include mq($until: bp-xs) {
      // added to fix close button moving off the screen on mobile
      height: calc(100vh - 120px);
    }

    @supports (display: grid) {
      height: auto;
      max-height: 100vh;
      max-height: calc(100vh - 40px);
    }
  }

  &__scroll-container {
    width: 100%;
    height: 100%;
    padding: 0;
    overflow-y: auto;
    @include mixins.custom-scrollbar(transparent, brand.$white, brand.$grey-70);

    .ds-modal--no-scroll & {
      overflow: hidden;
    }
  }

  &__video-container {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    max-height: 100vh;
    max-height: calc(100vh - 40px);
    background: brand.$black;
    height: auto;
    opacity: 0;
    transition: opacity 300ms ease 150ms;
  }

  &__video {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    outline: none;
  }

  &__inner {
    position: relative;
    padding: 44px 20px 38px;
    margin: 0 auto;
    overflow: hidden;

    .ds-modal-split {
      @include mq($from: bp-xs) {
        display: flex;
        flex-direction: row;
        margin-bottom: 22px;
      }

      &--45-55 {
        .ds-modal-split__item {
          &:nth-child(1) {
            flex: 1 1 45%;
          }
          &:nth-child(2) {
            flex: 1 1 55%;
          }
        }
      }

      &__item {
        flex: 1 1 50%;

        @include mq($until: bp-xs) {
          &:first-child {
            margin-bottom: 20px;
          }
        }

        label {
          display: block;
          font-size: 13px;
          line-height: 13px;
          margin-bottom: 10px;
          color: brand.$black;
          @include fonts.lato-regular();
        }

        .ds-stars {
          color: brand.$green;

          &__item {
            height: 28px;
            width: 29px;
          }
        }
      }
    }

    p {
      font-size: 14px;
      line-height: 24px;
      @include fonts.lato-regular();
    }
  }

  &__confirm {
    display: inline-block;
    margin-top: 20px;
    width: 102px;
    height: 102px;
  }

  &__nav {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: row;
    height: 52px;
    justify-content: space-between;
    margin: 0 auto;
    pointer-events: none;
    transform: translate(-50%, -50%);
    user-select: none;
    width: calc(100% + 104px + #{global.vr(10)});
  }

  &__nav-btn {
    display: inline-flex;
    align-items: center;
    border: 2px solid brand.$white;
    border-radius: 50%;
    color: brand.$white;
    font-size: 0;
    height: 52px;
    justify-content: center;
    pointer-events: all;
    transition: transform 0.3s animation.$ease-in-out-cubic;
    width: 52px;

    svg {
      height: 23px;
    }

    &--prev {
      margin-right: auto;
      transform: rotate(90deg);

      &:focus,
      &:hover {
        transform: translateX(-10px) rotate(90deg);
      }
    }

    &--next {
      margin-left: auto;
      transform: rotate(-90deg);

      &:focus,
      &:hover {
        transform: translateX(10px) rotate(-90deg);
      }
    }
  }
}
