@use '../helpers/fonts';
@use '../helpers/global';
@use '../settings/brand';
@use '../settings/layers';
@use '~sass-mq/mq' as *;

.sticky-jump-page-nav {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 15px;
  width: 100%;
  background: #54585a;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  z-index: layers.$layer-god;

  &--black {
    background: brand.$black;
  }

  @include mq($from: bp-sm) {
    display: block;
  }

  &__mob-label {
    display: inline-block;
    color: brand.$white;
    @include fonts.lato-regular();
    font-size: 12px;
    margin-right: 24px;
    text-transform: uppercase;

    @include mq($from: bp-sm) {
      display: none;
    }
  }

  &__selected-item {
    position: relative;
    cursor: pointer;
    display: block;
    max-width: 280px;
    padding: 0 6px;
    width: 100%;
    height: 26px;
    border-bottom: 1px solid brand.$white;
    color: brand.$white;
    @include fonts.lato-bold();
    font-size: 14px;
    text-transform: uppercase;

    @include mq($from: bp-sm) {
      position: absolute;
      left: -9999px;
    }

    &:after {
      content: '';
      width: 15px;
      height: 20px;
      background-image: url('~@img/icons/down-arrow.png');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    flex: 1;

    @include mq($from: bp-sm) {
      display: block;
    }
  }

  &__list {
    flex: 1;
    height: inherit;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    display: table;
    width: 100%;

    // excludes IE
    @supports (display: grid) {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    // EDGE
    @supports (-ms-ime-align: auto) {
      justify-content: space-between;
    }

    @include mq($until: bp-sm) {
      background: #54585a;
      position: absolute;
      overflow: hidden;
      z-index: layers.$layer-root;
      height: auto;
      max-height: 0;
      max-width: 292px;
      margin: 0 -6px;
      width: 100%;
      top: 100%;
      left: 0;
      display: block;
      padding: 0 12px;
      opacity: 0;
      transition: opacity 0.3s ease, max-height 0.3s ease;

      &.is-open {
        height: auto;
        z-index: layers.$layer-default;
        opacity: 1;
        max-height: 600px;
      }
    }

    &-item {
      display: table-cell;
      vertical-align: middle;
      text-align: center;

      @supports (display: grid) {
        display: block;
        text-align: unset;
      }

      @include mq($until: bp-sm) {
        &:last-child a {
          padding-bottom: 12px;
        }
      }

      a {
        cursor: pointer;
        color: brand.$white;
        @include fonts.lato-bold();
        font-size: 12px;
        text-transform: uppercase;

        @include mq($until: bp-sm) {
          padding: 8px 0;
          display: block;
        }
      }
    }
  }
}
