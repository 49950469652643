@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.mp-split-skew {
  position: relative;
  display: block;
  background: brand.$grey-max-power;
  color: brand.$grey-max-power;
  overflow: hidden;
  padding: global.vr(4) 0 global.vr(4);

  @include mq($until: bp-sm) {
    padding: global.vr(6) 0 global.vr(6);

    background-color: brand.$brand-cddb00;
    .tag-runtime--2,
    .tag-cuts--2,
    .tag-cuts-to--2,
    .tag-cuts--3 span {
      color: brand.$grey-max-power;
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    height: calc(100% - 556px);
    width: 100vw;
    background: brand.$brand-cddb00;
    z-index: 1;

    @include mq($from: bp-sm) {
      margin: global.vr(12) 0 global.vr(12);
    }
  }

  @include mq(bp-sm) {
    &:before {
      top: 0;
      bottom: auto;
      height: calc(100% - #{global.vr(24)});
      transform-origin: bottom right;
      transition: transform 750ms 0ms cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: skewX(-10.5deg) translateX(-110%);
    }

    &.animated:before {
      transform: skewX(-10.5deg) translateX(-36%);
    }

    & + & {
      padding-top: 0;

      &::before {
        height: calc(100% - #{global.vr(12)});
        margin-top: 0;
      }
    }
  }

  &__grid-container {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    z-index: 2;

    @supports (display: grid) {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(12, 1fr);
    }

    @include mq(bp-sm) {
      display: flex;
      flex-direction: row;

      @supports (display: grid) {
        display: grid;
        gap: 28px;
        grid-template-columns: repeat(12, 1fr);
      }
    }
  }

  &__grid-column {
    min-width: auto;
    text-align: center;

    &:nth-child(1) {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: global.vr(4) 0 global.vr(4);

      @supports (display: grid) {
        grid-column: 1 / span 12;
        grid-row: 2 / span 1;
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: center;

      @supports (display: grid) {
        grid-column: 1 / span 12;
        grid-row: 1 / span 1;
        margin-left: 0;
      }
    }

    @include mq(bp-sm) {
      @supports (display: grid) {
        &:nth-child(1),
        &:nth-child(2) {
          grid-row: 1 / span 1;
        }
      }

      &:nth-child(1) {
        align-items: flex-start;
        padding: global.vr(12) 0 global.vr(12);
        text-align: left;
      }

      &:nth-child(2) {
        text-align: right;
      }
    }

    @include mq(bp-sm) {
      &:nth-child(1) {
        flex-basis: percentage(1/2);
        margin-right: 14px;

        @supports (display: grid) {
          grid-column: 1 / span 6;
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        flex-basis: percentage(1/2);
        margin-left: 14px;

        @supports (display: grid) {
          grid-column: 7 / span 6;
          margin-left: 0;
        }
      }
    }
  }

  &__title-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

    @include mq(bp-sm) {
      align-items: flex-start;
      margin: 0;
    }

    &--reverse {
      flex-direction: column-reverse;
    }
  }

  &__title {
    display: block;
    @include fonts.lato-black();
    font-size: 30px;
    @include fonts.ls(-10);
    margin-bottom: global.vr(3);
    max-width: 500px;
    width: 100%;
    text-transform: uppercase;

    @include mq($from: bp-sm) {
      font-size: 40px;
      line-height: 39px;
    }

    em {
      font-style: normal;
      color: brand.$white;
    }
  }

  &__logo {
    display: inline-block;
    width: 140px;
    margin-bottom: global.vr(3);
  }

  &__content {
    display: block;
    @include fonts.lato-regular();
    font-size: 16px;
    @include fonts.ls(20);
    line-height: 26px;
    margin: 0 auto global.vr(3);
    max-width: 500px;

    @include mq(bp-sm) {
      margin: 0 0 global.vr(3);
    }
  }

  &__print {
    position: absolute;
    left: 0;
    bottom: 75px;
    display: none;
    color: brand.$white;
    @include fonts.lato-regular();
    font-size: 16px;
    @include fonts.ls(20);
    line-height: 26px;
    margin: 0;
    padding: global.vr(2) 0 0;
    transform: translateY(100%);

    @include mq(bp-sm) {
      display: block;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
    flex: 0 1 auto;
    max-width: 500px;
    width: 100%;
    align-self: center;
    order: 2;

    @include mq(bp-sm) {
      margin: 0;
      max-height: none;
      max-width: none;
      transition: transform 375ms 600ms ease, opacity 200ms 600ms ease;
      opacity: 0;
      transform: translateY(50px);
      align-self: flex-start;
      order: initial;

      .animated & {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }

  &__image-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    align-items: center;

    @include mq($from: bp-sm) {
      display: block;
      align-items: flex-start;
      flex-wrap: nowrap;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    color: brand.$white;
    justify-content: center;
    padding: 20px 0px;
    flex: 1 0 auto;
    order: 1;
    width: 50%;

    @include mq($from: bp-sm) {
      order: initial;
      width: 100%;
      justify-content: flex-end;

      &:first-child {
        transform: translateY(40%);
      }

      &:nth-child(2) {
        transform: translateY(40%);
      }

      &:nth-child(3) {
        transform: translateY(-30%);
      }
    }

    .mp-split-skew--reverse & {
      @include mq($from: bp-sm) {
        justify-content: flex-start;
        padding-top: global.vr(5);
        margin-bottom: -90px;
        margin-top: 90px;

        &:first-child {
          margin-top: 0px;
        }
      }
    }
  }

  &__tags {
    background: brand.$grey-max-power;
    color: brand.$white;

    &-inner {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;

      @media (min-width: 340px) {
        flex-direction: row;
        align-items: flex-start;
      }

      @include mq($from: bp-sm) {
        align-items: initial;
        justify-content: space-around;
      }
    }
  }

  &__tag {
    margin: global.vr(3) 0px;
    flex: 1 0 auto;
    max-width: 33%;

    @include mq($from: bp-sm) {
      max-width: none;
    }

    svg {
      max-width: 80px;
      height: auto;

      @include mq($from: bp-sm) {
        max-width: none;
      }
    }
  }

  &--dark {
    background-color: brand.$grey-max-power;
    color: brand.$white;
  }

  &--reverse {
    @include mq(bp-sm) {
      background-color: brand.$grey-max-power;

      &::before {
        content: '';
        right: auto;
        left: 0;
        transform: skewX(-10.5deg) translateX(110%);
      }

      &.animated:before {
        transform: skewX(-10.5deg) translateX(32.3%);
      }
    }

    .mp-split-skew__grid-column {
      &:nth-child(2) {
        align-items: flex-start;
      }

      @include mq(bp-sm) {
        &:nth-child(1) {
          order: 2;
          margin-right: 14px;
          text-align: left;

          @supports (display: grid) {
            grid-column-start: 7;
            margin-right: 0;
          }
        }

        &:nth-child(2) {
          order: 1;
          margin-left: 14px;
          text-align: left;

          @supports (display: grid) {
            grid-column-start: 1;
            margin-left: 0;
          }
        }
      }
    }
  }
}
