@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use './settings/brand';
@use './settings/breakpoints' as *;

.ds-top-tips-icons {
  padding: global.vr(10) 0;

  &--green {
    background-color: brand.$green;
  }

  &--black {
    background-color: brand.$grey-max-power;

    .ds-top-tips-icons__title,
    .ds-top-tips-icons__content,
    .ds-top-tips-icons__item-title,
    .ds-top-tips-icons__item-content {
      color: brand.$white;
    }
  }

  @include mq($until: bp-xs) {
    padding: global.vr(5) 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    padding-bottom: 30px;
    
    svg {
      width: 54px;
      height: 50px;
    }
  }

  &__title {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-black();
    font-size: 32px;
    line-height: 34px;
    color: brand.$grey-max-power;
    padding-bottom: global.vr(3);

    &--padding {
      padding-bottom: global.vr(10);

      @include mq($until: bp-xs) {
        padding-bottom: global.vr(6);
      }
    }
  }

  &__content {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-regular();
    font-size: 18px;
    line-height: 28px;
    color: brand.$grey-max-power;
    padding-bottom: global.vr(5);
    max-width: 820px;
    text-align: center;
  }

  &__tips {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &--centred {
      flex-direction: column;

      .ds-top-tips-icons__item {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  &__item {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
    justify-content: center;
    margin: 0 global.vr(2) global.vr(4);

    &:nth-child(1) {
      @include animations.fade-up($mode: parent, $delay: 0.6s);
    }
    &:nth-child(2) {
      @include animations.fade-up($mode: parent, $delay: 1s);
    }
    &:nth-child(3) {
      @include animations.fade-up($mode: parent, $delay: 1.3s);
    }
    &:nth-child(4) {
      @include animations.fade-up($mode: parent, $delay: 1.6s);
    }
    &:nth-child(5) {
      @include animations.fade-up($mode: parent, $delay: 1.9s);
    }
    &:nth-child(6) {
      @include animations.fade-up($mode: parent, $delay: 2.2s);
    }

    @include mq(bp-sm) {
      flex: 0 0 45%;
      width: 45%;
    }

    &-number-container {
      position: absolute;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: #595b60;

      &--large {
        position: relative;
        width: 60px;
        height: 72px;
        background-color: transparent;
        border-radius: 0;

        .ds-top-tips-icons__item-number {
          font-size: 60px;
        }
      }
    }

    &-number {
      font-size: 13px;
      color: brand.$white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-icon {
      position: relative;
      padding-right: global.vr(3);
      svg {
        width: 77px;
        height: 77px;
        border-radius: 50%;

        &.svg--large {
          width: 120px;
          height: 120px;
        }
      }

      img {
        max-width: 77px;

        &.img--large {
          max-width: 120px;
        }
      }
    }

    &-content-container {
      max-width: 396px;
    }

    &-title {
      @include fonts.lato-black();
      font-size: 21px;
      line-height: 26px;
      color: brand.$grey-max-power;
      padding-bottom: global.vr(1);
    }

    &-content {
      @include fonts.lato-regular();
      font-size: 16px;
      line-height: 24px;
      color: brand.$grey-max-power;
    }
  }
}
