@use '../../helpers/animations';
@use '../../helpers/fonts';
@use '../../helpers/global';
@use '../../settings/brand';
@use '../../settings/breakpoints' as *;
@use '../../settings/animation';

.ds-battery-table {
  padding: global.vr(5) 0;
  display: block;
  position: relative;
  margin: 0 global.vr(5);

  @include mq(bp-md) {
    margin: 0;
  }

  &__container {
    display: flex;
  }

  &__recommended-tag {
    position: absolute;
    top: 0;
    left: -16px;
    width: auto;
    background-color: brand.$green;
    transform: skew(-45deg);

    &-text {
      @include fonts.lato-medium();
      font-size: 10px;
      text-transform: uppercase;
      padding: global.vr(0.25) global.vr(3) global.vr(0.25) global.vr(3);
      transform: skew(45deg);
      color: brand.$grey-10;
    }
  }

  .tns-controls {
    display: flex;
    flex-direction: row;
    width: 100%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    [data-controls] {
      display: block;
      background: #cddb00;
      border-radius: 50%;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      height: 40px;
      transition: box-shadow 0.3s animation.$ease-in-out-cubic,
        background-color 0.3s animation.$ease-in-out-cubic,
        transform 0.3s animation.$ease-in-out-cubic;
      width: 40px;
      font-size: 13px;

      &:focus,
      &:hover {
        background: brand.$white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        text-decoration: none;
        transform: translateY(-2px);
      }

      svg {
        display: block;
        height: 12px;
        width: 16px;
        margin: 0 auto;
      }

      @include mq(bp-sm) {
        height: 50px;
        width: 50px;

        svg {
          display: block;
          height: 15px;
          width: 20px;
        }
      }
    }

    [data-controls='prev'] {
      svg {
        transform: rotate(180deg);
      }
      position: absolute;
      left: -45px;

      @include mq(bp-sm) {
        left: -60px;
      }
    }

    [data-controls='next'] {
      position: absolute;
      right: -45px;

      @include mq(bp-sm) {
        right: -60px;
      }
    }
  }

  // add padding to top of carousel so hover state isn't clipped
  .tns-ovh {
    padding-top: 10px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #cfcfce;
    transition: transform 0.3s animation.$ease-in-out-cubic;

    &:focus {
      text-decoration: none;
    }

    &:hover {
      transform: translateY(-10px);
      border: 1px solid brand.$green;
      box-shadow: 0 0 4px brand.$green;
      text-decoration: none;

      &.ds-battery-table__item--recommended {
        border: 3px solid brand.$green;
      }
    }

    &--recommended {
      border: 3px solid brand.$green;
    }

    &-image {
      padding: global.vr(2) global.vr(3);
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      img {
        max-width: 130px;
        max-height: 130px;
      }
    }

    &-feature-container {
      padding: global.vr(2) 2px;
      width: 100%;

      .ds-battery-table__item--recommended & {
        background-color: brand.$green;
      }
    }

    &-feature {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-title {
      @include fonts.lato-regular();
      font-size: 12px;
      color: brand.$grey-20;
      padding-bottom: global.vr(1);
    }

    &-value {
      @include fonts.lato-semi-bold();
      font-size: 29px;
      color: brand.$grey-10;

      &--subtext {
        font-size: 16px;
      }
    }
  }

  &__link {
    @include fonts.lato-bold();
    font-size: 14px;
    color: brand.$grey-10;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: global.vr(2);

    &:after {
      content: '';
      margin-left: global.vr(1);
      width: 15px;
      height: 15px;
      background-image: url('~@img/icons/icon-chevron-right-green.svg');
      background-size: contain;
      background-repeat: no-repeat;

      .ds-battery-table__item--recommended & {
        background-image: url('~@img/icons/icon-chevron-right-grey.svg');
      }
    }
  }

  &__footer-text {
    @include fonts.lato-regular();
    font-size: 14px;
    color: brand.$grey-20;
    p {
      margin-bottom: 0;
    }
  }
}
