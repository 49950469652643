@use '../settings/type';

@mixin object-fit($fit: cover, $position: center center) {
  object-fit: $fit;
  object-position: $position;
  font-family: 'object-fit: #{$fit}; object-position: #{$position};';
}

/// Returns the opposite direction of each direction in a list
/// @author Hugo Giraudel
/// @param {List} $directions - List of initial directions
/// @return {List} - List of opposite directions
@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top': 'bottom',
    'right': 'left',
    'bottom': 'top',
    'left': 'right',
    'center': 'center',
    'ltr': 'rtl',
    'rtl': 'ltr',
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append(
        $opposite-directions,
        unquote(map-get($direction-map, $direction))
      );
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}

@mixin sr-only() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin title-block() {
  display: flex;
  flex-direction: column-reverse;
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $width [1em] - Triangle width
/// @param {Length} $height [1em] - Triangle height
@mixin triangle($direction, $color: currentcolor, $width: 1em, $height: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-#{opposite-direction($direction)}: $width solid $color;

  $perpendicular-borders: $height solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

@function vertical-rhythm($amount) {
  @return type.$base-rhythm * $amount;
}

@function vr($amount) {
  @return vertical-rhythm($amount);
}
