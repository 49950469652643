@use 'sass:color';

// Incremental greyscale - all greys in designs should be adjusted to fit this scale.
$white: #fff;
$grey-95: hsl(0, 0, 95);
$grey-90: hsl(0, 0, 90);
$grey-85: hsl(0, 0, 85);
$grey-80: hsl(0, 0, 80);
$grey-75: hsl(0, 0, 75);
$grey-70: hsl(0, 0, 70);
$grey-65: hsl(0, 0, 65);
$grey-60: hsl(0, 0, 60);
$grey-55: hsl(0, 0, 55);
$grey-50: hsl(0, 0, 50);
$grey-45: hsl(0, 0, 45);
$grey-40: hsl(0, 0, 40);
$grey-35: hsl(0, 0, 35);
$grey-30: hsl(0, 0, 30);
$grey-25: hsl(0, 0, 25);
$grey-20: hsl(0, 0, 20);
$grey-15: hsl(0, 0, 15);
$grey-10: hsl(0, 0, 10);
$grey-05: hsl(0, 0, 5);
$black: #000;

$blue-grey: rgb(89, 91, 96);

$grey-max-power: #313131;
$grey-dark: #595b60;

// The current "standard" green.
$green: #cedc00;
$brand-primary: #cedc00;

// @todo: Ideally need to figure out which greens are needed and which aren't.
$brand-d3e002: #d3e002;
$brand-c7d31b: #c7d31b;
$brand-cddb00: #cddb00;
$brand-cadb36: #cadb36;

$brand-cadb36: hsl(66, 70%, 54%);
$brand-c7d31b: hsl(64, 77%, 47%);
$brand-d3e002: hsl(64, 98%, 44%);
$brand-cddb00: hsl(64, 100%, 43%);

$error: #e01b14;

/** Standard shadow colour and alpha. */
$shadow: color.change($black, $alpha: 0.16);

/** Box Shadow */
$bs: $shadow 0 3px 6px;
$bs-expanded: $shadow 0 6px 12px;

@mixin box-shadow($expand: false) {
  @if ($expand) {
    box-shadow: $bs-expanded;
  } @else {
    box-shadow: $bs;
  }
}

@mixin bs($expand: false) {
  @include box-shadow($expand);
}

/** Text Shadow */
$ts: $shadow 0 3px 6px;
$ts-expanded: $shadow 0 6px 12px;

@mixin text-shadow($expand: false) {
  @if ($expand) {
    text-shadow: $bs-expanded;
  } @else {
    text-shadow: $bs;
  }
}

@mixin ts($expand: false) {
  @include text-shadow($expand);
}
$error: #e01b14;
