@use '~sass-mq/mq' as *;
@use './settings/animation';
@use './settings/brand';
@use './settings/layers';
@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

.skewed-content-split {
  padding: global.vr(9) 0;

  @include mq(bp-md) {
    padding: global.vr(9) 0 global.vr(13);
  }

  &__container {
    @include mq(bp-md) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__left {
    position: relative;
    z-index: layers.$layer-default;
    max-width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -#{global.vr(10)};
    opacity: 0;
    transform: translate(0, -40px);
    transition: opacity 300ms animation.$ease-in-out-cubic 150ms,
      transform 300ms animation.$ease-in-out-cubic 150ms;
    will-change: transform;

    @include mq(bp-xs) {
      max-width: 630px;
    }

    @include mq(bp-md) {
      max-width: 700px;
      width: calc(46% + #{global.vr(15)});
      flex-basis: calc(46% + #{global.vr(15)});
      margin-right: -#{global.vr(15)};
      margin-bottom: 0;
      transform: translate(-40px, 0);
    }

    .skewed-content-split.animated & {
      opacity: 1;
      transform: translate(0);
    }
  }

  &__right {
    opacity: 0;
    transform: translate(0, 40px);
    transition: opacity 300ms animation.$ease-in-out-cubic,
      transform 300ms animation.$ease-in-out-cubic;

    @include mq(bp-md) {
      width: 54%;
      flex-basis: 54%;
      padding-right: global.vr(4);
      transform: translate(40px, 0);
    }

    .skewed-content-split.animated & {
      opacity: 1;
      transform: translate(0);
    }
  }

  &__outer {
    position: relative;
    overflow: hidden;
    background: brand.$green;
    padding: global.vr(13) global.vr(4) global.vr(4);

    @include mq(bp-xxs) {
      padding: global.vr(13) global.vr(7) global.vr(7);
    }

    @include mq(bp-md) {
      min-height: 454px;
      transform: skewX(-10deg);
      padding: global.vr(7) global.vr(13) global.vr(10.5) global.vr(23);
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: calc(100% - 140px);
      left: -68%;
      width: 130%;
      height: 200px;
      transform: skewX(79deg);
      background: rgba(187, 190, 17, 0.5);

      @include mq(bp-xs) {
        top: calc(100% - 200px);
      }
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: calc(100% - 120px);
      right: -64%;
      width: 132%;
      height: 160px;
      transform: skewX(-82deg);
      background: #c7cc13;

      @include mq(bp-xs) {
        top: calc(100% - 160px);
      }
    }
  }

  &__inner {
    position: relative;
    z-index: layers.$layer-default;
    max-width: 450px;

    @include mq(bp-md) {
      transform: skewX(10deg);
    }

    small {
      display: block;
      font-size: 12px;
      color: brand.$grey-30;
      margin-bottom: global.vr(1);
    }

    p {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: global.vr(3.5);
    }
  }

  &__title {
    font-size: 30px;
    @include fonts.lato-bold;
    margin-bottom: global.vr(2.5);
  }

  &__cta {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    letter-spacing: 1px;
    color: brand.$grey-20;
    background: brand.$white;
    padding: global.vr(1.25) global.vr(1.75);
    margin-bottom: global.vr(1.25);
    transform: translate3d(0, 0, 0);
    transition: background-color 150ms ease, transform 150ms ease, box-shadow 150ms ease;
    will-change: transform;

    &:hover,
    &:focus {
      box-shadow: rgba(0, 0, 0, 0.16) 0 6px 12px;
      text-decoration: none;
      -webkit-transform: translate(0, -2px);
      -ms-transform: translate(0, -2px);
      transform: translate(0, -2px);
      background: brand.$grey-95;
    }

    img {
      display: inline-flex;
      align-self: center;
      margin-right: global.vr(2.25);
      max-height: 30px;
    }
  }

  .tns-outer {
    @include mq($until: bp-md) {
      display: flex;
      flex-direction: column;
    }
  }

  .tns-inner {
    margin-right: 0 !important;
  }

  .tns-controls {
    position: absolute;
    bottom: -70px;
    left: 0;

    @include mq($until: bp-md) {
      position: relative;
      bottom: 0;
      margin-top: 20px;
      margin-bottom: -80px;
      padding-bottom: 70px;
      order: 2;
    }

    button {
      position: relative;
      display: inline-flex;
      align-self: center;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background: brand.$white;
      text-indent: -9999px;
      font-size: 0;
      color: rgba(255, 255, 255, 0);
      margin-right: global.vr(3.4);
      box-shadow: 3px 3px 6px rgba(brand.$black, 0.16);
      transition: background-color 150ms ease;

      @include mq(bp-xxs) {
        height: 58px;
        width: 58px;
        margin-right: global.vr(3.8);
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('~@img/icons/icon-arrow-right.svg');
        background-position: center;
        background-repeat: no-repeat;
      }

      &:nth-child(1):before {
        transform: rotateZ(180deg);
      }

      &:hover {
        background-color: brand.$green;
      }
    }
  }

  &__item {
    display: inline-flex;
    align-self: center;
    opacity: 0.5;
    transform: scaleY(0.8);
    transition: opacity 400ms ease, transform 400ms ease;
    will-change: transform;

    &--media a {
      position: relative;
      display: block;

      &:before {
        content: '';
        position: absolute;
        z-index: layers.$layer-default;
        top: 50%;
        left: 50%;
        width: 78px;
        height: 78px;
        transform: translate(-50%, -50%);
        background: url('~@img/icons/icon-play-red.svg') no-repeat center;
        will-change: transform;
        transform-origin: center;
        transition: transform 300ms ease;
      }

      &:hover:before {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }

    &--active {
      opacity: 1;
      transform: scaleY(1);
    }

    img {
      display: block;
      max-height: 400px;
    }
  }
}
