@use '../helpers/fonts';
@use '../helpers/global';
@use '../settings/brand';
@use './settings/animation';
@use './helpers/animations';
@use "../settings/breakpoints" as *;

@keyframes animate-leading-line {
  0% {
    transform: translate(-50%, 50%) scaleY(0);
    transform-origin: 0 0;
  }

  33% {
    transform: translate(-50%, 50%) scaleY(1);
    transform-origin: 0 0;
  }

  40% {
    transform: translate(-50%, 50%) scaleY(1);
    transform-origin: 0 0;
  }

  40.01% {
    transform-origin: 100% 100%;
  }

  60% {
    transform-origin: 100% 100%;
    transform: translate(-50%, 50%) scaleY(0);
  }
}

.scrub {
  .tns-ovh {
    margin: 0 50px;
  }

  [data-controls] {
    display: inline-flex;
    align-items: center;
    background: brand.$white;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    height: 40px;
    justify-content: center;
    transition: box-shadow 0.3s animation.$ease-in-out-cubic,
      background-color 0.3s animation.$ease-in-out-cubic,
      transform 0.3s animation.$ease-in-out-cubic;

    width: 40px;
    position: absolute;
    top: 65px;
    transform: translateY(0);

    &:focus,
    &:hover {
      background: brand.$white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      text-decoration: none;
      transform: translateY(2px);
    }

    svg {
      display: block;
      height: 12px;
      width: 16px;
    }

    @include mq(bp-sm) {
      height: 50px;
      width: 50px;

      svg {
        display: block;
        height: 15px;
        width: 20px;
      }
    }
  }

  [data-controls='prev'] {
    left: 3px;
    svg {
      transform: rotate(180deg);
    }
  }

  [data-controls='next'] {
    right: 3px;
    margin-left: global.vr(2);
  }

  &__inner {
    width: 100%;
  }

  &__header-container {
    display: flex;
    justify-content: center;
    background-color: brand.$white;
    padding: 40px 100px 0;

    @include mq($until: bp-xs) {
      padding: 40px 24px 0;
    }
  }

  &__header {
    @include animations.fade-up(200ms, parent);
    max-width: 840px;
    text-align: center;
    position: relative;
    z-index: 1;
    padding-bottom: 40px;
  }

  &__leading-line {
    position: absolute;
    height: 50px;
    width: 2px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) scaleY(0);
    z-index: 5;
    background-color: brand.$grey-05;
    animation: animate-leading-line 2s infinite;
  }

  &__title {
    @include fonts.lato-semi-bold();
    @include fonts.ls(-10);
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;

    @include mq($until: bp-xs) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__intro {
    font-size: 14px;
    line-height: 24px;
  }

  &__tabs-container {
    background-color: brand.$brand-primary;
    display: flex;
    justify-content: center;
  }

  &__tabs {
    @include animations.fade-up(400ms, parent);
    padding: 25px 0 0;
    width: 100%;
    max-width: 1260px;
    position: relative;
  }

  &__tabs-carousel {
    display: flex;
    justify-content: center;

    @include mq(bp-md) {
      // Need important to overwrite tinyslider width on large desktop only.
      width: 100% !important;
    }
  }

  &__tab-item {
    text-align: center;
    cursor: pointer;
    position: relative;
    padding-bottom: 35px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 0;
      width: 0;
      pointer-events: none;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;

      border-bottom: 24px solid brand.$white;
      margin-left: -12px;
      transform: translateY(30px);
      transition: transform 0.3s;
    }

    &.is-active {
      &:after {
        transform: translateY(0);
      }
    }
  }

  &__tab-media-holder {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: brand.$white;
    border: 2px solid brand.$grey-65;
    margin: 0 auto 20px;

    img {
      width: 100%;
    }

    .is-active & {
      border-color: brand.$brand-primary;
    }
  }

  &__tab-content {
    font-size: 20px;
    line-height: 28px;

    @include mq($until: bp-xs) {
      font-size: 14px;
      line-height: 24px;
    }

    .is-active & {
      @include fonts.lato-bold();
    }
  }

  &__content-container {
    background-color: brand.$white;
    display: flex;
    justify-content: center;
  }

  &__content-holder {
    @include animations.fade-up(600ms, parent);
    max-width: 840px;
  }

  &__content {
    display: none;

    &.is-active {
      display: block;
    }

    &-inner {
      display: flex;
      flex-wrap: wrap;
      padding: 35px 15px 15px;
    }

    &-card {
      flex: 0 1 12.5%;
      margin-bottom: 20px;
      padding: 0 15px;

      @include mq($until: bp-sm) {
        flex: 0 1 16.66667%;
      }

      @include mq($until: bp-xs) {
        flex: 0 1 25%;
      }

      @include mq($until: bp-xxs) {
        flex: 0 1 33.3333333%;
      }

      &-inner {
        max-width: 78px;
        margin-left: auto;
        margin-right: auto;
      }

      &-media {
        margin-bottom: 10px;
      }

      &-text {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &-base {
      background-color: brand.$white;
      padding: 5px 10px;
      text-align: center;
    }

    &-base-text {
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }
  }
}
