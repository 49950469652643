@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-card-fdr {
  display: block;
  cursor: pointer;
  margin-bottom: global.vr(4);
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &__media {
    @include animations.image-fade($mode: parent);

    img {
      max-width: none;
      width: 100%;
    }
  }

  &__title {
    display: block;
    color: brand.$grey-20;
    @include fonts.lato-bold();
    font-size: 16px;
    @include fonts.ls(25);
    line-height: 1em;
    margin-bottom: global.vr(1);
  }

  &__meta {
    display: block;
    color: brand.$grey-60;
    @include fonts.lato-bold();
    font-size: 14px;
    @include fonts.ls(25);
    line-height: 1em;
    margin-bottom: global.vr(2);
  }

  &__link {
    display: flex;
    align-items: center;
    color: brand.$grey-20;
    flex-direction: row;
    @include fonts.lato-bold();
    font-size: 14px;
    line-height: 1em;
    text-transform: uppercase;

    svg {
      display: inline-block;
      color: brand.$green;
      fill: brand.$green;
      height: 12px;
      margin-left: global.vr(1);
      width: 6px;
    }
  }
}
