@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './settings/layers';
@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

.fs-features {
  padding: global.vr(6) 0 global.vr(2);

  @include mq($from: bp-sm) {
    padding: global.vr(9) 0;
  }

  h2 {
    text-align: center;
    margin-bottom: global.vr(4.2);
  }

  &__row {
    display: flex;
    flex-direction: center;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin: 0 -#{global.vr(2)};
  }

  &__item {
    width: 100%;
    text-align: center;
    margin-bottom: global.vr(5);

    @include mq($from: bp-xxs) {
      width: 50%;
      flex-basis: 50%;

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          @include animations.fade-up($i * 100ms, self);
        }
      }
    }

    @include mq($from: bp-sm) {
      width: 20%;
      flex-basis: 20%;
      margin-bottom: 0;
    }

    &-inner {
      padding: 0 global.vr(2);
    }

    h3 {
      @include fonts.lato-semi-bold;
      font-size: 18px;
      line-height: 29px;
      margin-bottom: global.vr(2);
    }

    p {
      font-size: 14px;
      line-height: 22px;
      max-width: 260px;
      margin: 0 auto;
    }
  }

  &__image {
    display: block;
    box-shadow: 0 0 2px brand.$grey-90;
    margin-bottom: global.vr(3.2);
  }
}
