@use './settings/breakpoints' as *;
@use './settings/brand' as brand;
@use './helpers/fonts';
@use './helpers/global';

.ds-product-activity {
  background-color: brand.$grey-20;
  padding: global.vr(3) 0;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__heading {
    font-size: 24px;
    color: brand.$white;
    margin-bottom: global.vr(3);
  }

  &__activities {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 global.vr(4);

    &.active, &:hover {
      .ds-product-activity__item-icon {
        fill: brand.$green;
        stroke: brand.$green;
      }

      .ds-product-activity__item-title {
        color: brand.$green;
      }
    }

    &-icon {
      fill: brand.$white;
      stroke: brand.$white;

      svg {
        width: 85px;
        height: 85px;
      }
    }

    &-title {
      @include fonts.lato-semi-bold();
      font-size: 14px;
      color: brand.$white;
    }
  }
}
