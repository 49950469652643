@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

.ds-instruments-gallery {
  padding: global.vr(8) 0;

  &--dark {
    background: brand.$grey-35;
  }

  &--white {
    background: brand.$white;
  }

  &--green {
    background: brand.$brand-cddb00;
  }

  &__split {
    overflow: hidden;

    @include mq(bp-sm) {
      display: flex;
      padding: 0 global.vr(8);
    }

    &-left {
      @include animations.fade-up($mode: self);

      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-basis: 500px;
      order: 1;

      .tns-outer {
        position: relative;
      }

      .tns-controls {
        position: absolute;
        top: calc(100% + 14px);
        left: 0;

        button {
          display: inline-flex;
          align-self: center;
          width: 38px;
          height: 38px;
          background-color: brand.$brand-cddb00;
          background: url('~@img/icons/icon-arrow.svg') no-repeat center center;
          background-size: 18px 18px;
          border-radius: 50%;
          text-indent: -9999px;
          margin-right: global.vr(1);
          transition: background-color 300ms ease;

          &:first-child {
            transform: rotate(-180deg);
          }

          &:hover {
            background-color: darken(brand.$brand-cddb00, 5%);
          }

          .ds-instruments-gallery--dark &,
          .ds-instruments-gallery--green & {
            background-color: brand.$white;

            &:hover {
              background-color: brand.$grey-90;
            }
          }

          &[disabled] {
            cursor: not-allowed;
            opacity: 0.25;
          }
        }
      }
    }

    &-right {
      @include animations.fade-up($delay: 150ms, $mode: self);

      flex: 1;
      order: 2;

      @include mq($until: bp-sm) {
        margin-bottom: global.vr(6);
      }

      .tns-controls {
        display: none;
      }

      .tns-inner {
        margin-left: 0 !important;

        @include mq($until: bp-md) {
          margin-right: 40px !important;
        }
      }
    }
  }

  &__text {
    padding-bottom: global.vr(8);

    @include mq(bp-sm) {
      padding-bottom: 0;
    }

    strong {
      display: block;
      font-size: 30px;
      @include fonts.lato-bold();
      color: brand.$brand-cddb00;
      margin-bottom: global.vr(1.75);

      .ds-instruments-gallery--green & {
        color: brand.$grey-max-power;
      }
    }

    p {
      font-size: 16px;
      @include fonts.lato-bold();
      color: brand.$grey-max-power;

      .ds-instruments-gallery--dark & {
        color: brand.$white;
      }

      .ds-instruments-gallery--green & {
        color: brand.$grey-max-power;
      }
    }
  }

  &__images {
    .ds-instruments-gallery__item {
      display: inline-flex;
      align-self: center;
      opacity: 0.5;
      transform: scaleY(0.8);
      transition: opacity 400ms ease, transform 400ms ease;
      will-change: transform;

      &.skewed-content-split__item--active {
        opacity: 1;
        -webkit-transform: scaleY(1);
        -ms-transform: scaleY(1);
        transform: scaleY(1);
      }
    }
  }
}
