@use "../settings/brand";
@use "../helpers/fonts";
@use "../helpers/global";
@use '../settings/animation';
@use "../settings/breakpoints" as *;
@use '../helpers/mixins';

.ds-accessory-finder {
  position: relative;

  &__bg-outer {
    display: flex;
    height: 100%;
    width: 100%;

    .ds-accessory-finder--results & {
      height: 364px;
    }
  }

  &__bg-inner {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: #000000;
      opacity: 0.52;
      z-index: 1;

      @include mq(bp-sm) {
        background: linear-gradient(135deg, rgba(0, 0, 0, 1) 20%, rgba(117, 117, 117, 0) 70%);
      }
    }
  }

  &__img {
    width: 100%;
    display: block;
    position: relative;
    z-index: -1;

    @include mq($until: bp-xs) {
      display: none;
    }
  }

  &__mobile-img {
    width: 100%;
    display: block;
    position: relative;
    z-index: -1;

    @include mq(bp-xs) {
      display: none;
    }
  }

  &__bg {
    display: block;
    width: 100%;
    min-height: calc(100vh - 70px);
    font-family: 'object-fit: cover; object-position: center right;';
    object-fit: cover;
    object-position: bottom right;
    z-index: -1;

    @include mq(bp-xs) {
      position: absolute;
      display: none;
      min-height: calc(100vh - 70px);
    }

    .ds-accessory-finder--results & {
      transform: translateY(-40%);
    }
  }

  &__search-outer {
    position: absolute;
    top: 75%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: 2;

    @include mq(bp-sm) {
      left: 117px;
      top: 50%;
      align-items: center;
      justify-content: flex-start;
      transform: translate(0, -50%);
    }

    .ds-accessory-finder--results & {
      top: 364px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__suggestions {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 60px;
    width: 100%;
  }

  &__suggestion {
    width: 100%;
    background: #ffffff;
    z-index: 1;
    text-align: left;
    padding-left: 80px;
    border-radius: 0;
    border-bottom: 1px solid #cddb00;
    border-left: 1px solid #cddb00;
    border-right: 1px solid #cddb00;

    &:first-of-type {
      border-top: 1px solid #cddb00;
    }

    &:hover {
      background-color: #cddb00;
    }
  }

  &__search-inner {
    display: flex;
    flex-direction: column;

    button {
      margin: 0 auto;
    }

    @include mq(bp-sm) {
      button {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  &__search-input-wrapper {
    position: relative;
    margin: 0 0 global.vr(4);

    @include mq(bp-sm) {
      margin: 0 0 global.vr(2);
    }

    input {
      @include fonts.lato-regular();
      background: #fff;
      font-size: 23px;
      border: 0;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      color: brand.$grey-20;
      height: auto;
      line-height: 28px;
      min-width: 332px;
      padding: global.vr(2) global.vr(2) global.vr(2) global.vr(10);
      width: 100%;

      &::placeholder {
        color: brand.$grey-20;
      }

      &:active,
      &:focus {
        border: 0 !important;
      }

      @include mq(bp-sm) {
        width: 630px;
        width: 47ch;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      color: #cddb00;
      height: 22px;
      margin-left: global.vr(3);
      transform: translateY(-50%);
      width: 22px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: #cddb00;
      transform: scale(0, 1);
      transform-origin: left center;
      transition: transform 0.3s animation.$ease-in-out-cubic;
    }

    &:focus-within {
      &::after {
        transform: scale(1, 1);
      }
    }
  }

  &__categories {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    border-left: 2px solid brand.$white;
    cursor: default;
    height: calc(100% - #{global.vr(4)});
    margin: global.vr(2) 0 global.vr(2) global.vr(20);
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 1;
    overflow: hidden;
    pointer-events: none;
    z-index: 1;

    @include mq(bp-sm) {
      display: block;
    }

    .ds-accessory-finder--results & {
      opacity: 0;
    }
  }

  &__categories-inner {
    display: flex;
    animation: marquee-vertical 30s infinite linear;
    flex-direction: column;
    transform: translateY(0);

    span {
      display: inline-block;
      color: brand.$white;
      margin-bottom: global.vr(5);
      margin-left: global.vr(2);
    }
  }

  &__title-outer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    text-align: center;
    transform: translateY(#{global.vr(2)});

    &--gap-top {
      top: 50px;
    }

    @include mq(bp-sm) {
      text-align: left;
    }

    .ds-accessory-finder--results & {
      transform: translateY(#{global.vr(8)});
    }
  }

  &__title {
    @include fonts.lato-bold();
    position: relative;
    display: inline-block;
    color: brand.$white;
    font-size: 34px;
    line-height: 28px;
    margin: 0;
    padding: 0;
    text-transform: none;
    z-index: 2;
    text-align: center;

    .ds-accessory-finder--landing & {
      max-width: 800px;
      padding-bottom: 20px;
    }

    em {
      display: inline-block;
      font-family: 'Wreath Halftone';
      font-size: 66px;
      font-style: normal;
      line-height: 113px;
      margin-right: global.vr(1);
    }

    .RURU &,
    .HUHU &,
    .PLPL &,
    .LTLT &,
    .LVLV &,
    .CSCZ & {
      em {
        font-size: 56px;
        font-family: 'Segoe Script';
      }
    }

    &--ru-RU,
    &--hu-HU,
    &--pl-PL,
    &--lt-LT,
    &--lv-LV,
    &--cs-CZ {
      em {
        font-size: 56px;
        font-family: 'Segoe Script';
      }
    }

    @include mq(bp-sm) {
      font-size: 49px;

      em {
        font-size: 85px;
      }

      &--ru-RU,
      &--hu-HU,
      &--pl-PL,
      &--lt-LT,
      &--lv-LV,
      &--cs-CZ,
      .RURU &,
      .HUHU &,
      .PLPL &,
      .LTLT &,
      .LVLV &,
      .CSCZ & {
        em {
          font-size: 70px;
        }
      }
    }

    .ds-accessory-finder--results & {
      transform: scale(0.9);
    }
  }

  &__subtitle {
    max-width: 500px;
    @include fonts.lato-regular();
    font-size: 17px;
    color: brand.$white;
    padding-bottom: global.vr(4);
    text-align: center;
  }

  &__results {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 1px;
    padding-top: 38px + global.vr(2);
    padding-right: global.vr(4);
    padding-left: global.vr(4);
    padding-bottom: 38px + global.vr(2);
  }

  .fade-up-enter {
    opacity: 0;
    transform: translateY(20px);
  }

  .fade-up-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.6s animation.$ease-in-out-cubic,
      transform 0.6s animation.$ease-in-out-cubic;
  }

  .fade-up-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .fade-up-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s animation.$ease-in-out-cubic,
      transform 0.6s animation.$ease-in-out-cubic;
  }

  &__culture-selector {
    .ds-accessory-finder__culture-container {
      .ds-accessory-finder__title-outer {
        position: absolute;
        justify-content: start;
        padding-bottom: global.vr(4);

        @include mq($until: bp-sm) {
          position: relative;

          &--gap-top {
            padding-bottom: 50px;
          }
        }

        .ds-accessory-finder__title {
          position: absolute;
          left: 25%;
          transform: translateX(-25%);

          @include mq($until: bp-sm) {
            position: relative;
            left: 0;
            transform: translateX(0);
          }
        }
      }
    }

    &--centre {
      .ds-accessory-finder__culture-container {
        .ds-accessory-finder__title-outer {
          .ds-accessory-finder__title {
            left: 50%;
            transform: translateX(-50%);

            @include mq($until: bp-sm) {
              left: 0;
              transform: translateX(0);
            }
          }
        }
      }
      .ds-accessory-finder__culture-inner {
        left: 50%;
        transform: translateX(-50%);

        @include mq($until: bp-sm) {
          left: 0;
          transform: translate(0);
        }
      }
    }
  }

  &__culture-container {
    width: 100%;

    @include mq($until: bp-sm) {
      position: absolute;
      top: 0;
    }
  }

  &__culture-inner {
    max-width: 550px;
    min-width: 280px;
    position: absolute;
    top: 25%;
    left: 25%;
    transform: translateX(-25%);

    @include mq($until: bp-sm) {
      max-width: 100%;
      position: relative;
      top: initial;
      text-align: center;
      left: 0;
      transform: translate(0);
    }
  }

  &__culture-subtitle {
    @include fonts.lato-regular();
    font-size: 17px;
    color: brand.$white;
    padding-bottom: global.vr(4);

    @include mq($until: bp-xs) {
      padding-bottom: global.vr(2);
    }
  }

  &__culture-region {
    @include fonts.lato-bold();
    font-size: 24px;
    color: brand.$white;
    padding-bottom: global.vr(4);

    @include mq($until: bp-sm) {
      display: none;
    }
  }

  &__culture-item-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 550px;

    @include mq($until: bp-sm) {
      display: none;
    }
  }

  &__culture-item {
    flex: 0 0 33%;
    display: flex;
    align-items: center;
    padding-bottom: global.vr(3);
    cursor: pointer;

    &:hover {
      text-decoration: none;
      .ds-accessory-finder__culture-item-name {
        color: brand.$green;
        text-decoration: none;
      }
    }
  }

  &__culture-item-name {
    @include fonts.lato-regular();
    font-size: 17px;
    color: brand.$white;
    margin-left: global.vr(2);
  }

  &__culture-item-image {
    max-width: 28px;
  }

  &__mobile-dropdown {
    display: none;
    min-width: 250px;
    border: none;
    outline: none;
    position: relative;
    padding: 15px 20px;
    cursor: pointer;
    background-color: brand.$white;
    text-align: left;

    span {
      @include fonts.lato-bold();
      font-size: 14px;
      color: brand.$grey-max-power;
    }

    @include mq($until: bp-sm) {
      display: inline-block;
    }

    &.is-active {
      ul {
        display: block;
      }

      &:after {
        transform: translate(50%, -50%) rotate(-180deg);
      }
    }

    ul {
      display: none;
      position: absolute;
      z-index: 1;
      background: brand.$white;
      width: 100%;
      left: 0px;
      margin: 0;
      list-style: none;
      padding: 0;
      max-height: 200px;
      overflow-y: auto;

      @include mq($until: bp-xxs) {
        max-height: 160px;
      }
    }

    li {
      display: flex;
      align-items: center;
      padding: 0 20px;
      @include fonts.lato-bold();
      font-size: 14px;
      color: brand.$grey-max-power;

      img {
        padding-right: 10px;
        max-width: 50px;
      }
    }

    &:after {
      content: '\f078';
      font-size: 18px;
      position: absolute;
      font-family: 'FontAwesome';
      top: 30px;
      right: 20px;
      transform: translate(50%, -50%);
      color: brand.$grey-max-power;
    }
  }
}

.ds-hidden {
  display: none !important;
  visibility: hidden;

  &.show {
    display: block !important;
    visibility: visible !important;
  }

  .EditMode & {
    display: block !important;
    visibility: visible !important;
  }
}

.ds-modal {
  &.open {
    opacity: 1;
    height: 100%;
    padding: 125px 20px;
    z-index: 11;

    .ds-accessory-modal__container {
      opacity: 1;
    }
  }
}

.ds-accessory-modal {
  &__container {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1050px;
    width: 100%;
    max-height: 100vh;
    max-height: calc(100vh - 40px);
    height: auto;
    padding: 0 20px;
    background: brand.$white;
    border: 10px solid brand.$green;
    overflow-y: auto;
    opacity: 0;
    transition: opacity 300ms ease 150ms;
    @include mixins.custom-scrollbar(transparent, brand.$white, brand.$grey-70);
  }

  &__inner {
    position: relative;
    padding: 44px 0 38px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    width: 17px;
    height: 17px;
    background-image: url('~@img/icons/icon-cross--black.svg');
  }

  &__title {
    @include fonts.lato-bold();
    font-size: 30px;
    color: brand.$grey-max-power;
    padding-bottom: global.vr(2);
    text-align: center;
  }

  &__content,
  &__subtitle,
  &__footer {
    @include fonts.lato-semi-bold();
    font-size: 17px;
    color: brand.$grey-max-power;
    padding-bottom: global.vr(1);
    text-align: center;
    max-width: 700px;

    a {
      text-decoration: underline;
    }
  }

  &__product-container {
    display: flex;
    flex-direction: column;
    padding-bottom: global.vr(2);
    width: 100%;

    @include mq(bp-xs) {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__product {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 global.vr(1) global.vr(2);

    @include mq(bp-xs) {
      padding: 0 global.vr(1);
    }
  }

  &__product-title {
    @include fonts.lato-bold();
    font-size: 18px;
    color: brand.$grey-max-power;
  }

  &__product-image {
    width: 100%;
    max-width: 240px;

    @include mq($until: bp-xs) {
      max-width: 300px;
    }
  }
}

.ds-message {
  text-align: center;

  @include mq(bp-sm) {
    text-align: inherit;
  }
}

@keyframes marquee-vertical {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}
