@use "./settings/brand";
@use "./helpers/fonts";
@use "./helpers/global";
@use './settings/animation';
@use "./settings/breakpoints" as *;
@use './helpers/mixins';

.wn-banner {
  $skew: 11deg;

  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;

  h1 {
    font-weight: 39px;
    font-weight: bold;
    margin-bottom: 25px;
    text-transform: none;
    color: brand.$grey-max-power;
    padding-bottom: 0px;

    @include mq($from: bp-sm) {
      font-size: 48px;
    }
  }

  h2 {
    font-size: 24px;
    @include fonts.lato-thin;
    font-weight: normal;
    margin-bottom: 0px;
    text-transform: uppercase;
    letter-spacing: 6px;
    line-height: 1.4;
    color: brand.$grey-max-power;

    @include mq($from: bp-sm) {
      font-size: 32px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    background-color: brand.$brand-primary;

    @include mq($from: bp-sm) {
      flex-wrap: nowrap;
      height: 600px;
    }
  }

  &__content {
    width: 100%;
    padding: 25px 20px 25px 20px;
    position: relative;
    z-index: 1;
    background-color: brand.$brand-primary;

    @include mq($from: bp-xs) {
      padding: 35px 20px 35px 20px;
    }

    @include mq($from: bp-sm) {
      width: 50%;
      transform: skewX(-$skew);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include mq($from: bp-md) {
      padding: 75px 20px 95px 20px;
    }

    // &:before,
    // &:after {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    // }

    // &:before {
    //   transform: skew(-8deg);
    //   background-color: #c0d036;
    //   clip-path: polygon(0 0, 100% 69%, 100% 100%, 0% 100%);
    //   height: 190px;
    //   z-index: 0;

    //   @include mq($from: bp-sm) {
    //     height: 190px;
    //   }
    // }

    // &:after {
    //   background-color: #b4c432;
    //   clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    //   height: 80px;
    //   z-index: 1;

    //   @include mq($from: bp-sm) {
    //     height: 160px;
    //   }
    // }

    &-copy {
      max-width: 370px;
      margin: 0 auto;
      position: relative;
      z-index: 2;

      @include mq($from: bp-sm) {
        transform: skewX($skew);
      }
    }

    &-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 90px;
      z-index: 0;
      background-image: url('~@img/whats-new/shards.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;

      @include mq($from: bp-sm) {
        height: 190px;
      }
    }
  }

  &__tag {
    position: absolute;
    z-index: 3;
    // using 99 rather than 100 just to avoid any clipping issues
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 10px 7px 20px;
    background-color: brand.$brand-primary;

    @include mq($from: bp-sm) {
      left: 99%;
    }

    &--mobile {
      display: block;

      @include mq($from: bp-sm) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include mq($from: bp-sm) {
        display: block;
      }
    }

    .layout--top & {
      top: 40px;
    }

    .layout--bottom & {
      top: auto;
      bottom: 40px;
      transform: translateY(50%);
    }

    &-inner {
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1;

      @include mq($from: bp-sm) {
        font-size: 40px;
        transform: skew($skew);
      }
    }
  }

  &__media {
    width: 100%;
    background: #fff;
    z-index: 0;
    position: relative;

    img {
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: center;';
      width: 100%;
      height: 100%;
    }

    @include mq($from: bp-sm) {
      width: 57%;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
  }
}
