@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use './settings/brand';
@use './settings/breakpoints' as *;

.ds-form-container {
  padding: global.vr(8) global.vr(0);

  &--green {
    background-color: brand.$green;

    .ds-form__field {
      .ds-form__control {
        border: 1px solid brand.$black;
      }
      .ds-form__select-container {
        select {
          background-color: brand.$white;
        }
      }

      input[type='radio'] {
        + label {
          &:before {
            // important needed to fix issue with radio buttons else where
            border: 1px solid brand.$black !important;
            background-color: brand.$white !important;
          }
        }

        &:checked + label:before {
          // important needed to fix issue with radio buttons else where
          background-color: brand.$black !important;
        }
      }

      input[type='checkbox'] {
        + label {
          &:before {
            border: 1px solid brand.$black;
            background-color: brand.$white;
          }
        }

        &:checked + label:before {
          background-color: brand.$black;
        }
      }
    }
  }

  &__split {
    display: flex;
    // opacity: 0;

    // .animated & {
    //   @include animations.fade-up($mode: parent, $delay: 0.2s);
    // }

    .EditMode &,
    .DesignMode & {
      opacity: 1;
    }

    &--horizontal {
      flex-direction: column;
      align-items: stretch;
      flex-grow: 1;

      @include mq(bp-sm) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &--vertical {
      flex-direction: column;
      align-items: stretch;
      flex-grow: 1;
    }
  }

  &__half {
    width: 100%;

    .ds-form-container__split--horizontal & {
      @include mq(bp-sm) {
        width: 50%;
        flex-basis: 50%;
      }

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;

        @include mq(bp-sm) {
          position: sticky;
          top: 25px;
        }

        @include mq(bp-lg) {
          top: 50px;
        }
      }
    }

    &:nth-child(1) {
      .ds-form-container__split--horizontal & {
        order: 2;
      }

      .ds-form-container__split--left & {
        @include mq(bp-sm) {
          order: 1;
          padding-right: global.vr(1);
        }

        @include mq(bp-lg) {
          padding-right: global.vr(1.5);
        }
      }

      .ds-form-container__split--right & {
        @include mq(bp-sm) {
          order: 2;
          padding-left: global.vr(0.5);
        }

        @include mq(bp-lg) {
          padding-left: global.vr(1.5);
        }
      }

      .ds-form-container__split--top & {
        margin-bottom: global.vr(2);

        @include mq(bp-sm) {
          text-align: unset;
          margin-bottom: unset;
          order: 1;
        }
      }

      .ds-form-container__split--bottom & {
        order: 2;
      }
    }

    &:nth-child(2) {
      order: 1;

      .ds-form-container__split--horizontal & {
        text-align: center;
        margin-bottom: global.vr(2);

        @include mq(bp-sm) {
          text-align: unset;
          margin-bottom: unset;
        }
      }

      .ds-form-container__split--left & {
        @include mq(bp-sm) {
          order: 2;
          padding-left: global.vr(0.5);
        }

        @include mq(bp-lg) {
          padding-left: global.vr(1.5);
        }
      }

      .ds-form-container__split--right & {
        @include mq(bp-sm) {
          order: 1;
          padding-right: global.vr(0.5);
        }

        @include mq(bp-lg) {
          padding-right: global.vr(1.5);
        }
      }

      .ds-form-container__split--top & {
        text-align: center;

        @include mq(bp-sm) {
          order: 2;
          margin-top: global.vr(2);
        }
      }

      .ds-form-container__split--bottom & {
        order: 1;
        text-align: center;
        margin-bottom: global.vr(2);
      }
    }
  }

  .ds-form__field {
    .ds-form__file-name {
      display: block;
    }

    &.ds-form__field--select {
      &.ds-form__field--error {
        &:before {
          right: 35px;
        }
      }
    }

    &.ds-form__field-error {
      input[type='checkbox'] {
        + label {
          &:before {
            border: 1px solid brand.$error;
          }
        }
      }
    }

    input[type='radio'] {
      display: none;

      + label {
        cursor: pointer;
        position: relative;
        padding-left: 34px;
        font-size: 12px;
        line-height: 20px;
        color: brand.$grey-20;
        @include fonts.lato-regular();

        &:before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          padding: 5px;
          border-radius: 50%;
          border: 1px solid brand.$green;
          background-color: brand.$white;
        }
      }

      &:checked + label:before {
        background-color: brand.$green;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
