@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/brand';
@use '../settings/breakpoints' as *;

$sticky-height: 72px;

.ds-sticky-bar {
  display: none;
  position: sticky;
  z-index: 8;
  top: 0;
  height: $sticky-height;
  margin-top: -#{$sticky-height};
  background: brand.$green;

  @supports (position: sticky) {
    display: block;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: global.vr(1.75) global.vr(2.5);

    @include mq(bp-xs) {
      padding: global.vr(1.75) global.vr(5);
    }
  }

  &__title {
    @include fonts.lato-bold;
    font-size: 15px;

    @include mq(bp-xs) {
      font-size: 18px;
    }
  }

  .btn-new {
    font-size: 10px;

    @include mq($until: bp-xs) {
      padding: global.vr(2) global.vr(5) global.vr(2) global.vr(2);
    }

    @include mq(bp-xs) {
      font-size: 12px;
    }

    &__icon {
      width: 12px;

      @include mq(bp-xs) {
        width: 16px;
      }
    }
  }
}
