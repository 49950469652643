@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

.ds-instrument-categories {
  padding: global.vr(8) 0;

  &__text {
    @include animations.fade-up($mode: self);

    max-width: 920px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: global.vr(3);

    @include mq(bp-sm) {
      margin-bottom: global.vr(4);
    }

    h2 {
      font-family: 24px;
      @include fonts.lato-semi-bold();
      margin: 0 0 global.vr(2);
      color: brand.$brand-cddb00;

      @include mq(bp-xs) {
        font-family: 32px;
      }
    }
  }

  &__row {
    @include mq(bp-sm) {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  &__col {
    @include animations.fade-up($mode: self);

    @include mq(bp-sm) {
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          animation-delay: $i * 50ms;
        }
      }
    }

    display: flex;
    flex-direction: column;
    flex-basis: 33.333%;
    align-items: center;
    margin-bottom: global.vr(6);

    &:last-child {
      margin-bottom: 0;
    }

    @include mq(bp-sm) {
      margin-bottom: 0;
    }
  }

  &__image {
    flex: 1;
    margin-bottom: global.vr(1);

    // IE
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex: auto;
    }

    img {
      display: inline-block;
    }
  }

  strong {
    font-size: 20px;
    @include fonts.lato-bold();
    margin-bottom: global.vr(2.5);
    text-transform: uppercase;
  }
}
