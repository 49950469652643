@use '../../helpers/animations';
@use '../../helpers/fonts';
@use '../../helpers/global';
@use '../../settings/brand';
@use '../../settings/breakpoints' as *;

.ds-downloads {
  display: block;

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: global.vr(6);
    text-align: left;

    @include mq(bp-sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @supports (display: grid) {
      display: grid;
      gap: global.vr(4) global.vr(2);
      grid-template-columns: 1fr;

      @include mq(bp-xs) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mq(bp-sm) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include mq(bp-md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mq(bp-lg) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__item {
    @include animations.fade-up();
    display: block;
    flex: 1 1 auto;
    margin-bottom: global.vr(2);
    color: brand.$black;

    @supports (display: grid) {
      margin-bottom: 0;
    }

    @include mq(bp-sm) {
      flex: 0 0 percentage(1 / 3);
    }

    &-inner {
      position: relative;
      padding-left: global.vr(8);

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        background: url('~@img/icons/icon-download.svg') no-repeat center center;
        height: 27px;
        transform: translateY(-50%);
        width: 28px;
      }
    }

    &-title {
      @include fonts.lato-bold();
      @include fonts.ls(40);
      display: block;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 global.vr(1);
    }

    &-action {
      @include fonts.lato-regular();
      @include fonts.ls(40);
      display: block;
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }
  }

  &__cta {
    @include animations.fade-up();
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @include mq(bp-xxs) {
      display: inline-flex;
      align-items: center;
    }

    > span {
      @include fonts.lato-regular();
      font-size: 14px;
      margin-right: global.vr(3);
    }
  }
}
