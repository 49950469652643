@use "./settings/animation";
@use './settings/breakpoints' as *;
@use './settings/brand' as brand;
@use './settings/grid' as *;
@use './helpers/fonts';
@use './helpers/global';
@use './helpers/mixins' as mixins;

@import '~@fonts-ui/Spumante/Spumante.css';

@mixin spumante() {
  font-family: 'Spumante W05 Regular';
}

.christmas-2020 {
  &.show-bg {
    background: url('~@img/christmas-2020/xmas-tree.png') no-repeat center center;

    background: url('~@img/christmas-2020/xmas-tree.png') -95px calc(100% + 10px) no-repeat,
      url('~@img/christmas-2020/oneplus-presents.png') calc(100% + 40px) calc(100% + 12px) no-repeat;
  }

  &__unwrapped {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: calc(100vh - #{$nav-height});
    margin: 0;

    &--step-2 {
      overflow: hidden;
    }

    &-overlay {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .christmas-2020__unwrapped--step-4 & {
        height: calc(100vh - #{$nav-height});
      }
    }

    &-blob {
      position: absolute;
      z-index: 2;
      top: 0;
      width: 100%;
      height: 100%;
      right: -158px;
      min-width: 767px;
      transition: transform 1s animation.$ease-in-out-cubic;

      @include mq(bp-xxs) {
        right: -400px;
        min-width: 1920px;
      }

      @include mq(580px) {
        right: -318px;
      }

      @include mq(bp-md) {
        left: 0;
        right: auto;
        min-width: 0px;
      }

      .christmas-2020__unwrapped--reduced-width & {
        @include mq(bp-xs) {
          transform: translateX(-16%);
        }

        @include mq(bp-sm) {
          transform: translateX(-26%);
        }

        @include mq(bp-md) {
          transform: translateX(-35%);
        }

        @include mq(bp-xl) {
          transform: translateX(-40%);
        }
      }

      .christmas-2020__unwrapped--step-2 & {
        // @include mq($until: bp-xs) {
        //   height: 110px;
        // }
      }

      .christmas-2020__unwrapped--step-4 & {
        height: calc(100vh - #{$nav-height});
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-blob-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &-image {
      position: absolute;
      z-index: 1;
      right: 40px;
      bottom: 0;
      max-width: 47vw;
      opacity: 0;
      transform: scale(0.5);
      transition: opacity 1s animation.$ease-in-out-cubic, transform 1s animation.$ease-in-out-cubic;

      .christmas-2020__unwrapped--reduced-width & {
        opacity: 1;
        transform: scale(1);
      }

      .christmas-2020__unwrapped--step-2 & {
        @include mq($until: bp-xs) {
          z-index: 2;
          max-width: 400px;
          right: -80px;
          bottom: -30px;
        }
      }

      @include mq(bp-lg) {
        max-width: 53vw;
        bottom: -80px;
      }

      .christmas-2020__unwrapped--step-4 & {
        top: 10%;
      }
    }

    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      picture {
        display: contents;
      }

      img {
        display: block;
        max-height: calc(100vh - #{$nav-height});
        margin-left: auto;
      }
    }

    &-inner {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      padding: 40px 30px;
      transition: transform 1s animation.$ease-in-out-cubic;

      @include mq(bp-xs) {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 70px;
        flex: 1;
      }

      @include mq(bp-md) {
        padding-left: 130px;
        padding-right: 260px;
      }

      .christmas-2020__unwrapped--step-2 & {
        @include mq(bp-sm) {
          margin-bottom: 40px;
        }
      }

      h1,
      h2 {
        color: brand.$grey-dark;
        text-shadow: 0 3px 1px brand.$white;
        text-transform: none;
        @include spumante();
      }

      h1 {
        font-size: 62px;
        line-height: 48px;
        max-width: 870px;

        @include mq(bp-xs) {
          font-size: 68px;
          line-height: 54px;
        }

        @include mq(bp-sm) {
          font-size: 5.9vw;
          line-height: 5vw;
          max-width: 550px;
        }

        @include mq(bp-md) {
          font-size: 7vw;
          line-height: 5.8vw;
        }

        @include mq(bp-lg) {
          max-width: 632px;
        }

        @include mq(bp-xxl) {
          font-size: 140px;
          line-height: 118px;
        }
      }

      h2 {
        font-size: 75px;
        max-width: 100%;
        width: 100%;

        @include mq(bp-lg) {
          font-size: 90px;
        }
      }

      p {
        max-width: 870px;

        @include mq(bp-sm) {
          max-width: 550px;
        }

        @include mq(bp-lg) {
          max-width: 632px;
        }
      }
    }
  }

  &__mobile-only {
    display: block;

    @include mq(bp-xs) {
      display: none;
    }
  }

  &__image {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .christmas-2020__unwrapped--step-1 & {
      flex: 1;
    }

    @include mq(bp-xs) {
      display: inline-block;
      width: auto;
      margin-right: 40px;
      margin-bottom: 0;
      transition: opacity 1s animation.$ease-in-out-cubic, transform 1s animation.$ease-in-out-cubic;
      margin-bottom: 30px;
    }

    @include mq(bp-md) {
      margin-right: 80px;
    }

    .christmas-2020__unwrapped--reduced-width & {
      @include mq(bp-xs) {
        opacity: 0;
        transform: scale(0.5);
      }
    }

    .christmas-2020__unwrapped--step-2 &,
    .christmas-2020__unwrapped--step-3 & {
      margin-right: 0;
    }

    > * {
      flex: 1;
    }

    img {
      display: block;
      max-height: 260px;
      margin: 0 auto;

      @include mq(bp-xxs) {
        width: 50%;
        max-height: none;
        margin: 0;
      }

      @include mq(bp-xs) {
        max-height: 621px;
        width: auto;
      }

      @include mq(bp-lg) {
        max-height: 811px;
      }
    }

    h1 {
      font-size: 8vw;
      line-height: 7vw;
      display: block;
      text-align: center;
      padding: 0;
      margin-top: 15px;

      .christmas-2020__unwrapped--step-2 & {
        margin-bottom: 0px;
      }

      @include mq(bp-xs) {
        display: none;
      }
    }
  }

  &__form {
    border-radius: 6px;
    background: brand.$white;
    box-shadow: 0 3px 6px rgba(brand.$black, 0.16);
    width: 100%;
    max-width: 1364px;
    margin: 80px auto 0;
    padding: 24px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    flex-direction: column;
    position: relative;
    @include mixins.fade-in-up(750ms, 250ms, 25px, ease);

    &--left {
      justify-content: flex-start;
    }

    @include mq(bp-xs) {
      padding: 30px;
    }

    @include mq(bp-sm) {
      flex-direction: row;
      padding: 80px;
    }

    &-ribon {
      width: 107px;
      height: 108px;
      position: absolute;
      top: -8px;
      right: -10px;
      background-image: url('/sitefiles/img/christmas-2020/ribon.png');
      background-repeat: no-repeat;
    }

    &-inner {
      &--vert {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }

    &-prize {
      position: relative;
      margin-top: 30px;

      @include mq(bp-sm) {
        margin-top: 0px;
      }

      img {
        position: sticky;
        top: 30px;

        @include mq(bp-xs) {
          top: 80px;
          max-width: 390px;
        }
      }
    }

    &-ticket {
      position: absolute;
      top: 57px;
      left: 15px;
      transform: rotate(21deg);
      filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.5));
      max-width: 100%;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 25px;
        width: 15px;
        height: 15px;
        border: 2px solid #6c2100;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: white;
      }

      &-inner {
        background-color: brand.$green;
        padding: 35px 50px 35px 50px;
        clip-path: polygon(0% 20%, 10% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 80%);
        background-image: url('/sitefiles/img/christmas-2020/sparkles.svg');
        background-repeat: no-repeat;
        background-position: top 8px right 8px;
      }

      &-title {
        h2 {
          font-size: 16px;
          color: brand.$white;
          font-family: 'LatoWeb';
          margin-bottom: 15px;
          margin-top: 0px;
          line-height: 1;
          text-shadow: none;
          text-transform: uppercase;
          letter-spacing: 1.2px;
        }
      }

      &-sub-title {
        h3 {
          margin-bottom: 0px;
          line-height: 1;
          text-transform: uppercase;
          font-size: 36px;
          font-family: 'LatoWeb';
        }
      }
    }
  }

  &__content {
    flex-basis: 60%;

    .christmas-2020__unwrapped--step-2 & {
      display: none;

      @include mq(bp-xs) {
        display: block;
      }
    }

    .christmas-2020__unwrapped--step-2 &,
    .christmas-2020__unwrapped--step-3 & {
      flex-basis: 38%;
    }

    @include mq(bp-xs) {
      padding: 80px 0;
    }

    h1 {
      display: none;

      @include mq(bp-xs) {
        display: block;
      }
    }

    legend {
      font-size: 18px;
      border: none;
    }

    .ds-form__field--circular-radios label {
      margin-bottom: 20px;
    }

    input[type='email'].ds-form__control {
      font-size: 14px;

      &::placeholder {
        font-size: 14px;
      }
    }

    input[type='checkbox'].ds-form__control + label {
      font-size: 14px;
      @include fonts.lato-medium();
      letter-spacing: 1px;
      text-transform: none;
    }

    .ds-btn {
      &--desktop {
        display: none;

        @include mq(550px) {
          display: inline-block;
        }

        @include mq(bp-xs) {
          display: none;
        }

        @include mq(bp-sm) {
          display: inline-block;
        }
      }

      &--mobile {
        display: inline-block;

        @include mq(550px) {
          display: none;
        }

        @include mq(bp-xs) {
          display: inline-block;
        }

        @include mq(bp-sm) {
          display: none;
        }
      }

      &[disabled] {
        pointer-events: none;
        color: rgba(brand.$grey-20, 0.5);

        &:before {
          transform: translate(0, -50%) translate(-230px, 40px) scale(1);
        }
      }
    }
  }

  &__intro-text {
    display: none;

    @include mq(bp-xs) {
      display: block;
    }

    &--closed {
      display: block;
      text-align: center;

      @include mq(bp-xs) {
        margin-top: 100px;
      }
    }
  }

  &__signup {
    max-width: 540px;

    .ds-form__label a {
      text-decoration: underline;

      &:hover {
        color: #000;
      }
    }

    &-split {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-bottom: 30px;

      .ds-form__field {
        margin-bottom: 0;
        padding-right: 20px;
      }

      .ds-btn {
        flex-shrink: 0;
      }

      + .ds-form__field--error {
        margin-top: -15px;
        margin-bottom: 30px;
      }
    }
  }

  &__see-prizes {
    margin-top: 40px;
    max-width: 240px;

    @include mq(bp-sm) {
      max-width: 540px;
      margin-top: 30px;
    }
  }

  &__landing-logo {
    max-width: 150px;
    margin: 0 auto 100px 0;

    .christmas-2020__unwrapped--step-1 & {
      margin: 0 auto 25px 0;
    }

    img {
      width: 100%;
    }
  }

  input:not([type='button']).ds-form__control,
  textarea.ds-form__control,
  select.ds-form__control {
    // overwrite legacy !important
    border-color: brand.$grey-20 !important;

    &:focus {
      // overwrite legacy !important
      border-color: brand.$error !important;
    }
  }

  input[type='checkbox'].ds-form__control + label:before {
    border-color: brand.$grey-20;
  }

  input[type='checkbox'].ds-form__control:checked + label:before {
    background-color: brand.$grey-20;
  }
}
