@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-card--award {
  position: relative;
  background: none;
  box-shadow: none;
  padding: 0;
  @include animations.fade-up(50ms, $mode: self);

  &__link {
    cursor: pointer;
  }

  .ds-card__image {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: brand.$white;
    padding: 30px;
    margin-bottom: global.vr(1);
    transition: box-shadow 0.3s animation.$ease-in-out-cubic,
      transform 0.3s animation.$ease-in-out-cubic;
    transform: translateY(0);

    img {
      max-height: 109px;
      width: auto;
    }
  }

  .ds-card__title {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    color: brand.$grey-max-power;
  }

  &:hover,
  &:focus {
    transform: none;
    box-shadow: none;

    .ds-card__image {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      transform: translateY(-4px);
    }
  }
}
