@use '~sass-mq/mq' as *;
@use '../settings/brand';
@use '../helpers/fonts';

.ds-mpps {
  background-color: brand.$black;

  @include mq($until: bp-sm) {
    display: none;
  }

  &__mobile {
    display: none;

    @include mq($until: bp-sm) {
      display: block;
      overflow: hidden;
    }
  }

  &__container {
    position: relative;
  }

  &__img {
    transition: opacity 1000ms ease;
    opacity: 1;
    user-select: none;
    width: 100%;

    &.in-view {
      opacity: 0.5;
    }

    &.active {
      opacity: 0.4;
    }
  }
}

.mpps-marker {
  position: absolute;
  width: 50px;
  height: 50px;

  &__title {
    position: absolute;
    z-index: 20;
    color: brand.$white;
  }

  &__target {
    width: 10.5vw;
    height: 10.5vw;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
  }

  &__pin {
    position: absolute;
    transform: translate3d(-50%, -50%, 0) scale(0);
    z-index: 1;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    backdrop-filter: brightness(2.2);
    transition: transform 375ms cubic-bezier(0.19, 1, 0.22, 1);
    border: 1px solid #fff;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.6);

    &.active {
      transform: translate3d(-50%, -50%, 0) scale(1);
      animation: pin-animation 2.2s infinite cubic-bezier(0.165, 0.84, 0.44, 1);

      @keyframes pin-animation {
        0% {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
        }
        70% {
          box-shadow: 0 0 0 14px rgba(255, 255, 255, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }
    }

    &:after {
      content: '';
      width: 15px;
      height: 15px;
      background-image: url('~@img/icons/icon-cross.svg');
      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      left: 52%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      color: #fff;
      transition: border 275ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.2);
      transform: translate3d(-50%, -50%, 0) scale(1.5);
      animation: none;
    }
  }

  &__header {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 16px;

    @include mq($until: bp-md) {
      font-size: 16px;
    }

    @include mq($until: bp-sm) {
      font-size: 14px;
    }
  }

  &__text {
    opacity: 0;
    position: absolute;
    left: #{'calc(100% + 10px)'};
    width: 300px;
    color: #fff;
    z-index: 2;
    border-radius: 3px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity 375ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
    pointer-events: none;

    a {
      color: #fff;
    }

    p {
      font-size: 12px;
      margin-bottom: 0px;

      @include mq($until: bp-sm) {
        font-size: 10px;
      }
    }

    img {
      display: block;
      margin-right: 8px;
      margin-bottom: 8px;
      float: left;
      max-width: 40px;
    }

    &.tease {
      opacity: 0.4;
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.mpps-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: calc(100% - 30px);
  z-index: 10;
  background-color: brand.$white;
  padding: 30px;
  border-radius: 2px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms ease;
  border-top: 5px solid brand.$green;

  @include mq($from: bp-md) {
    position: absolute;
  }

  @include mq($from: bp-xs) {
    width: auto;
  }

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-direction: column;

    @include mq($from: bp-md) {
      flex-direction: row;
    }

    &--full {
      .mpps-popup__content-image {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: brand.$grey-35;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 22px;
      height: 22px;
      background-image: url('~@img/icons/icon-cross.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  h2 {
    @include fonts.lato-bold();
    font-size: 18px;
    margin-bottom: 10px;
  }

  &__content-image {
    width: 100%;

    @include mq(bp-md) {
      width: 50%;
    }

    img {
      margin-bottom: 15px;
      align-self: center;

      @include mq($from: bp-sm) {
        margin-bottom: 0px;
      }
    }
  }

  &__content-text {
    padding: 15px 15px 0px 0px;
    width: 100%;

    @include mq(bp-md) {
      width: 50%;
    }

    @include mq($from: bp-xs) {
      padding: 15px 15px 0px 15px;
    }

    h3 {
      font-size: 18px;
      margin-bottom: 5px;

      + p {
        margin-bottom: 22px;
      }
    }

    p {
      position: relative;
      padding-left: 24px;
      line-height: 1;
      margin-bottom: 15px;
      font-size: 16px;

      &:before {
        content: '';
        width: 5px;
        height: 5px;
        background-color: brand.$brand-primary;
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }
  }
}

.mpps-inline-popup {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  z-index: 10;
  background: brand.$brand-primary;
  color: brand.$grey-35;
  padding: 20px 35px 20px 20px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms ease;

  @include mq($from: bp-md) {
    position: absolute;
  }

  .ds-mpps__mobile & {
    max-width: calc(100% - 50px);
    width: 100%;
  }

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  h3 {
    font-size: 18px;
    @include fonts.lato-black;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
