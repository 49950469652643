.ds-slider {
  &__item {
    position: relative;
    height: 0;
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
    font-family: 'object-fit: contain; object-position: center center;';
  }
}
