@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use './settings/animation';
@use './settings/brand';
@use './settings/breakpoints' as *;
@use './settings/grid';

.product-listing-container {
  min-height: 100vh;
}

.product-listing {
  transition: opacity 250ms ease;
  opacity: 0;
  will-change: opacity;
  position: relative;
  z-index: 1;

  .modal-open & {
    z-index: 10;
  }

  .container & {
    margin-left: -15px;
    margin-right: -15px;
  }

  &.theme--dark {
    background-color: brand.$grey-max-power;
  }

  &.ready {
    opacity: 1;
  }

  &__container {
    margin: 0 auto;
    padding: 20px;

    @include mq($until: bp-sm) {
      padding: 40px 15px;
    }

    @include mq($until: bp-xs) {
      padding: 10px 0px;
    }
  }

  // HEADER
  &__header {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    padding: 30px;

    @include mq($until: bp-sm) {
      width: 80%;
    }

    @include mq($until: bp-xs) {
      width: 100%;
    }

    h2 {
      font-size: 32px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
      line-height: 22px;

      @include mq($until: bp-xs) {
        display: none;
      }
    }
  }

  // FOOTER
  &__footer {
    display: none;

    @include mq($until: bp-xs) {
      display: block;
      margin-top: 10px;
      padding: 10px;
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
    }
  }

  // RESULTS
  &__results {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    overflow: hidden;
    display: none;

    &.active {
      display: flex;
    }

    .btn--view-all {
      margin-top: 10px;
    }

    .clear-both {
      clear: both;
      margin-bottom: 30px;
    }
  }

  // ORDER BY FILTERS
  &__order-filters {
    display: none;
    justify-content: space-between;
    padding-bottom: 50px;
    flex-wrap: wrap;
    padding-top: 15px;

    .theme--dark & {
      color: brand.$white;
    }

    &.active {
      display: flex;
    }

    @include mq($until: bp-xs) {
      flex-direction: row-reverse;
      justify-content: space-around;
      border-top: 1px solid brand.$grey-85;
      border-bottom: 1px solid brand.$grey-85;
      padding-bottom: 0px;
      padding-top: 0px;
    }

    .filter-divider {
      display: none;

      @include mq($until: bp-xs) {
        display: block;
        width: 2px;
        background-color: brand.$grey-85;
        margin: 10px 0px;
      }
    }

    .filter-back {
      position: relative;
      font-size: 14px;
      padding-left: 20px;

      a {
        color: brand.$grey-20;
      }

      &:after {
        content: '\f053';
        font-family: 'FontAwesome';
        position: absolute;
        left: 5px;
        top: 49%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        color: brand.$brand-primary;
        line-height: 0px;
      }
    }

    .filter-list {
      display: flex;
      flex: 1 0 auto;
      align-self: center;
      justify-content: center;

      @include mq($until: bp-xs) {
        display: none;
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        list-style-type: none;

        li {
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 4px;
          text-transform: uppercase;
          padding: 0px 25px;
          cursor: pointer;
          position: relative;
          color: brand.$grey-05;
          user-select: none;

          &.active {
            font-weight: bold;

            &:before {
              content: '';
              position: absolute;
              top: 100%;
              width: 80%;
              left: 50%;
              transform: translate(-50%);
              height: 1px;
              background-color: black;
            }
          }

          &:last-child:after {
            display: none;
          }

          &:after {
            content: '';
            position: absolute;
            background-color: #000;
            width: 1px;
            height: 85%;
            right: 0px;
          }
        }
      }
    }

    .filter-block {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      z-index: 3;

      @include mq($until: bp-xs) {
        position: relative;
        border-bottom: none;
        margin-left: 10px;
        width: 38%;
        text-align: center;
        padding: 12px 0;
        font-size: 14px;
      }

      &:after {
        content: '\f078';
        display: none;
        font-size: 18px;
        position: absolute;
        font-family: 'FontAwesome';
        top: 50%;
        right: 5px;
        transform: translate(50%, -50%);
        color: brand.$brand-primary;

        @include mq($until: bp-xs) {
          display: block;
        }
      }

      &--mobile {
        display: none;

        @include mq($until: bp-xs) {
          display: block;

          &:after {
            display: none;
          }
        }
      }

      &--desktop {
        display: block;

        @include mq($until: bp-xs) {
          display: none;
        }
      }

      span {
        margin-right: 20px;
        font-size: 14px;
        font-weight: bold;
        padding: 5px;

        .theme--dark & {
          color: brand.$white;
        }

        @include mq($until: bp-xs) {
          font-weight: normal;
          text-align: center;
          padding: 0px;
        }
      }
    }

    .filter-dropdown {
      min-width: 140px;
      font-size: 12px;
      border: none;
      outline: none;
      position: relative;
      border: 1px solid brand.$grey-85;
      padding: 5px 20px 5px 5px;
      cursor: pointer;

      .theme--dark & {
        color: brand.$white;
      }

      @include mq($until: bp-xs) {
        position: absolute;
        height: 100%;
        width: 100%;
        text-align: left;
        border: none;
        padding: 0px;
      }

      span {
        font-weight: normal;
        font-size: 12px;
        user-select: none;
        padding: 0px;

        @include mq($until: bp-xs) {
          display: none;
        }
      }

      ul {
        display: none;
        position: absolute;
        z-index: 1;
        background: white;
        width: calc(100% + 2px);
        left: -1px;
        margin: 0;
        list-style: none;
        padding: 0;
        top: 39px;
        border: 1px solid brand.$grey-85;

        .theme--dark & {
          background: brand.$grey-max-power;
        }

        @include mq($until: bp-xs) {
          top: 29px;
        }

        li {
          padding: 5px;
          cursor: pointer;

          &:hover {
            background-color: brand.$grey-85;

            .theme--dark & {
              background: #484848;
            }
          }
        }
      }

      &.active {
        ul {
          display: block;
        }

        &:after {
          transform: translate(50%, -50%) rotateX(180deg);
        }
      }

      &:after {
        content: '\f078';
        font-size: 18px;
        position: absolute;
        font-family: 'FontAwesome';
        top: 50%;
        right: 17px;
        transform: translate(50%, -50%);
        color: brand.$brand-primary;

        @include mq($until: bp-xs) {
          display: none;
        }
      }
    }
  }

  &__filter-fluid {
    position: relative;
    left: 0;
    top: 0px;
    width: 100%;

    &.stuck-bottom {
      position: absolute;
      bottom: 0px !important;
      top: auto !important;
    }

    &.stuck-top {
      top: 0px !important;
      bottom: auto !important;
    }
  }

  // FILTERS
  &__filters {
    width: 15%;
    display: flex;
    flex-direction: column;
    position: relative;

    @include mq($until: bp-sm) {
      width: 30%;
    }

    @include mq($until: bp-xs) {
      display: none;
    }

    &-container {
      @include mq($until: bp-xs) {
        position: relative;
        height: calc(90% - 50px);
      }
    }
  }

  // MOBILE FILTERS..
  &__filters-mobile {
    display: none;

    @include mq($until: bp-xs) {
      display: block;
      width: 85%;
      padding: 0px;
      position: fixed;
      right: 0;
      top: 0;
      height: 100vh;
      z-index: 70;
      transform: translateX(100%);
      background: #fff;
      transition: transform 250ms ease;
      will-change: transform;

      .iOS.safari & {
        height: calc(100vh - #{grid.$safari-bottom-bar});
      }

      .android.chrome & {
        height: calc(100vh - #{grid.$chrome-nav});
      }

      .iOS.chrome & {
        height: 100vh;
      }

      &.active {
        transform: translateX(0%);
      }

      &--title {
        height: 50px;
        background-color: brand.$brand-primary;
        text-align: center;
        font-size: 14px;
        text-align: left;
        display: flex;
        align-items: center;
        padding: 0px 20px;
      }

      .close-arrow {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        position: relative;

        &:after {
          content: '\f060';
          font-size: 18px;
          line-height: 0px;
          position: absolute;
          font-family: 'FontAwesome';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: brand.$black;
        }
      }

      .clear {
        margin-left: auto;
      }
    }
  }

  // FILTER CLOSE MOBILE
  &__filters-close-mobile {
    display: none;

    @include mq($until: bp-xs) {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      width: 15%;
      background-color: brand.$grey-85;
      z-index: 70;
      height: 50px;
      transform: translateX(-100%);
      transition: transform 250ms ease;
      will-change: transform;
      cursor: pointer;

      &.active {
        transform: translateX(0%);
      }

      &:after {
        content: '\f00d';
        font-size: 25px;
        position: absolute;
        font-family: 'FontAwesome';
        top: 50%;
        line-height: 0px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: brand.$black;
      }
    }
  }

  // CLEAR FILTERS BUTTON
  &__filter-clear {
    display: inline-block;
    padding: 0 40px;
    margin: 0 auto;
    text-align: center;
    background-color: lightgrey;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  // FILTER
  &__filter {
    margin-bottom: 25px;

    .theme--dark & {
      color: brand.$white;
    }

    @include mq($until: bp-xs) {
      display: none;
    }

    // MOBILE TOP LEVEL FILTER ITEMS
    &--mobile {
      display: none;

      @include mq($until: bp-xs) {
        display: block;
        border-bottom: 1px solid brand.$grey-85;
        padding: 10px;
        position: relative;

        &.active {
          background-color: brand.$grey-85;

          &:after {
            content: '\f00c';
            font-size: 13px;
            position: absolute;
            font-family: 'FontAwesome';
            top: 50%;
            right: 25px;
            transform: translate(50%, -50%);
            color: brand.$black;
          }
        }

        &.no-products {
          color: #a4a4a4;
          cursor: inherit;
        }

        .link-block {
          font-size: 14px;
          text-transform: uppercase;
          padding: 0 15px;
          cursor: pointer;
        }
      }
    }

    &--mobile-options {
      position: absolute;
      background: white;
      width: 100%;
      height: 100%;
      transform: translateX(100%);
      transition: transform 250ms ease;
      will-change: transform;
      top: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      &.active {
        transform: translateX(0%);
      }
    }

    &--show-products {
      margin: 0px 25px;
      margin-top: 10px;
      padding: 5px 0px;
      font-size: 14px;
      background-color: brand.$brand-primary;
      text-align: center;
      text-transform: uppercase;
    }

    // Clicked at this point, someone has hidden it
    &.toggled {
      .product-listing__filter-option {
        display: none;
      }

      .filter-toggle:after {
        transform: translate(50%, -50%);
      }
    }

    &-title {
      text-transform: uppercase;
      padding-bottom: 5px;
      border-bottom: 1px solid brand.$brand-primary;
      margin-bottom: 10px;
      position: relative;
      cursor: pointer;
      user-select: none;
      font-size: 16px;
      font-weight: bold;

      .filter-toggle:after {
        content: '\f078';
        font-size: 13px;
        position: absolute;
        font-family: 'FontAwesome';
        top: 40%;
        right: 15px;
        transform: translate(50%, -50%) rotateX(180deg);
        color: brand.$brand-primary;
      }
    }

    &--checkbox {
      display: flex;
      align-items: flex-end;
      height: 100%;
      margin-bottom: 8px;
      cursor: pointer;

      &.no-products {
        color: #a4a4a4;
        cursor: inherit;
      }

      .box {
        display: block;
        width: 25px;
        height: 25px;
        border: 1px solid brand.$grey-85;
        margin-right: 20px;
        position: relative;

        &:after {
          content: '\f00c';
          font-family: 'FontAwesome';
          font-size: 15px;
          color: brand.$brand-primary;
          position: absolute;
          left: 50%;
          top: 49%;
          transform: translate(-50%, -50%);
          display: none;
        }
      }

      .count {
        display: inline-block;
        color: #a4a4a4;
        margin-left: 3px;
      }

      p {
        font-size: 14px;
        display: block;
        margin-bottom: 0;
        padding: 0;
        user-select: none;
      }

      // Hover effect
      &:hover {
        span:after {
          display: block;
          opacity: 0.2;
        }
      }

      // Active Class
      &.active {
        span:after {
          display: block;
          opacity: 1;
        }
      }

      &.faded {
        opacity: 0.2;
        cursor: inherit;
      }
    }
  }

  // PRODUCTS
  &__products {
    width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 570px;
    margin-bottom: auto;

    @include mq($until: bp-sm) {
      width: 70%;
    }

    @include mq($until: bp-xs) {
      width: 100%;
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: dense;
      grid-gap: 35px;
      padding: 35px;

      @include mq($until: bp-md) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
        padding: 24px;
      }

      @include mq($until: bp-sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px;
        padding: 12px;
      }
    }
  }

  // PRODUCT
  &__product {
    width: 21%;
    margin-right: 4%;
    margin-bottom: 4%;
    animation: product-load-in 250ms 0ms ease forwards;

    @include mq($until: bp-md) {
      width: 29%;
    }

    @include mq($until: bp-sm) {
      width: 46%;
    }

    @include mq($until: bp-xxs) {
      margin-left: 10%;
      width: 35%;
      margin-bottom: 15px;
    }

    @supports (display: grid) {
      width: 100%;
      margin: 0;

      .product-list-item-inner {
        margin: 0px;
      }
    }

    &.span--2 {
      width: 46%;

      @include mq($until: bp-md) {
        width: 63%;
      }

      @include mq($until: bp-sm) {
        width: 96%;
      }

      @include mq($until: bp-xxs) {
        width: 84%;
      }

      @supports (display: grid) {
        width: 100%;
        grid-column: span 2;

        @include mq($until: 340px) {
          grid-column: span 1;
        }
      }
    }
  }

  &__product-cover {
    @include mq($until: bp-xs) {
      opacity: 0;
      pointer-events: none;
      user-select: none;
      transition: opacity 250ms ease;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #000;
      z-index: 1;

      &.active {
        opacity: 0.8;
      }
    }
  }

  &__list-footer {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    text-align: center;

    @include mq($until: bp-xs) {
      padding-bottom: 0px;
    }

    @supports (display: grid) {
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }

  // LOAD MORE
  &__load-more {
    text-align: center;
    margin: 0 auto;
    padding: 0 50px;
    background-color: brand.$grey-85;
    cursor: pointer;
    font-size: 14px;
    display: inline-block;
    user-select: none;
  }

  &__load-more-summary {
    margin-bottom: 45px;
    margin: 0 auto;
    font-size: 14px;
    position: relative;
    margin-bottom: 20px;
    text-align: center;

    .theme--dark & {
      color: brand.$white;
    }

    span {
      font-weight: normal;
      cursor: pointer;
    }
  }
}
