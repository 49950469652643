@use '../helpers/global';

$site-padding: global.vr(3);
$site-padding-md: global.vr(5);

$site-wide: 100%;
$site-wide-md: 992px;
$site-wide-lg: 1244px;
$site-wide-xl: 1470px;
$site-wide-xxl: 1778px;

$nav-height: 70px;
$nav-height--small: 55px;

$safari-bottom-bar: 75px;
$chrome-nav: 56px;

// site wide based on 1920 - percentage (inner pixels when padded)
$site-wide-base: 1920px; // maximum size
$site-wide-padding: (($site-padding * 2.5) * 2); // used to invert padding (60*2)

$site-wide-100: $site-wide-base; // 100% (1920)
$site-wide-85: ($site-wide-base * 0.85) + $site-wide-padding; // 85% (1632)
$site-wide-71: ($site-wide-base * 0.71) + $site-wide-padding; // 71% (1366)
$site-wide-60: ($site-wide-base * 0.6) + $site-wide-padding; // 60% (1152)
