@use '~sass-mq/mq' as *;
@use '../settings/brand';
@use '../settings/layers';
@use '../helpers/fonts';
@use '../helpers/global';

.accessories-picker {
  padding-top: global.vr(2);
  margin-bottom: global.vr(7);

  @include mq(bp-sm) {
    padding-top: global.vr(5);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  + .text-center {
    opacity: 0;
    transition: opacity 600ms ease 600ms;
  }

  &__nav {
    position: sticky;
    top: 0;
    z-index: layers.$layer-default;
    transform: translateY(40px);
    opacity: 0;
    transition: opacity 600ms ease, transform 600ms ease;

    @include mq(bp-sm) {
      position: static;
      flex-basis: 40%;
      transform: translateX(-40px);
      opacity: 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      @include mq($until: bp-sm) {
        width: 100%;
        opacity: 0;
        max-height: 0;
        padding: global.vr(2) 0 global.vr(3);
        overflow: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
        transition: max-height 300ms ease, opacity 300ms ease;
      }

      @include mq(bp-sm) {
        max-width: 388px;
      }
    }

    &-item {
      position: relative;
      cursor: pointer;
      @include fonts.lato-medium();
      font-size: 18px;
      color: brand.$grey-20;
      background-color: brand.$white;
      padding: global.vr(1) global.vr(4);
      transition: background-color 150ms ease, box-shadow 150ms ease;

      @include mq(bp-xxs) {
        padding: global.vr(2) global.vr(4);
      }

      @include mq(bp-sm) {
        font-size: 24px;
        margin-bottom: global.vr(3.5);
      }

      &:before {
        content: ' ';
        display: block;
        position: absolute;
        top: 50%;
        right: global.vr(4);
        height: 20px;
        width: 15px;
        background-image: url('~@img/icons/icon-arrow-accessory.svg');
        transform: scale(0) translateY(-50%) rotateZ(-90deg);
        opacity: 0;
        transform-origin: 50%;
        transition: opacity 150ms ease, transform 150ms ease;
      }

      &:hover {
        @include mq(bp-sm) {
          background-color: rgb(251, 251, 231);
          box-shadow: 0 0 24px rgba(brand.$grey-45, 0.2);

          &:before {
            opacity: 1;
            transform: scale(1) translateY(-50%) rotateZ(-90deg);
          }
        }
      }

      &.is-active:before {
        opacity: 1;
        transform: scale(1) translateY(-50%) rotateZ(-90deg);
      }

      &--selected {
        background-color: rgb(251, 251, 231);

        @include mq(bp-sm) {
          display: none;
        }

        &:before,
        &:hover:before {
          opacity: 1;
          transform: scale(1) translateY(-50%) rotateZ(0deg);
        }

        &.is-active {
          &:before,
          &:hover:before {
            opacity: 1;
            transform: scale(1) translateY(-50%) rotateZ(-180deg);
          }

          + ul {
            opacity: 1;
            max-height: 500px;
          }
        }
      }
    }
  }

  &__result {
    padding-top: global.vr(2);
    transform: translateY(40px);
    opacity: 0;
    transition: opacity 600ms ease 400ms, transform 600ms ease 400ms;

    @include mq(bp-sm) {
      padding-top: 0;
      flex-basis: 60%;
      transform: translateX(-40px);
      opacity: 0;
    }
  }

  &__section {
    opacity: 0;
    transform: translateX(16px);
    height: 0;
    overflow: hidden;
    transition: transform 500ms ease 200ms, opacity 500ms ease 200ms;

    &.is-active {
      opacity: 1;
      transform: translateX(0);
      height: auto;
      overflow: visible;
    }
  }

  &__link {
    display: flex;
    width: 100%;
    flex-direction: row;
    text-decoration: none;
    align-items: flex-start;
    padding: global.vr(4) 0;
    border-bottom: 1px solid rgba(brand.$grey-20, 0.5);
    transition: transform 300ms ease;

    @include mq(bp-xxs) {
      align-items: center;
    }

    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }

    &:hover {
      @include mq(bp-sm) {
        transform: translateX(global.vr(2));
      }
    }
  }

  &__desc {
  }

  &__product-img {
    max-width: 60px;
    margin-right: global.vr(3);

    @include mq(bp-xxs) {
      max-width: 80px;
    }

    @include mq(bp-sm) {
      max-width: 100px;
      margin-right: global.vr(6);
    }
  }

  &__title {
    @include fonts.lato-medium();
    color: brand.$grey-20;
    margin-bottom: global.vr(1);
    font-size: 18px;

    @include mq(bp-xxs) {
      font-size: 22px;
    }

    @include mq(bp-sm) {
      font-size: 24px;
    }
  }

  &__code {
    @include fonts.lato-semi-bold();
    color: lighten(brand.$grey-45, 10%);
    font-size: 14px;

    @include mq(bp-xxs) {
      font-size: 18px;
    }
  }

  &__icon {
    margin-left: auto;
    text-align: right;

    img {
      width: 47px;
      height: 47px;

      @include mq(bp-xxs) {
        width: 67px;
        height: 67px;
      }
    }
  }
}

.animated {
  .accessories-picker {
    &__nav,
    &__result {
      opacity: 1;
      transform: translateY(0);

      @include mq(bp-sm) {
        transform: translateX(0);
      }
    }

    + .text-center {
      opacity: 1;
    }
  }
}
