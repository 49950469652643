@use '~sass-mq/mq' as *;
@use './settings/animation';
@use './settings/brand';
@use './helpers/fonts';
@use './helpers/global';

.fs-compare {
  padding: global.vr(7) 0;

  &__inner {
    border-top: 10px solid brand.$green;
  }

  &__slider {
    position: relative;
    overflow: hidden;

    &:before {
      content: ' ';
      position: absolute;
      z-index: 25;
      top: 50%;
      left: 50%;
      width: 2500px;
      height: 2500px;
      background: brand.$green;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transform-origin: center;
      transition: transform 1s animation.$ease-in-out-cubic;
      will-change: transform;

      .animated & {
        transform: translate(-50%, -50%) scale(0);
        transform-origin: center;
      }
    }
  }

  &__before {
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 0;
    left: -11px;
    z-index: 20;
    transform: skewX(-11deg);
    background: brand.$green;

    &-inner {
      transform: skewX(11deg);
      padding: global.vr(0.5) global.vr(2) global.vr(0.5) global.vr(3);
      font-size: 15px;
      @include fonts.lato-black();
      text-transform: uppercase;

      @include mq(bp-xxs) {
        font-size: 18px;
        padding: global.vr(1) global.vr(2) global.vr(1) global.vr(3);
      }

      @include mq(bp-xs) {
        font-size: 32px;
        padding: global.vr(3) global.vr(5);
      }
    }
  }

  &__after {
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 0;
    right: -11px;
    z-index: 20;
    transform: skewX(11deg);
    background: brand.$green;

    &-inner {
      transform: skewX(-11deg);
      padding: global.vr(0.5) global.vr(3) global.vr(0.5) global.vr(2);
      font-size: 15px;
      @include fonts.lato-black();
      text-transform: uppercase;

      @include mq(bp-xxs) {
        font-size: 18px;
        padding: global.vr(1) global.vr(3) global.vr(1) global.vr(2);
      }

      @include mq(bp-xs) {
        font-size: 32px;
        padding: global.vr(3) global.vr(5);
      }
    }
  }

  &__image {
    filter: grayscale(75%);
    transition: filter 1s animation.$ease-in-out-cubic 500ms;
    will-change: filter;

    .animated & {
      filter: grayscale(0%);
    }

    &--overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 50%;
      overflow: hidden;
      will-change: width;

      img {
        max-width: calc(100vw - 48px);

        @include mq(bp-xs) {
          max-width: calc(100vw - 96px);
        }

        @include mq(bp-md) {
          max-width: calc(100vw - 120px);
        }
      }
    }

    img {
      display: block;
      user-select: none;
      box-sizing: border-box;
    }
  }

  &__range {
    cursor: pointer;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 10px;
    height: 100%;
    width: 120%;
    background: transparent;
    appearance: none;
    outline: none;
    border: 0;
    box-shadow: none;

    &:focus {
      border: 0 !important;
    }

    @mixin thumb() {
      display: block;
      cursor: ew-resize;
      width: 55px;
      height: 50px;
      border: 0;
      outline: none;
      box-shadow: none;
      background: url('~@img/icons/icon-drag.svg') center no-repeat;
      background-size: contain;

      @include mq(bp-xs) {
        cursor: ew-resize;
        width: 75px;
        height: 68px;
      }
    }

    &::-webkit-slider-thumb {
      @include thumb();
    }

    &::-moz-range-thumb {
      @include thumb();
    }

    &::-ms-thumb {
      @include thumb();
    }

    &::-ms-fill-upper {
      opacity: 0;
    }

    &::-ms-fill-lower {
      opacity: 0;
    }

    &::-ms-track {
      opacity: 0;
      height: 0;
    }

    &::-ms-tooltip {
      display: none;
    }

    &::-webkit-slider-runnable-track,
    &::-webkit-slider-thumb,
    &:focus {
      appearance: none;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  &__logo {
    pointer-events: none;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: global.vr(2);
    left: 0;
    right: 0;

    @include mq(bp-xxs) {
      bottom: global.vr(3);
    }

    img {
      max-height: 16px;
      height: 100%;

      @include mq(bp-xxs) {
        max-height: 28px;
      }

      @include mq(bp-xs) {
        max-height: 42px;
      }
    }
  }
}
