@use "./settings/animation";
@use './settings/breakpoints' as *;
@use './settings/brand' as brand;
@use './settings/grid' as *;
@use './helpers/fonts';
@use './helpers/global';
@use './helpers/mixins' as mixins;

.christmas-2020__game {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 20;
  width: 100%;
  max-width: 344px;
  margin: 0 auto;
  padding: 40px;
  border-radius: 6px;
  background: brand.$white;
  box-shadow: 0 3px 6px rgba(brand.$black, 0.16);
  opacity: 0;
  animation: game-in 750ms forwards animation.$ease-in-out-cubic 500ms;
  animation-play-state: paused;

  @include mq(bp-xs) {
    animation-delay: 1500ms;
  }

  .christmas-2020__unwrapped--step-2 &,
  .christmas-2020__unwrapped--step-3 & {
    animation-play-state: running;
  }

  @keyframes game-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @include mq(bp-xs) {
    position: absolute;
    top: 50%;
    right: 80px;
    transform: translateY(-50%);
  }

  @include mq(bp-md) {
    right: 120px;
  }

  @include mq(bp-lg) {
    max-width: 490px;
    right: 160px;
  }

  &-inner {
    position: relative;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 264px;

    @include mq(bp-lg) {
      min-height: 344px;
    }
  }

  &-image {
    display: block;
    position: relative;
    z-index: 2;
    top: 0;
    left: 0;

    &--active {
    }
  }

  &-button {
    width: 129px;
    height: 129px;
    backdrop-filter: blur(30px) brightness(1.5);
    border-radius: 50%;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

    &--play:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 52%;
      background-image: url('~@img/icons/icon-play.svg');
      background-size: contain;
      width: 40px;
      height: 50px;
      background-position: center center;
      background-repeat: no-repeat;
      transform: translate(-50%, -50%);
      filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));
    }

    &--pause:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      background-color: #fff;
      transform: translate(-50%, -50%);
      filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));
    }
  }

  p {
    font-size: 19px;
    @include fonts.lato-medium();
    margin: 0;
    text-transform: uppercase;
  }
}
