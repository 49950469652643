@use '../settings/animation';

@mixin fade-in($delay: 0s, $mode: self) {
  opacity: 0;
  // Add the required transitions
  transition: opacity 0.6s animation.$ease-in-out-cubic;
  transition-delay: $delay;

  @if ($mode == parent) {
    .animated & {
      opacity: 1;
    }
  } @else if ($mode == self) {
    &.animated {
      opacity: 1;
    }
  }
}

@mixin fade-up($delay: 0s, $mode: self) {
  opacity: 0;
  // Add a translate transform
  transform: translateY(20px);
  // Add the required transitions
  transition: opacity 0.6s animation.$ease-in-out-cubic, transform 0.6s animation.$ease-in-out-cubic;
  transition-delay: $delay;

  @if ($mode == parent) {
    .animated & {
      opacity: 1;
      transform: translateY(0);
    }
  } @else if ($mode == self) {
    &.animated {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@mixin fade-down($delay: 0s, $mode: self) {
  opacity: 0;
  // Add a translate transform
  transform: translateY(-20px);
  // Add the required transitions
  transition: opacity 0.6s animation.$ease-in-out-cubic, transform 0.6s animation.$ease-in-out-cubic;
  transition-delay: $delay;

  @if ($mode == parent) {
    .animated & {
      opacity: 1;
      transform: translateY(0);
    }
  } @else if ($mode == self) {
    &.animated {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@mixin fade-right($delay: 0s, $mode: self) {
  opacity: 0;
  // Add a translate transform
  transform: translateX(-20px);
  // Add the required transitions
  transition: opacity 0.6s animation.$ease-in-out-cubic, transform 0.6s animation.$ease-in-out-cubic;
  transition-delay: $delay;

  @if ($mode == parent) {
    .animated & {
      opacity: 1;
      transform: translateX(0);
    }
  } @else if ($mode == self) {
    &.animated {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@mixin fade-left($delay: 0s, $mode: self) {
  opacity: 0;
  // Add a translate transform
  transform: translateX(20px);
  // Add the required transitions
  transition: opacity 0.6s animation.$ease-in-out-cubic, transform 0.6s animation.$ease-in-out-cubic;
  transition-delay: $delay;

  @if ($mode == parent) {
    .animated & {
      opacity: 1;
      transform: translateX(0);
    }
  } @else if ($mode == self) {
    &.animated {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@mixin image-fade($delay: 0s, $mode: self) {
  filter: grayscale(1) brightness(1.5);
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: filter 0.6s animation.$ease-in-out-cubic ($delay + 0.2s),
    opacity 0.6s animation.$ease-in-out-cubic $delay;
  will-change: filter;

  @if ($mode == parent) {
    .animated & {
      filter: grayscale(0) brightness(1);
      opacity: 1;
    }
  } @else if ($mode == self) {
    &.animated {
      filter: grayscale(0) brightness(1);
      opacity: 1;
    }
  }
}
