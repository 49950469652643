@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use './settings/animation';
@use './settings/breakpoints' as *;
@use './settings/brand';

.icon-banner {
  display: flex;
  align-items: center;
  background: brand.$brand-cddb00;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  text-align: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    transition: transform 0.6s animation.$ease-in-out-cubic;
    width: 150%;
  }

  &::before {
    background: #abb92f;
    height: 200px;
    opacity: 0.5;
    transform: skewY(-4deg) translateY(100%);
    transform-origin: bottom right;
    transition-delay: 0.1s;
    z-index: 2;
  }

  &.animated::before {
    transform: skewY(-4deg) translateY(60%);
  }

  &::after {
    background: #c7d63c;
    height: 150px;
    transform: skewY(4deg) translateY(100%);
    transform-origin: bottom left;
    transition-delay: 0.2s;
    z-index: 1;
  }

  &.animated::after {
    transform: skewY(4deg) translateY(50%);
  }

  @include mq(bp-md) {
    &::before {
      height: 470px;
      transform: skewX(1deg) skewY(-1deg) translateY(100%);
    }

    &.animated::before {
      transform: skewX(4deg) skewY(-4deg) translateY(60%);
    }

    &::after {
      height: 330px;
      transform: skewX(1deg) skewY(1deg) translateY(100%);
    }

    &.animated::after {
      transform: skewX(4deg) skewY(4deg) translateY(50%);
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    z-index: 3;
  }

  &__title {
    @include animations.fade-up($delay: 0.8s, $mode: parent);
    display: block;
    @include fonts.lato-semi-bold();
    font-size: 26px;
    @include fonts.ls(20);
    line-height: 1em;
    margin-bottom: global.vr(2);

    @include mq(bp-md) {
      font-size: 48px;
    }
  }

  &__subtitle {
    @include animations.fade-up($delay: 0.6s, $mode: parent);
    display: block;
    @include fonts.lato-regular();
    font-size: 14px;
    @include fonts.ls(100);
    line-height: 1em;
    text-transform: uppercase;
    margin-bottom: global.vr(2);

    @include mq(bp-md) {
      font-size: 18px;
    }
  }

  &__icon {
    @include animations.fade-up($delay: 0.4s, $mode: parent);
    display: block;
    margin-bottom: global.vr(2);

    svg {
      display: inline-block;
      height: 60px;
      width: 60px;

      @include mq(bp-md) {
        height: 70px;
        width: 70px;
      }
    }
  }
}
