@use '../helpers/fonts';
@use '../helpers/global';

.ds-author {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 58px;
    margin-bottom: global.vr(1);
    width: 58px;
    background: #f4f4f4;
    color: #313131;
  }

  &__name {
    @include fonts.lato-regular();
    font-size: 14px;
    line-height: 17px;
    margin-bottom: global.vr(0.5);
  }

  &__location {
    @include fonts.lato-bold();
    font-size: 14px;
    line-height: 17px;
  }

  &__flag {
    max-width: 24px;
  }
}
