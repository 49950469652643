@use './settings/breakpoints' as *;
@use './settings/brand' as brand;

.christmas-2020-promo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    margin: 40px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    padding: 0 35px;
    width: 100%;
    justify-content: space-between;

    @include mq(bp-xs) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include mq(bp-xl) {
      padding: 0;
    }
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
    background: #e7f6ff;
    border-radius: 25px 25px 0 0;
    box-shadow: 0 3px 6px rgba(brand.$black, 0.16);
    overflow: hidden;
    margin-bottom: 22px;

    @include mq(bp-xs) {
      flex: 0 1 49%;
    }

    @include mq(bp-lg) {
      flex: 0 1 32%;
      margin-bottom: 0;
    }
  }

  &__card-caption {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 22px;
    left: -100px;
    transform: rotate(-35deg);
    font-size: 13px;
    width: 300px;
    background: #c30803;
    color: brand.$white;

    span {
      max-width: 120px;
      text-align: center;
      line-height: 18px;
      margin: 5px;
    }
  }

  &__card-header {
    flex-grow: 1;
    padding: 35px 0 0;
    display: flex;
    justify-content: center;
    background: url('~@img/christmas-2020/stars.png') no-repeat center center;

    img {
      align-self: flex-end;
      width: 100%;
    }
  }

  &__card-body {
    position: relative;
    padding: 20px 35px 35px;
    background: white;
  }

  &__footer {
    margin: 60px;
  }
}
