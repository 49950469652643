@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';
@use './settings/animation';

.instrument-guide {
  background-color: #f4f4f4;

  &__container {
    padding-top: global.vr(6);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @include animations.fade-up($delay: 150ms, $mode: parent);
    @include fonts.lato-semi-bold();
    font-size: 32px;
    line-height: 58px;
    padding-bottom: global.vr(2);
    max-width: 820px;
    text-align: center;
  }

  &__description {
    @include animations.fade-up($delay: 150ms, $mode: parent);

    max-width: 820px;
    @include fonts.lato-regular();
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }

  &__subtitle {
    @include animations.fade-up($delay: 150ms, $mode: parent);
    @include fonts.lato-regular();
    font-size: 18px;
    line-height: 28px;
    padding-bottom: global.vr(5);
  }

  &__tab-container {
    display: flex;
    overflow: hidden;

    @include mq(bp-sm) {
      justify-content: center;
    }

    .tns-outer {
      max-width: 100%;
    }
  }

  &__tabs {
    display: flex;
    width: 100%;

    @include mq(bp-sm) {
      justify-content: center;
      width: 100% !important;
    }
  }

  &__tab {
    @include animations.fade-up($delay: 150ms, $mode: parent);

    // important to overwrite tinyslider
    display: flex !important;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 global.vr(2);
    padding-bottom: global.vr(8);
    cursor: pointer;
    user-select: none;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: 0;
      border-left: 34px solid transparent;
      border-right: 34px solid transparent;
      border-bottom: 25px solid brand.$brand-cddb00;

      transform: translateY(25px);
      transition: transform 0.3s animation.$ease-in-out-cubic;
    }

    &.active {
      &:after {
        transform: translateY(0px);
      }
    }

    &-img-container {
      width: 184px;
      height: 184px;
      background-color: brand.$white;
      border: 2px solid brand.$grey-max-power;
      border-radius: 50%;
      position: relative;

      .active & {
        border: 4px solid brand.$brand-cddb00;
      }
    }

    &-img {
      max-width: 124px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-model {
      @include fonts.lato-bold();
      font-size: 12px;
      line-height: 22px;
      padding: global.vr(1) 0;
      color: #595b60;
    }

    &-product {
      @include fonts.lato-bold();
      font-size: 20px;
      line-height: 25px;
      color: brand.$grey-max-power;
      max-width: 170px;
      text-align: center;

      .active & {
        color: brand.$brand-cddb00;
      }
    }
  }

  &__tab-items-container {
    background-color: brand.$brand-cddb00;
  }

  &__tab-items {
    position: relative;
  }

  &__item {
    position: relative;
    opacity: 0;
    height: 0;
    visibility: hidden;

    &.active {
      padding: global.vr(4) 0;
      opacity: 1;
      height: 100%;
      visibility: visible;
    }

    &-container {
      display: none;

      .active & {
        display: block;
      }

      .ds-accordion {
        opacity: 0;
        transform: translateY(25px);
        transition: transform 0.3s animation.$ease-in-out-cubic opacity 0.3s
          animation.$ease-in-out-cubic;

        .active & {
          opacity: 1;
          transform: translateY(0);
        }

        &__item {
          &-header {
            padding-left: global.vr(2);
            border-bottom: 2px solid brand.$grey-max-power;
            &::after {
              background-color: brand.$grey-max-power;
            }
          }

          &-row {
            position: relative;
            @include fonts.lato-regular();
            font-size: 16px;
            line-height: 24px;
            color: brand.$grey-max-power;
            padding: global.vr(2) global.vr(10) global.vr(2) global.vr(2);

            &:nth-child(even) {
              background-color: #c9cf0f;
            }

            &:after {
              content: '';
              position: absolute;
              right: 27px;
              top: 50%;
              transform: translateY(-50%);
              background-image: url('~@img/icons/icon-cross-bold--black.svg');
              background-repeat: no-repeat;
              width: 18px;
              height: 18px;
            }

            &--tick {
              &:after {
                background-image: url('~@img/icons/icon-tick--black.svg');
              }
            }
          }
        }
      }
    }
  }
}
