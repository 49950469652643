@use '../helpers/fonts';
@use '../helpers/global';
@use "../settings/breakpoints" as *;

.ds-list-section {
  display: block;
  width: 100%;

  &__title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 70px + global.vr(3) + global.vr(4);
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      display: block;
      background: #d3d80f;
      height: 70px;
      transform: translateY(#{global.vr(3)});
      width: 1px;
    }
  }

  &__title {
    @include fonts.lato-regular();
    display: block;
    color: #313131;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: global.vr(1);

    @include mq(bp-sm) {
      font-size: 33px;
      line-height: 40px;
    }
  }

  &__subtitle {
    @include fonts.lato-regular();
    display: block;
    color: #979797;
    font-size: 15px;
    line-height: 18px;
  }

  &__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @supports (display: grid) {
      display: grid;
      gap: global.vr(5);
      grid-template-columns: repeat(2, 1fr);
      width: 100%;

      @include mq(bp-sm) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include mq(bp-md) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  &__item {
    display: block;
    flex: 0 0 46%;
    margin: 0 2%;

    @include mq(bp-sm) {
      flex: 0 0 29%;
    }

    @include mq(bp-md) {
      flex: 0 0 21%;
    }

    @supports (display: grid) {
      flex: none;
      margin: 0;
    }

    > * {
      // Ensure whatever is inside the item is full width.
      width: 100%;
    }
  }
}
