@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';
@use '../helpers/mixins';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-gallery-detail {
  position: relative;
  height: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    padding-right: global.vr(0.5);
    width: 100%;

    @include mq(bp-sm) {
      @include mixins.custom-scrollbar(transparent, brand.$white, brand.$grey-70);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      overflow-y: scroll;
    }
  }

  &__title {
    display: block;
    color: brand.$grey-20;
    @include fonts.lato-bold();
    font-size: 18px;
    line-height: 1em;
    margin-bottom: global.vr(1);
  }

  &__subtitle {
    display: block;
    color: brand.$grey-60;
    @include fonts.lato-bold();
    font-size: 14px;
    line-height: 1em;
    margin-bottom: global.vr(2);
  }

  &__content {
    display: block;
    color: brand.$grey-20;
    @include fonts.lato-semi-bold();
    font-size: 16px;
    @include fonts.ls(20);
    line-height: 24px;
    margin-bottom: global.vr(2);
  }

  &__date {
    display: block;
    color: brand.$grey-60;
    @include fonts.lato-bold();
    font-size: 14px;
    line-height: 1em;
    margin-bottom: global.vr(2);
  }

  &__footer {
    display: block;
    margin-top: auto;

    img {
      display: inline-block;
      width: 80px;
    }

    span {
      display: inline-block;
      color: brand.$grey-40;
      @include fonts.lato-regular();
      font-size: 14px;
      line-height: 1em;
      margin-left: global.vr(1);
    }
  }

  .ds-media-list {
    margin: global.vr(2) 0;
  }
}
