@use 'sass:color';

@use '../../helpers/fonts';
@use '../../helpers/global';

@use '../../settings/animation';
@use '../../settings/brand';
@use '../../settings/breakpoints' as *;

.ds-card {
  display: block;
  background: brand.$white;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
  padding: global.vr(3) global.vr(7);
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.3s animation.$ease-in-out-cubic,
    transform 0.3s animation.$ease-in-out-cubic;
  transform: translateY(0);

  &:focus,
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    text-decoration: none;
    transform: translateY(-4px);
  }

  &__thumbnail {
    display: block;
    max-height: 148px;
    max-width: 148px;
    margin: 0 auto global.vr(3);

    @include mq(bp-sm) {
      max-height: 180px;
      max-width: 180px;
    }

    img {
      @include global.object-fit(contain, center center);
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &__title-block {
    @include global.title-block();
  }

  &__title {
    @include fonts.lato-regular();
    @include fonts.ls(50);
    display: block;
    color: color.change(brand.$grey-20, $alpha: 0.8);
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }

  &__subtitle {
    @include fonts.lato-regular();
    @include fonts.ls(50);
    display: block;
    color: color.change(brand.$grey-20, $alpha: 0.3);
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 global.vr(0.5);
  }

  .ds-carousel__item & {
    margin-top: 4px;
    margin-bottom: 4px;
    height: calc(100% - 8px);
  }
}
