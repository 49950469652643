@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-split-cta {
  display: flex;
  flex-direction: column;

  @include mq(bp-xs) {
    flex-direction: row;
  }

  &__col {
    &:first-child {
      display: inline-flex;
      align-items: center;
      margin-bottom: global.vr(5);

      @include mq(bp-xs) {
        flex: 0 0 55%;
        border-right: 1px solid brand.$white;
        margin-bottom: 0;
        padding-right: global.vr(5);
      }
    }

    &:last-child {
      @include mq(bp-xs) {
        flex: 0 0 45%;
        padding: global.vr(3) 0 global.vr(3) global.vr(5);
      }
    }
  }

  &__image {
    width: 100%;
    @include animations.image-fade($mode: parent);
  }

  &__title-block {
    @include global.title-block();
  }

  &__title {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-semi-bold();
    @include fonts.ls(-10);
    font-size: 48px;
    line-height: 1em;
    margin: 0 0 global.vr(3);
  }

  &__subtitle {
    @include animations.fade-up($mode: parent);
    @include fonts.lato-regular();
    @include fonts.ls(100);
    font-size: 14px;
    line-height: 1em;
    margin: 0 0 global.vr(0.5);
  }

  &__content {
    @include animations.fade-up($mode: parent, $delay: 0.4s);
    @include fonts.lato-regular();
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 global.vr(2);
  }

  &__link {
    @include animations.fade-up($mode: parent, $delay: 0.6s);
  }
}
