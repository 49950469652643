@use '../../helpers/fonts';
@use '../../helpers/global';
@use '../../helpers/animations';

@use '../../settings/animation';
@use '../../settings/brand';
@use '../../settings/breakpoints' as *;

.ds-reviews {
  display: block;

  &__inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include mq(bp-sm) {
      justify-content: flex-start;
    }
  }

  &__column {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;

    @include mq(bp-xs) {
      &:nth-child(1) {
        flex: 1 1 60%;
      }

      &:nth-child(2) {
        flex: 0 0 40%;
        max-width: 300px;
      }
    }

    @include mq(bp-lg) {
      &:nth-child(1) {
        flex: 1 1 percentage(2/3);
      }

      &:nth-child(2) {
        flex: 0 0 percentage(1/3);
      }
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: global.vr(4);
    padding-bottom: global.vr(2);

    @include mq(bp-xs) {
      padding-left: global.vr(4);
    }
  }

  &__thumbnail {
    @include animations.image-fade($mode: parent);
    flex: 0 0 72px;
    height: 72px;
    width: 72px;
  }

  &__title-block {
    display: flex;
    flex-direction: column;
    margin-left: global.vr(2);

    @include mq(bp-sm) {
      margin-right: global.vr(8);
    }
  }

  &__title {
    @include animations.fade-up($delay: 0.2s, $mode: parent);
    @include fonts.lato-regular();
    align-self: flex-end;
    color: brand.$grey-20;
    font-size: 18px;
    line-height: 24px;

    @include mq(bp-sm) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__code {
    @include animations.fade-up($delay: 0.4s, $mode: parent);
    @include fonts.lato-semi-bold();
    @include fonts.ls(100);
    align-self: flex-start;
    color: brand.$grey-45;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &__average {
    @include animations.fade-up();
    color: brand.$grey-20;

    &-skew-outer {
      background: #cddb00;

      @include mq(bp-xs) {
        transform: skewY(7deg);
        transform-origin: left;
      }
    }

    &-skew-inner {
      display: flex;
      background: #cddb00;
      flex-wrap: wrap;
      padding: global.vr(3) global.vr(4);

      @include mq(bp-xs) {
        transform: skewY(-7deg);
        transform-origin: left;
      }
    }

    &-title {
      @include fonts.lato-regular();
      display: block;
      flex: 1 1 100%;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: global.vr(2);
    }

    &-column {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;

      & + & {
        margin-left: global.vr(1);
      }
    }

    &-row {
      width: 100%;
    }

    &-review {
      margin-top: global.vr(1);
      font-size: 14px;
      text-decoration: underline;
      display: inline-block;
      cursor: pointer;
    }

    &-score {
      @include fonts.lato-semi-bold();
      display: inline-block;
      font-size: 48px;
      line-height: 1em;
    }

    &-stars {
      display: inline-block;
      margin-bottom: global.vr(1.5);

      .ds-stars {
        color: brand.$grey-20;

        &__item {
          height: 18px;
          width: 19px;
        }
      }
    }

    &-count {
      @include fonts.lato-semi-bold();
      @include fonts.ls(100);
      display: inline-block;
      font-size: 13px;
      line-height: 1em;
    }
  }

  &__chart {
    display: flex;
    flex-direction: column;
    margin-bottom: global.vr(4);

    @include mq(bp-xs) {
      padding-left: global.vr(4);
      padding-right: global.vr(4);
    }

    @include mq(bp-sm) {
      margin-bottom: 0;
    }

    &-row {
      @include animations.fade-up();
      display: flex;
      align-items: center;
      margin-bottom: global.vr(1);
      cursor: default;

      &:last-child {
        margin-bottom: 0;
      }

      @for $var from 1 to 6 {
        &:nth-child(#{$var}) {
          .ds-reviews__chart-bar::before {
            transition-delay: $var * 0.2s;
          }
        }
      }

      &--inactive.ds-reviews__chart-row.animated {
        opacity: 0.3;
      }
    }

    &-count {
      @include fonts.lato-semi-bold();
      @include fonts.ls(100);
      flex: 0 0 auto;
      font-size: 14px;
      line-height: 17px;
      margin-left: global.vr(2);
      width: 60px;
    }

    &-bar {
      position: relative;
      background: brand.$grey-95;
      flex: 1 1 auto;
      height: 13px;
      margin-left: global.vr(2);
      margin-right: auto;
      max-width: 400px;
      z-index: 1;

      &-inner {
        position: absolute;
        top: 0;
        left: 0;
        background: #cadb36;
        height: 100%;
        transform: scaleX(0);
        transform-origin: center left;
        transition: transform 0.6s animation.$ease-in-out-cubic;
        width: var(--value);
        z-index: 2;

        .animated & {
          transform: scaleX(1);
        }
      }
    }

    .ds-stars {
      flex: 0 0 auto;
      color: #cadb36;

      &__item {
        height: 15px;
        width: 16px;
      }
    }
  }

  &__list {
    flex: 0 0 100%;
    margin-top: global.vr(4);

    @include mq(bp-xs) {
      margin-top: global.vr(8);
    }
  }

  &__item {
    display: flex;
    border-top: 1px solid brand.$grey-95;
    color: brand.$grey-20;
    flex-direction: column;
    padding: global.vr(3) 0;

    @include mq(bp-xs) {
      padding-left: global.vr(4);
      padding-right: global.vr(4);
    }

    &:last-child {
      border-bottom: 1px solid brand.$grey-95;
    }

    @include mq(bp-sm) {
      flex-direction: row;
    }

    &-author {
      @include animations.fade-up($mode: parent);
      flex: 0 0 auto;
      margin-bottom: global.vr(2);

      @include mq(bp-xs) {
        padding-left: 0;
      }

      @include mq(bp-sm) {
        padding: global.vr(2);
        padding-left: 0;
        margin-right: global.vr(2);
        margin-bottom: 0;
      }
    }

    &-inner {
      flex: 1 1 auto;

      @include mq(bp-sm) {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    &-title {
      @include animations.fade-up($mode: parent);
      @include fonts.lato-bold();
      display: flex;
      align-items: center;
      color: brand.$grey-20;
      font-size: 14px;
      justify-content: center;
      line-height: 17px;
      margin-bottom: global.vr(1);

      @include mq(bp-sm) {
        justify-content: flex-start;
      }
    }

    &-date {
      @include animations.fade-up($delay: 0.2s, $mode: parent);
      @include fonts.lato-semi-bold();
      @include fonts.ls(100);
      display: block;
      color: brand.$grey-45;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: global.vr(1);
      text-align: center;

      @include mq(bp-sm) {
        text-align: left;
      }
    }

    &-content {
      @include animations.fade-up($delay: 0.4s, $mode: parent);
      @include fonts.lato-regular();
      @include fonts.ls(50);
      font-size: 14px;
      line-height: 24px;
      text-align: center;

      @include mq(bp-sm) {
        text-align: left;
      }

      &--right-bottom {
        @include mq(bp-sm) {
          text-align: right;
          justify-content: flex-end;
          margin-top: auto;
        }
      }

      a {
        @include fonts.lato-regular-italic();
        color: #cddb00;
        text-decoration: underline;
        white-space: nowrap;

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      .translate-link {
        cursor: pointer;
        display: inline-block;
        color: brand.$grey-60;
      }
    }

    .ds-stars {
      color: #cadb36;
      margin-right: global.vr(0.5);
      margin-bottom: 2px;

      &__item {
        height: 16px;
        width: 17px;
      }
    }
  }

  &__reply {
    background: #f4f4f4;
    padding: global.vr(2);
    font-size: 14px;
    margin-top: global.vr(1);

    &-name {
      font-weight: bold;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      img {
        margin-right: 8px;
        width: 24px;
        height: 24px;
      }
    }
  }

  &__pagination {
    @include animations.fade-up();
    display: block;
    border-bottom: 1px solid brand.$grey-95;
    flex: 1 1 100%;
    padding: global.vr(1) 0 global.vr(2);

    .ds-pagination {
      justify-content: flex-end;
    }
  }

  &__cta {
    @include animations.fade-up();
    margin-top: global.vr(5);
  }

  &__load-more {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: global.vr(3);

    svg {
      transform: rotate(90deg);
    }
  }
}
