@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use './settings/brand';
@use './settings/breakpoints' as *;

.ds-top-tips {
  position: relative;
  z-index: 9;
  display: flex;
  background: #f4f4f4;
  margin: 0;
  overflow: hidden;
  flex-direction: column;

  @include mq(bp-sm) {
    flex-direction: row;
  }

  &__left {
    position: relative;
    flex: 1 1 100%;
    overflow: hidden;
    width: 100%;
    z-index: 2;

    height: auto;

    @include mq(bp-sm) {
      height: 600px;
      width: 55%;
      flex: 1 1 55%;
    }
  }

  &__image {
    @include animations.image-fade($mode: parent, $delay: 0.2s);
    display: block;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      object-position: center center;
    }
  }

  &__right {
    display: block;
    padding: 0;
    width: 100%;
    position: relative;
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: global.vr(4);
    background: #f4f4f4;
    z-index: 3;
    transform-origin: top right;
    transform: skewX(-13deg);

    @include mq(bp-sm) {
      width: 45%;
      flex: 1 1 45%;
    }
  }

  &__content {
    width: 100%;
    max-width: 396px;
    transform-origin: top right;
    transform: skewX(13deg);
    padding: 0 global.vr(2);

    @include mq(bp-sm) {
      padding: 0;
    }
  }

  &__title {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-black();
    font-size: 32px;
    line-height: 34px;
    color: brand.$grey-max-power;
    padding-bottom: global.vr(3);
  }

  &__list {
    list-style-type: none;
    @include animations.fade-up($mode: parent, $delay: 0.2s);
  }

  &__tip {
    @include fonts.lato-regular();
    font-size: 18px;
    line-height: 28px;
    color: brand.$grey-max-power;
    max-width: 396px;
    padding-bottom: global.vr(2);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: brand.$green;
      left: -40px;
      top: 5px;
    }
  }
}
