@use 'sass:color';

@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

$overlap-md: 40px;
$step-md: 8px;
$width-md: 150px;

$overlap-lg: 40px;
$step-lg: 8px;
$width-lg: 218px;

$z-base: 3;

.ds-banner-skew {
  position: relative;
  z-index: 9;
  display: flex;
  background: #646466;
  flex-direction: column;
  margin: 0;
  overflow: hidden;

  @include mq(bp-md) {
    flex-direction: row;
  }

  &--reverse,
  &--instrument {
    flex-direction: column-reverse;

    @include mq(bp-md) {
      flex-direction: row-reverse;
    }
  }

  &__left {
    position: relative;
    background: brand.$green;
    flex: 1 1 55%;
    overflow: hidden;
    padding: global.vr(8) global.vr(6) global.vr(4);
    z-index: 2;

    @include mq(bp-xxs) {
      padding: global.vr(8) global.vr(8) global.vr(4);
    }

    @include mq($until: bp-xxs) {
      .ds-banner-skew--instrument & {
        padding: global.vr(5) global.vr(3) global.vr(4);
      }
    }

    @include mq(bp-md) {
      padding: global.vr(18) global.vr(15);
      transform: skewX(-13deg);
      transform-origin: top right;
      width: 55%;

      .ds-banner-skew--reverse & {
        transform: skewX(13deg);
        transform-origin: top right;
      }

      .ds-banner-skew--instrument & {
        padding: global.vr(10) global.vr(15);
        margin-right: -220px;
        padding-left: global.vr(6);
        flex: 1 1 40%;
        width: 40%;
      }
    }

    @include mq(bp-lg) {
      padding: global.vr(25) global.vr(17);
      width: 55%;

      .ds-banner-skew--instrument & {
        padding: global.vr(22) global.vr(17);
        padding-left: global.vr(6);
        flex: 1 1 30%;
        width: 30%;
        min-width: 650px;
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 150%;
    }

    &::before {
      background: #c9cf0f;
      height: 200px;
      transform: skewY(-8deg);
      transform-origin: bottom right;
      z-index: 1;

      @include mq(bp-md) {
        height: 470px;
        transform: skewX(13deg) skewY(-13deg) translateY(100%);
        transition: transform 0.6s animation.$ease-in-out-cubic;
        transition-delay: 0.2s;

        .animated & {
          transform: skewX(13deg) skewY(-13deg) translateY(0);
        }
      }
    }

    // #c4c90f
    &::after {
      background: #bfc40f;
      height: 150px;
      opacity: 0.5;
      transform: skewY(8deg);
      transform-origin: bottom left;
      z-index: 2;

      @include mq(bp-md) {
        height: 330px;
        transform: skewX(13deg) skewY(13deg) translateY(100%);
        transition: transform 0.6s animation.$ease-in-out-cubic;
        transition-delay: 0.1s;

        .animated & {
          transform: skewX(13deg) skewY(13deg) translateY(0);
        }
      }
    }

    &-inner {
      position: relative;
      z-index: 3;

      @include mq(bp-md) {
        transform: skewX(13deg);
        transform-origin: top right;

        .ds-banner-skew--reverse & {
          transform: skewX(-13deg);
          transform-origin: top right;
        }
      }
    }
  }

  &__logos {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 28px;

    img {
      display: inline-block;
      vertical-align: super;
      margin-right: 12px;
      max-height: 48px;

      @media (min-width: bp-md) {
        margin-right: 28px;
        max-height: 34px;
      }
    }
  }

  &__logo {
    padding-bottom: 10px;
    width: 145px;
    height: auto;
  }

  &__logo-with-text {
    display: inline-flex;
    align-self: center;
    align-items: center;
    margin-right: 12px;

    @media (min-width: bp-md) {
      margin-right: 28px;
    }

    img {
      margin-right: 7px;
      height: 100%;
    }

    strong {
      @include fonts.lato-bold();
      color: #595b60;
      font-size: 25px;
      text-transform: uppercase;
    }
  }

  &__title {
    @include animations.fade-up($mode: parent);

    display: block;
    color: brand.$grey-20;
    @include fonts.lato-bold();
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 20px;
    max-width: 190px;
    padding: 0;
    text-transform: none;
    will-change: transform;

    @include mq(bp-md) {
      font-size: 36px;
      line-height: 36px;
      max-width: 453px;
    }

    @include mq(bp-lg) {
      font-size: 36px;
      line-height: 36px;
      max-width: 453px;
    }

    .ds-banner-skew--instrument & {
      font-size: 20px;
      color: brand.$white;
      margin-bottom: 6px;
      max-width: none;
    }
  }

  &__tag {
    opacity: 0;
    position: relative;
    left: -160px;
    padding: 10px 10px 10px 160px;
    display: inline-block;
    @include fonts.lato-black-italic();
    font-size: 34px;
    line-height: 34px;
    text-transform: uppercase;
    background: brand.$grey-40;
    color: brand.$brand-cddb00;
    transform: skew(-13deg);
    margin-bottom: 30px;
    transition: opacity 0.6s animation.$ease-in-out-cubic;

    @include mq(bp-md) {
      font-size: 58px;
      line-height: 58px;
    }

    .animated & {
      opacity: 1;
    }

    strong {
      display: block;
      transform: skew(13deg);
    }
  }

  &__word-break {
    @include animations.fade-up($mode: parent);

    .ds-banner-skew--instrument & {
      @include fonts.lato();
      font-size: 60px;
      line-height: 60px;
      color: brand.$grey-35;

      @include mq(bp-md) {
        font-size: 70px;
        line-height: 70px;
      }
    }
  }

  &__hr-line {
    position: relative;
    left: -380px;
    margin: 32px 0 42px;
    height: 5px;
    background: linear-gradient(to right, brand.$white 75%, transparent);
    border: none;
    width: 760px;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 500ms animation.$ease-in-out-cubic 300ms;

    .animated & {
      transform: scaleX(1);
    }
  }

  &__content {
    @include animations.fade-up($mode: parent, $delay: 0.2s);

    color: brand.$grey-20;
    font-size: 16px;
    line-height: 1.5em;
    margin: 0 0 32px;
    will-change: transform;

    @include mq(bp-md) {
      font-size: 18px;
    }
  }

  &__cta-wrapper {
    @include animations.fade-up($mode: parent, $delay: 0.4s);
  }

  &__caption {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    color: brand.$grey-max-power;
    @include fonts.lato-bold();
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    opacity: 0;
    transform: translateY(-100%) translateX(-20px);
    transition: opacity 0.6s animation.$ease-in-out-cubic,
      transform 0.6s animation.$ease-in-out-cubic;

    .animated & {
      opacity: 1;
      transform: translateY(-100%) translateX(0);
    }

    @include mq(bp-md) {
      transform: translateY(-100%) translateY(-10px) translateX(-20px);

      .animated & {
        opacity: 1;
        transform: translateY(-100%) translateY(-10px) translateX(0);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      display: block;
      width: 100vw;
      height: 1px;
      background: brand.$grey-max-power;
      transform: translate(-16px, -50%);
    }
  }

  &__right {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex: 1 1 45%;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0;

    @include mq(bp-md) {
      display: block;
      padding: 0;
      width: 45%;

      .ds-banner-skew--instrument & {
        flex: 1 1 60%;
        width: 60%;
      }
    }

    @include mq(bp-lg) {
      .ds-banner-skew--instrument & {
        flex: 1 1 70%;
        width: 70%;
      }
    }
  }

  &__waves {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 499px;
    height: 436px;
    z-index: 1;
    transform: translate(0, -50%);

    @include mq(bp-md) {
      position: absolute;
      top: 0;
      right: 0;
      width: 1013px;
      height: 885px;
      transform: none;
    }
  }

  &--media-single {
    .ds-banner-skew__image {
      display: block;
      width: 100%;
      height: auto;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        object-position: center left;
      }

      @include mq(bp-md) {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% + 130px);
        height: 100%;
      }

      @include mq(bp-lg) {
        width: calc(100% + 170px);
      }
    }

    &.ds-banner-skew--instrument .ds-banner-skew__image {
      width: 100%;
    }

    &.ds-banner-skew--reverse {
      .ds-banner-skew__image {
        left: 0;
        right: auto;
      }
    }
  }

  &--media-multiple {
    .ds-banner-skew__right {
      padding: 30px 0 20px;
    }

    .ds-banner-skew__image {
      position: relative;
      display: inline-block;
      flex: 0 0 27%;
      margin: 0;
      margin-right: -16px;
      transform: translateX(-8px);

      @include mq(bp-md) {
        opacity: 0;
      }

      @include mq(bp-lg) {
        opacity: 0;
      }

      img {
        position: relative;
        display: block;
        max-width: 100%;
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 15%;
        border-radius: 50%;
        background: color.change(#414141, $alpha: 0.5);
        filter: blur(8px);
        transform: translate(0, 8px);
        z-index: 1;
      }

      @include mq(bp-md) {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        margin: 0 20px 10px 0;
        max-width: $width-md;
      }

      @include mq(bp-lg) {
        margin: 0 40px 20px 0;
        max-width: $width-lg;
      }
    }
  }
}

.ds-banner-skew--media-single {
  .ds-banner-skew__image img {
    filter: grayscale(1) brightness(1.5);
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: filter 0.6s animation.$ease-in-out-cubic 0.2s,
      opacity 0.6s animation.$ease-in-out-cubic 0s;
    width: 100%;
    will-change: filter;
  }

  &.animated .ds-banner-skew__image img {
    filter: grayscale(0) brightness(1);
    opacity: 1;
  }
}

$max: 4;

@for $i from 1 through $max {
  $transform-md: translateX(-$width-md * ($i - 1))
    translateY(-$step-md * ($i - 1))
    translateX($overlap-md * ($i - 1));
  $transform-lg: translateX(-$width-lg * ($i - 1))
    translateY(-$step-lg * ($i - 1))
    translateX($overlap-lg * ($i - 1));

  @keyframes banner-skew-image-#{$i}-md {
    to {
      opacity: 1;
      transform: $transform-md;
    }
  }

  @keyframes banner-skew-image-#{$i}-lg {
    to {
      opacity: 1;
      transform: $transform-lg;
    }
  }

  .ds-banner-skew--media-multiple {
    .ds-banner-skew__image {
      &:nth-of-type(#{$i}) {
        z-index: $z-base + ($max - $i + 1);
      }

      @include mq(bp-md) {
        &:nth-of-type(#{$i}) {
          transform: translateY(20px) $transform-md;
        }
      }

      @include mq(bp-lg) {
        &:nth-of-type(#{$i}) {
          transform: translateY(20px) $transform-lg;
        }
      }
    }

    &.animated {
      .ds-banner-skew__image {
        @include mq(bp-md) {
          &:nth-of-type(#{$i}) {
            animation: banner-skew-image-#{$i}-md 0.6s animation.$ease-in-out-cubic forwards;
            animation-delay: 0.15s * $i;
          }
        }

        @include mq(bp-lg) {
          &:nth-of-type(#{$i}) {
            animation: banner-skew-image-#{$i}-lg 0.6s animation.$ease-in-out-cubic forwards;
            animation-delay: 0.15s * $i;
          }
        }
      }
    }
  }
}
