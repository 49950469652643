@use '~sass-mq/mq' as *;
@use './settings/animation';
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.fs-runtime {
  background: url('~@img/carpet-mask-bg.jpg') no-repeat top center;
  background-size: cover;
  background-attachment: fixed;
  padding: global.vr(6) 0;

  @include mq(bp-sm) {
    padding: global.vr(13) 0;
  }

  &__container {
    display: flex;
    flex-direction: column;

    @include mq(bp-md) {
      flex-direction: row;
    }
  }

  &__title {
    text-align: center;
    margin-top: 20px;
    
    &--header {
      font-size: 32px;
      margin-bottom: 15px;
    }
  }

  &__inner {
    max-width: 715px;
    width: 100%;
    margin: 0 auto;

      @include mq($until: bp-xs) {
        margin-bottom: 24px;
      }
  }

  &__chart {
    position: relative;
    padding-top: global.vr(3);
    margin-left: 22px;

    @include mq(bp-xs) {
      margin-left: 55px;
    }

    @include mq(bp-md) {
      padding-top: global.vr(11);
    }

    &-label {
      display: inline-flex;
      align-items: center;
      justify-items: center;
      position: absolute;
      top: 50%;
      left: -53px;
      height: 24px;
      background: brand.$green;
      padding: global.vr(0.25) global.vr(1) 0;
      font-size: 10px;
      @include fonts.lato-bold;
      transform: rotateZ(-90deg);

      @include mq(bp-xs) {
        left: -90px;
        height: 35px;
        font-size: 14px;
        padding: global.vr(0.25) global.vr(2) 0;
      }

      @include mq(bp-md) {
        font-size: 16px;
      }
    }

    &-long-label {
      display: inline-flex;
      align-items: center;
      justify-items: center;
      position: absolute;
      top: 50%;
      left: -75px;
      height: 24px;
      background: brand.$green;
      padding: global.vr(0.25) global.vr(1) 0;
      font-size: 10px;
      @include fonts.lato-bold;
      transform: rotateZ(-90deg);

      @include mq(bp-xs) {
        left: -115px;
        height: 35px;
        font-size: 14px;
        padding: global.vr(0.25) global.vr(2) 0;
      }

      @include mq(bp-md) {
        font-size: 16px;
      }
    }

    &-grid {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-end;
      min-height: 430px;
      padding-bottom: global.vr(5);
    }

    &-bars {
      position: relative;
      z-index: layers.$layer-default;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      width: calc(90% + 15px);
      padding-right: 16px;
      overflow: hidden;
      @include mq(bp-md) {
        width: calc(90% + 17px);

        &--wide {
          width: 90%;
        }
      }
    }

    &-runtime {
      position: relative;
      width: 100%;
      margin-bottom: global.vr(1);
      background: url('~@img/carpet-mask-bar.jpg') no-repeat left;
      height: 44px;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          .fs-runtime__chart-logos {
            transition-delay: 1.7s + ($i * 50ms);
          }

          .fs-runtime__chart-time {
            transition-delay: 1.7s + ($i * 50ms);
          }
        }
      }

      &--product {
        position: relative;
        width: 78px;
        transition: width 1s animation.$ease-in-out-cubic 1s;
        will-change: width;

        @include mq(bp-md) {
          width: 144px;
        }

        &-stick-vac {
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -20px;
            background: url('~@img/R18SV7-chart-use.png') no-repeat right center;
            background-size: 99px 50px;
            height: 50px;
            width: calc(100% + 20px);

            @include mq(bp-md) {
              background-size: 166px 84px;
              height: 84px;
            }
          }
        }

        &-debris-sweeper {
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -20px;
            background: url('~@img/debris-sweeper-chart.png') no-repeat right center;
            background-size: 54px 44px;
            height: 50px;
            width: calc(100% + 20px);

            @include mq(bp-md) {
              background-size: 72px 62px;
              height: 84px;
            }
          }
        }

        .fs-runtime__chart-time {
          left: auto;
          right: 0;
        }
      }

      @include mq(bp-md) {
        height: 70px;
      }
    }

    &-logos {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      min-width: 280px;
      padding-left: global.vr(0.5);
      opacity: 0;
      transition: opacity 300ms animation.$ease-in-out-cubic;

      @include mq(bp-xxs) {
        padding-left: global.vr(1.25);
      }

      .fs-runtime__chart.animated & {
        opacity: 1;
      }

      img {
        margin-right: global.vr(0.75);
        max-height: 24px;

        @include mq(bp-xxs) {
          max-height: 30px;
          margin-right: global.vr(1.5);
        }

        @include mq(bp-md) {
          max-height: 60px;
        }
      }
    }

    &-time {
      position: absolute;
      top: 0;
      left: 100%;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 140px;
      padding-left: global.vr(2);
      font-size: 12px;
      @include fonts.lato-bold;
      opacity: 0;
      transform: translateX(-40px);
      transition: opacity 300ms animation.$ease-in-out-cubic,
        transform 300ms animation.$ease-in-out-cubic;
      will-change: transform;

      @include mq(bp-xs) {
        font-size: 16px;
      }

      .fs-runtime__chart.animated & {
        opacity: 1;
        transform: translateX(0);
      }
    }

    &-list {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width: 100%;
      height: 100%;
    }

    &-number {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      position: relative;
      width: calc(100% / 8);
      flex-basis: calc(100% / 8);

      span {
        font-size: 12px;
        @include fonts.lato-bold;
        transform: translateX(-40%);

        @include mq(bp-xs) {
          font-size: 16px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - #{global.vr(5)});
        width: 1px;
        border-right: 1px solid brand.$grey-60;
      }
    }
  }

  &__support-copy {
    max-width: 424px;
    margin: 0 auto;
    text-align: center;
    margin-top: global.vr(3);

    strong {
      display: inline-flex;
      align-items: center;
      justify-items: center;
      height: 35px;
      background: brand.$green;
      padding: global.vr(0.25) global.vr(2) 0;
      margin-bottom: global.vr(1.5);
      font-size: 14px;
      text-transform: uppercase;
      @include fonts.lato-bold;

      @include mq(bp-md) {
        font-size: 16px;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      @include fonts.lato-bold;
    }
  }
}
