@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.mp-intellicell {
  position: relative;
  display: block;
  background: brand.$grey-max-power;
  color: brand.$white;
  overflow: hidden;
  padding: global.vr(4) 0px global.vr(4);

  @include mq($from: bp-sm) {
    padding: global.vr(12) 0 global.vr(12);
  }

  &.animated {
    #mp-intellicell-battery {
      #lines polygon {
        animation: animate-brain 3000ms 0ms ease forwards;

        @media all and (-ms-high-contrast: none) {
          animation: none;
        }

        .browser-edge & {
          animation: none;
        }

        @keyframes animate-brain {
          100% {
            stroke-dashoffset: 0;
          }
        }
      }
      #dots path,
      #lines path {
        animation: fade-dots 500ms 500ms ease forwards;

        @media all and (-ms-high-contrast: none) {
          animation: none;
        }

        @include mq($until: bp-sm) {
          animation: none;
        }

        .browser-edge & {
          animation: none;
        }

        @keyframes fade-dots {
          100% {
            opacity: 1;
          }
        }
      }

      #text polygon,
      #text rect,
      #text g {
        animation: fade-text 500ms 1000ms ease forwards;

        @media all and (-ms-high-contrast: none) {
          animation: none;
        }
        @include mq($until: bp-sm) {
          animation: none;
        }

        .browser-edge & {
          animation: none;
        }

        @keyframes fade-text {
          100% {
            opacity: 1;
          }
        }
      }
    }
  }

  &__grid-container {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;

    @supports (display: grid) {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(12, 1fr);
    }

    @include mq(bp-sm) {
      display: flex;
      flex-direction: row;

      @supports (display: grid) {
        display: grid;
        gap: 28px;
        grid-template-columns: repeat(12, 1fr);
      }
    }
  }

  &__grid-column {
    min-width: auto;
    text-align: center;

    &:nth-child(1) {
      position: relative;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;

      @supports (display: grid) {
        grid-column: 1 / span 12;
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: center;

      @supports (display: grid) {
        grid-column: 1 / span 12;
        margin-left: 0;
      }
    }

    @include mq(bp-sm) {
      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(2) {
        text-align: right;
      }
    }

    @include mq(bp-sm) {
      &:nth-child(1) {
        flex-basis: percentage(1/2);
        margin-right: 14px;

        @supports (display: grid) {
          grid-column: 2 / span 5;
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        flex-basis: percentage(1/2);
        margin-left: 14px;

        @supports (display: grid) {
          grid-column: 7 / span 5;
          margin-left: 0;
        }
      }
    }
  }

  &__edge {
    position: absolute;
    top: 50%;
    display: none;
    height: auto;
    opacity: 0.25;
    width: 1000px;
    z-index: 1;

    @include mq(bp-sm) {
      display: block;
    }

    &:nth-of-type(1) {
      left: 0;
      transform: translateY(-50%) translateX(-80%);
    }

    &:nth-of-type(2) {
      right: 0;
      transform: translateY(-50%) translateX(80%);
    }
  }

  &__title {
    @include fonts.lato-semi-bold();
    font-size: 18px;
    @include fonts.ls(-10);
    line-height: 25px;
    margin: 0 auto global.vr(2);
    max-width: 400px;
    text-transform: uppercase;
  }

  &__subtitle {
    @include fonts.lato-black();
    font-size: 34px;
    @include fonts.ls(-10);
    line-height: 42px;
    margin: 0 auto global.vr(2);
    max-width: 400px;
    text-transform: uppercase;
  }

  &__content {
    @include fonts.lato-regular();
    font-size: 16px;
    @include fonts.ls(20);
    line-height: 26px;
    margin: 0 auto;
    max-width: 400px;
  }

  #mp-intellicell-battery {
    #lines polygon {
      @media all and (-ms-high-contrast: none) {
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
        stroke: brand.$green;
        fill-opacity: 1;
        stroke-width: 0.2px;
      }

      @include mq($until: bp-sm) {
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
        fill-opacity: 1;
        stroke: none;
        stroke-width: 0.2px;
      }

      stroke-dasharray: 1500;
      stroke-dashoffset: 1500;
      stroke: brand.$green;
      stroke-width: 0.2px;
      fill-opacity: 0;
    }

    #dots path,
    #lines path {
      opacity: 0;
      @media all and (-ms-high-contrast: none) {
        opacity: 1;
      }

      @include mq($until: bp-sm) {
        opacity: 1;
      }
    }

    #text polygon,
    #text rect,
    #text g {
      opacity: 0;

      @media all and (-ms-high-contrast: none) {
        opacity: 1;
      }

      @include mq($until: bp-sm) {
        opacity: 1;
      }

      .browser-edge & {
        opacity: 1;
      }
    }
  }
}
