@use '~sass-mq/mq' as *;
@use '../settings/brand';
@use '../helpers/fonts';
@use '../helpers/global';
@use '../helpers/animations';

.support-signpost {
  background-color: brand.$brand-primary;

  &__container {
    padding: global.vr(10) global.vr(2);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @include fonts.lato-bold();
    font-size: 36px;
    color: brand.$grey-35;
    padding-bottom: global.vr(3);
    text-align: center;

    @include animations.fade-up();
  }

  &__content {
    font-size: 16px;
    color: brand.$grey-35;
    text-align: center;
    max-width: 600px;
    padding-bottom: global.vr(6);

    @include animations.fade-up();
  }

  &__items {
    display: flex;

    @include mq($until: bp-sm) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 global.vr(6);

    @include animations.fade-up();

    @include mq($until: bp-sm) {
      padding: global.vr(4) global.vr(2);
    }

    &--image {
      padding-bottom: global.vr(2);
    }

    &--title {
      @include fonts.lato-bold();
      font-size: 24px;
      color: brand.$grey-35;
      padding-bottom: global.vr(2);
      text-align: center;
    }

    &--content {
      font-size: 16px;
      color: brand.$grey-35;
      text-align: center;
      padding-bottom: global.vr(2);
      max-width: 250px;

      @include mq($until: bp-xxs) {
        max-width: 100%;
      }
    }

    &:nth-child(1),
    &:nth-child(3) {
      @include animations.fade-up();
    }

    &:nth-child(2) {
      @include animations.fade-up(0.3s);
    }
  }
}
