@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use './settings/animation';
@use './settings/brand';
@use './settings/breakpoints' as *;

.ds-tabbed-tips {
  padding-top: global.vr(8);
  padding-bottom: global.vr(8);
  &__content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    @include animations.fade-up($mode: parent, $delay: 0.2s);

    svg,
    img {
      width: 54px;
      height: 50px;
    }
  }

  &__content-title {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-black();
    font-size: 32px;
    line-height: 34px;
    color: brand.$grey-max-power;
    padding: global.vr(3) 0 global.vr(5);
    text-align: center;
  }

  &__content {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-regular();
    font-size: 18px;
    line-height: 28px;
    color: brand.$grey-max-power;
    padding-bottom: global.vr(5);
    max-width: 820px;
    text-align: center;
  }

  &__category-container {
    overflow: hidden;
    position: sticky;
    top: 30px;
  }

  &__category {
    display: flex;
    align-items: center;
    width: 0%;
    height: 70px;
    padding: 0 global.vr(4) 0 global.vr(8);
    margin: 0 global.vr(6) global.vr(2) global.vr(-3);
    transform: skewX(-20deg);
    background: #54585a;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.7;
    // all is used due to various properties changing at different times.
    transition: all 0.3s animation.$ease-in-out-cubic;

    @include mq($until: bp-xs) {
      padding: 0 global.vr(4) 0 global.vr(4);
      height: 46px;
    }

    .animated & {
      width: 90%;

      .ds-tabbed-tips__category-title,
      .ds-tabbed-tips__category-icon {
        opacity: 1;
      }

      &.is-active {
        width: 100%;
        height: 90px;
        opacity: 1;

        @include mq($until: bp-xs) {
          height: 58px;
        }

        .ds-tabbed-tips__category-icon {
          padding-left: global.vr(2);
          img {
            width: 62px;
            height: 57px;
            min-width: 62px;
            min-height: 57px;

            @include mq($until: bp-xs) {
              width: 40px;
              height: 37px;
              min-width: 40px;
              min-height: 37px;
            }
          }
        }
      }
    }

    &-title {
      @include fonts.lato-bold();
      color: brand.$white;
      font-size: 22px;
      line-height: 28px;
      transform: skewX(20deg);
      opacity: 0;
      transition: opacity 0.3s animation.$ease-in-out-cubic;

      @include mq($until: bp-xs) {
        font-size: 11px;
        line-height: 14px;
      }
    }

    &-icon {
      transform: skewX(20deg);
      margin-right: global.vr(5);
      transition: all 0.3s animation.$ease-in-out-cubic;
      opacity: 0;

      @include mq($until: bp-xs) {
        margin-right: global.vr(2);
      }

      img {
        width: 55px;
        height: 50px;
        min-width: 55px;
        min-height: 50px;
        transition: all 0.3s animation.$ease-in-out-cubic;

        @include mq($until: bp-xs) {
          width: 35px;
          height: 32px;
          min-width: 35px;
          min-height: 32px;
        }
      }
    }
  }

  &__right-container {
    @include animations.fade-left($mode: self, $delay: 0.2s);

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  }

  &__item-container {
    @supports (display: grid) {
      grid-column: 1;
      grid-row: 1;
    }
    opacity: 0;

    &.is-active {
      animation-name: itemAnimation;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;

      .ds-tabbed-tips__item {
        animation-name: itemAnimation;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;

        max-height: 100%;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: start;
    margin: 0 0 global.vr(2);
    max-height: 0;

    &-icon {
      position: relative;
      padding-right: global.vr(3);
      svg {
        width: 77px;
        height: 77px;
        border-radius: 50%;
      }

      img {
        width: 100%;
        max-width: 77px;
      }
    }

    &-content-container {
      max-width: 396px;
    }

    &-title {
      @include fonts.lato-black();
      font-size: 21px;
      line-height: 26px;
      color: brand.$grey-max-power;
      padding-bottom: global.vr(1);
    }

    &-content {
      @include fonts.lato-regular();
      font-size: 16px;
      line-height: 24px;
      color: brand.$grey-max-power;
    }
  }
}

@keyframes itemAnimation {
  0% {
    opacity: 0;
    max-height: 0;
  }
  50% {
    max-height: 100%;
  }
  100% {
    opacity: 1;
  }
}
