@use "../helpers/fonts";
@use "../helpers/global";
@use "../settings/animation";
@use "../settings/brand";

.ds-product-summary {
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid #cddb00;
  padding: global.vr(1) global.vr(2) global.vr(1) global.vr(1);
  justify-content: center;
  margin: 0 0 global.vr(4);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #cddb00;
    height: 100%;
    opacity: 0;
    transform: scale(1, 1);
    width: 100%;
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: brand.$grey-95;
    height: 100%;
    opacity: 0;
    transform: scale(0, 1);
    transform-origin: left center;
    transition: opacity 0.15s animation.$ease-in-out-cubic,
      transform 0.3s animation.$ease-in-out-cubic;
    width: 100%;
    z-index: -1;
  }

  &__image-wrapper {
    display: flex;
    flex-direction: column;
    height: 60px;
    margin: 0 global.vr(3) 0 0;
    width: 60px;
  }

  &__image {
    display: block;
    font-family: 'object-fit: contain; object-position: center center;';
    height: 100%;
    object-fit: contain;
    object-position: center center;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    @include fonts.lato-regular();
    display: block;
    color: #313131;
    font-size: 16px;
    line-height: 1.1em;
    margin: 0 0 global.vr(1);
    text-transform: uppercase;
  }

  &__subtitle {
    @include fonts.lato-regular();
    display: block;
    color: #979797;
    font-size: 15px;
    line-height: 1.1em;
    margin: 0;
    text-transform: uppercase;
  }

  &:focus,
  &:hover {
    text-decoration: none;

    &::before {
      animation: pulse;
      animation-direction: normal;
      animation-duration: 0.6s;
      animation-fill-mode: forwards;
    }

    &::after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1, 1);
  }
  5% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: scale(1.1, 1.4);
  }
}
