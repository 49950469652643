@use './settings/breakpoints' as *;
@use './settings/brand' as brand;
@use './helpers/fonts';

.ds-content-strip {
  background: brand.$green;
  overflow: hidden;

  @include mq($until: bp-sm) {
    .ds-site-wide {
      padding: 0;
    }
  }

  @include mq($from: bp-sm) {
    background: linear-gradient(to right, brand.$grey-35 30%, brand.$green 30%);
  }

  &.animated {
    .ds-content-strip__shard {
      &:after {
        transform: rotateZ(0deg);
      }

      img {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .ds-content-strip__content {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__inner {
    @include mq($from: bp-sm) {
      display: flex;
      justify-content: center;
      text-align: left;
    }
  }

  &__shard {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 18px 22px;
    width: 100%;
    height: inherit;
    background: brand.$grey-35;

    @include mq($from: bp-sm) {
      max-width: 440px;
      justify-content: flex-start;
      width: 208px;
      padding-left: 12px;
    }

    &:after {
      content: ' ';
      display: none;
      position: absolute;
      top: 0;
      right: -116px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 200px 0 0 116px;
      border-color: transparent transparent transparent brand.$grey-35;
      transition: transform 0.5s ease-in 0.3s;

      @include mq($from: bp-sm) {
        display: block;
        transform-origin: 0 0;
        transform: rotateZ(45deg);
      }

      @include mq($from: bp-lg) {
        border-width: 166px 0 0 166px;
        right: -196px;
      }
    }

    img {
      position: relative;
      z-index: 1;
      margin-right: 10px;
      width: auto;
      max-height: 121px;
      opacity: 0;
      transform: translateX(-30px);
      transition: transform 0.5s ease-in, opacity 0.5s ease-in;

      @include mq($from: bp-sm) {
        margin-right: 70px;
      }

      @include mq($from: bp-lg) {
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__content {
    padding: 30px 24px;
    height: inherit;
    opacity: 0;
    transform: translateX(20px);
    transition: transform 0.5s ease-in 0.4s, opacity 0.5s ease-in 0.4s;

    @include mq($from: bp-sm) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 12px 20px 18px 120px;
    }

    @include mq($from: bp-lg) {
      padding: 8px 20px 8px 216px;
      flex: 1;
    }

    strong {
      display: block;
      width: 100%;
      color: brand.$black;
      margin-bottom: 6px;
      font-size: 20px;
    }

    p {
      color: brand.$black;
      font-size: 12px;
      margin: 0;
    }
  }
}
