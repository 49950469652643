@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-card--article {
  position: relative;
  cursor: pointer;

  .ds-card__media {
    position: relative;
    border-bottom: 4px solid brand.$green;
    margin-bottom: global.vr(2);
    overflow: hidden;
    padding-bottom: percentage(9 / 16);
    z-index: 1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';
      transform: scale(1);
      transition: transform 0.6s animation.$ease-in-out-cubic;
      width: 100%;
    }
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}
