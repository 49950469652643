@use './helpers/fonts';
@use './helpers/global';

@use './settings/breakpoints' as *;
@use './settings/brand';

.ds-form {
  overflow: hidden;

  &__title {
    @include fonts.lato-bold();
    font-size: 26px;
    line-height: 32px;
    margin-bottom: global.vr(4);
    text-transform: uppercase;
  }

  &__content {
    @include fonts.lato-bold();
    font-size: 16px;
    @include fonts.ls(50);
    line-height: 19px;
    margin-bottom: global.vr(4);

    a {
      color: brand.$green;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__field {
    width: 100%;
    margin-bottom: 18px;
    font-size: 0;

    + small {
      margin-top: -18px;
      margin-bottom: 18px;
    }

    &--horizontal {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;

      @include mq(bp-sm) {
        justify-content: flex-start;
      }
    }

    &--file {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;

      @include mq(bp-sm) {
        flex-wrap: nowrap;
      }

      .ds-btn {
        cursor: pointer;
        flex: 1 1 auto;
        margin-right: 42px;
        margin-bottom: global.vr(1);

        @include mq(bp-sm) {
          flex: 0 0 auto;
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
    }

    &--no-padding ul {
      padding: 0;
    }

    &--image {
      min-height: global.vr(4);
    }

    &--error {
      position: relative;

      &:not(.no-bg) {
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          right: 14px;
          display: block;
          background: url('~@img/icons/icon-error.svg') no-repeat center;
          height: 45px;
          pointer-events: none;
          width: 16px;
        }
      }

      .ds-form__label {
        color: brand.$error;
      }

      input:not([type='button']).ds-form__control,
      textarea.ds-form__control,
      select.ds-form__control {
        padding-right: 42px;
        // overwrite legacy !important
        border-color: brand.$error !important;

        &:focus {
          // overwrite legacy !important
          border-color: brand.$error !important;
        }
      }

      &.ds-form__field--checkbox,
      &.ds-form__field--radio {
        &::before {
          height: 100%;
        }

        .ds-form__label::before {
          // overwrite legacy !important
          border-color: brand.$error !important;
        }
      }

      &.ds-form__field--file {
        &::before {
          top: 0;
          bottom: auto;
          height: 47px;
        }
      }
    }
  }

  &__label {
    display: block;
    color: brand.$black;
    @include fonts.lato-regular();
    font-size: 13px;
    @include fonts.ls(200);
    line-height: 17px;
    margin-bottom: 10px;
    text-transform: uppercase;

    .ds-form__field--horizontal & {
      flex: 1 0 auto;
      margin-bottom: 0;
      margin-right: 10px;
      max-width: 120px;
    }

    .ds-form__field--checkbox &,
    .ds-form__field--radio & {
      font-size: 14px;
      letter-spacing: normal;
      text-transform: none;
    }
  }

  &__label-container {
    display: flex;
    align-items: center;
  }

  &__tooltip {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: brand.$green;
    margin-left: 10px;
    margin-bottom: 10px;

    &:before {
      content: '?';
      font-size: 18px;
      line-height: 18px;
      @include fonts.lato-bold();
      color: brand.$grey-max-power;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__caption {
    display: block;
    color: brand.$grey-20;
    @include fonts.lato-regular();
    font-size: 11px;
    @include fonts.ls(50);
    line-height: 1em;
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: none;
  }

  &__control {
    border: 1px solid brand.$grey-85;
    appearance: none;
    border-radius: 0;
    font-size: 12px;
    color: brand.$black;
    @include fonts.lato-regular();
    transition: border-color 300ms ease, background-color 300ms ease;

    &::placeholder {
      color: brand.$grey-65;
    }
    // overwrite legacy !important
    &:focus {
      border-color: brand.$green !important;
      background-color: brand.$grey-95;

      &::placeholder {
        color: brand.$grey-55;
      }
    }
    .ds-form__field--horizontal & {
      flex: 1 1 auto;
      max-width: 300px;
    }
  }

  &__file-name {
    display: inline-block;
    flex: 1 1 100%;
    font-size: 12px;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mq(bp-sm) {
      flex: 0 1 auto;
    }
  }

  &__previews {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:after,
    img {
      display: inline-block;
      background: brand.$grey-95;
      border: 1px solid brand.$grey-85;
      flex: 0 0 100px;
      height: 100px;
      margin-bottom: global.vr(1);
      margin-right: global.vr(1);
      width: 100px;

      &:last-child {
        margin-right: 0;
      }
    }

    &:after {
      content: '';
      background: brand.$grey-95 url('~@img/icons/icon-plus.svg') no-repeat center center;
      background-size: 25px 25px;
    }

    &.ds-form__error::after {
      border: 1px solid brand.$error;
    }

    img {
      object-fit: cover;
      object-position: center center;
    }
  }

  .ds-form-split {
    @include mq($from: bp-xs) {
      display: flex;
      flex-direction: row;

      &__50 {
        flex: 1 1 50%;

        &:nth-child(1) {
          padding-right: 8px;
        }

        &:nth-child(2) {
          padding-left: 8px;
        }
      }
    }

    small {
      display: block;
      font-size: 11px;
      color: brand.$grey-30;
      @include fonts.lato-regular-italic();
    }
  }

  &__error-message {
    color: brand.$error;
  }

  &__error {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      right: 14px;
      content: ' ';
      display: block;
      width: 16px;
      height: 45px;
      background: url('~@img/icons/icon-error.svg') no-repeat center;
    }

    input:not([type='button']).ds-form__control,
    textarea.ds-form__control,
    select.ds-form__control {
      padding-right: 42px;

      // overwrite legacy !important
      border-color: brand.$error !important;

      &:focus {
        // overwrite legacy !important
        border-color: brand.$error !important;
      }
    }
  }

  &__select-container {
    position: relative;

    &:after {
      content: '\f078';
      font-size: 18px;
      position: absolute;
      font-family: 'FontAwesome';
      top: 50%;
      right: 17px;
      transform: translate(50%, -50%);
      color: brand.$brand-primary;

      @include mq($until: bp-xs) {
        display: none;
      }
    }
  }
}

// form elements
input[type='color'].ds-form__control,
input[type='date'].ds-form__control,
input[type='datetime'].ds-form__control,
input[type='datetime-local'].ds-form__control,
input[type='email'].ds-form__control,
input[type='month'].ds-form__control,
input[type='number'].ds-form__control,
input[type='password'].ds-form__control,
input[type='search'].ds-form__control,
input[type='tel'].ds-form__control,
input[type='text'].ds-form__control,
input[type='time'].ds-form__control,
input[type='url'].ds-form__control,
input[type='week'].ds-form__control,
input:not([type]).ds-form__control {
  display: block;
  height: 45px;
  width: 100%;
  padding: 0 12px;
}

select.ds-form__control {
  display: block;
  height: 45px;
  width: 100%;
  padding: 0 30px 0 12px;
  background: url('~@img/icons/icon-angle-down.svg') no-repeat right 10px center;

  .ds-form__select-container & {
    background: none;
  }
}

textarea.ds-form__control {
  display: block;
  height: 94px;
  width: 100%;
  padding: 12px;
}

input[type='checkbox'].ds-form__control {
  display: none;

  + label {
    cursor: pointer;
    position: relative;
    padding-left: 34px;
    font-size: 12px;
    line-height: 20px;
    color: brand.$grey-20;
    @include fonts.lato-regular();

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid brand.$green;
      background-color: brand.$white;
    }
  }

  &:checked + label:before {
    background-color: brand.$green;
    background-image: url('~@img/icons/icon-tick.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  &.has-error + label:before {
    border: 1px solid brand.$error !important;
  }
}

input[type='radio'].ds-form__control {
  display: none;

  + label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    min-width: 78px;
    width: auto;
    font-size: 14px;
    background-color: brand.$grey-95;
    border: 1px solid brand.$grey-85;
    opacity: 0.3;
    @include fonts.lato-regular();
    transition: opacity 150ms ease, border-color 150ms ease;

    .ds-form__field--circular-radios & {
      position: relative;
      display: block;
      opacity: 1;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: normal;
      background: none;
      border: none;
      height: auto;
      padding-left: 38px;
      color: brand.$grey-max-power;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -10px;
        left: 0;
        width: 20px;
        height: 20px;
        background-color: brand.$white;
        border: 1px solid brand.$grey-85;
        border-radius: 50%;
      }
    }
  }

  &:checked + label {
    opacity: 1;
    border-color: brand.$green;

    .ds-form__field--circular-radios & {
      border-color: none;

      &:before {
        background: radial-gradient(circle, brand.$black 40%, brand.$white 50%, brand.$white 100%);
      }
    }
  }
}
.hidden-field {
  display: none;
}
