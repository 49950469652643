@use '../settings/fonts';

@mixin culture-font-fallback() {
  .RURU &,
  .HUHU &,
  .PLPL &,
  .CSCZ &,
  .LTLT &,
  .LVLV &,
  .ETEE & {
    font-family: fonts.$font-cultureFallback;
  }
}

// Fonts
@mixin lato() {
  font-family: fonts.$font-family;

  @include culture-font-fallback();
}

@mixin lato-thin() {
  @include lato();
  font-weight: 100;
}

@mixin lato-thin-italic() {
  @include lato-thin();
  font-style: italic;
}

@mixin lato-light() {
  @include lato();
  font-weight: 300;
}

@mixin lato-light-italic() {
  @include lato-light();
  font-style: italic;
}

@mixin lato-regular() {
  @include lato();
  font-weight: 400;
}

@mixin lato-regular-italic() {
  @include lato-regular();
  font-style: italic;
}

@mixin lato-medium() {
  @include lato();
  font-weight: 500;
}

@mixin lato-semi-bold() {
  @include lato();
  font-weight: 600;
}

@mixin lato-bold() {
  @include lato();
  font-weight: 700;
}

@mixin lato-bold-italic() {
  @include lato-bold();
  font-style: italic;
}

@mixin lato-black() {
  @include lato();
  font-weight: 900;
}

@mixin lato-black-italic() {
  @include lato-black();
  font-style: italic;
}

// Typography
@mixin letter-spacing($value) {
  letter-spacing: ($value / 1000) * 1em;
}

@mixin ls($value) {
  @include letter-spacing($value);
}
