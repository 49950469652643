@use 'sass:color';

@use '../helpers/fonts';

@use '../settings/animation';
@use '../settings/brand';

$btn-color-bg: brand.$white;
$btn-color-bg-tint: brand.$grey-95;
$btn-color-text: brand.$grey-20;
$btn-color-shadow: color.change(#000, $alpha: 0.16);

.ds-btn {
  @include brand.bs();
  @include fonts.lato-semi-bold();
  @include fonts.ls(100);
  position: relative;
  display: inline-block;
  margin: 0;
  border: 0;
  padding: 14px 56px 14px 26px;
  background: $btn-color-bg-tint;
  color: $btn-color-text;
  font-size: 15px;
  line-height: 1.2em;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: box-shadow 0.3s animation.$ease-in-out-cubic,
    transform 0.3s animation.$ease-in-out-cubic;
  will-change: transform;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: $btn-color-bg;
    border-radius: 50%;
    transform: translate(0, -50%) translate(-80px, 40px) scale(1);
    transition: transform 0.5s animation.$ease-in-out-cubic;
    z-index: 1;
  }

  &__text {
    position: relative;
    text-rendering: optimizeLegibility;
    transform: translate3d(0, 0, 0);
    will-change: transform;
    z-index: 2;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 16px;
    display: inline-block;
    width: 20px;
    height: 16px;
    transform: translateY(-50%);
    transition: transform 0.3s animation.$ease-in-out-cubic;
    z-index: 2;

    svg {
      display: block;
      width: 100%;
      height: 100%;

      .ds-btn--arrow-down & {
        transform: rotate(90deg);
      }

      .ds-btn--upload & {
        transform: rotateZ(-90deg);
      }
    }

    .ds-btn--icon-first & {
      left: 12px;
      right: auto;
      width: 30px;
      height: 30px;
    }
  }

  &--no-icon {
    padding: 16px;
  }

  &--primary {
    background: #d3e002;
    color: brand.$grey-20;

    &::before {
      background: darken(#d3e002, 2%);
    }

    &:focus,
    &:hover {
      color: brand.$grey-20;
    }
  }

  &:focus,
  &:hover {
    @include brand.bs($expand: true);
    color: $btn-color-text;
    text-decoration: none;
    transform: translate(0, -2px);

    &::before {
      transform: translate(0, -50%) translate(-80px, 40px) scale(1.75);
    }

    .ds-btn__icon {
      transform: translateY(-50%) translateX(4px);
    }
  }

  &--red {
    background: brand.$error;
    color: brand.$white;

    &::before {
      background: darken(brand.$error, 2%);
    }

    &:focus,
    &:hover {
      color: brand.$white;
    }
  }

  &--outline {
    @include brand.ts();
    @include fonts.lato-bold();
    background: none;
    border: 1px solid brand.$white;
    color: brand.$white;
    font-size: 14px;
    letter-spacing: normal;
    line-height: 17px;
    padding: 13px 16px 12px;
    text-transform: none;

    &:focus,
    &:hover {
      color: brand.$white;
    }

    &::before {
      content: none;
    }
  }

  &--icon-first {
    padding: 14px 26px 14px 56px;
  }

  &--spinner {
    cursor: wait;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    filter: brightness(0.8);
    pointer-events: none;

    .ds-btn__fake-text {
      position: relative;
      z-index: 2;
      opacity: 0.35;
      pointer-events: none;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: -15px auto 0;
      width: 30px;
      height: 30px;
    }
  }
}
