@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-card-grid {
  &__filters {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: global.vr(2) 0;
  }

  &__filter {
    display: flex;
    flex-direction: row;

    label {
      margin: 0 global.vr(1) 0 0;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2px;
    margin-right: -2px;

    > * {
      padding-left: 2px;
      padding-right: 2px;
      margin-bottom: 4px;
      flex-basis: calc(#{percentage(1 / 3)} - 4px);

      .ds-card-grid--wider-gap & {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;
        flex-basis: calc(#{percentage(1 / 3)} - 40px);
      }
    }

    @supports (display: grid) {
      display: grid;
      gap: 4px;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;

      .ds-card-grid--wider-gap & {
        gap: 40px;
      }

      > * {
        margin-bottom: 0;
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: global.vr(7) 0;
  }
}
