@use '../helpers/global';
@use '../settings/animation';

.ds-stars {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 0;

  &__item {
    display: inline-block;
    height: 15px;
    width: 16px;

    & + & {
      margin-left: global.vr(0.5);
    }

    @supports (display: grid) {
      animation: ds-stars-animation;
      animation-play-state: paused;
      opacity: 0;
      transform: scale(0.2);

      .animated & {
        animation-direction: normal;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
        animation-play-state: running;
        animation-timing-function: animation.$ease-in-out-cubic;
      }

      @for $var from 1 to 6 {
        &:nth-child(#{$var}) {
          animation-delay: $var * 0.2s;
        }
      }
    }
  }
}

@keyframes ds-stars-animation {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  90% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
