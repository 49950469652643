@use './helpers/animations';
@use './helpers/fonts';
@use './helpers/global';

@use './settings/brand';
@use './settings/breakpoints' as *;

.ds-how-to-5050 {
  &--single-column {
    background-color: brand.$green;

    .ds-grid-column {
      &:nth-child(1) {
        @include mq(bp-sm) {
          grid-column-start: 4;
          grid-column-end: 10;
        }
      }
    }
  }

  &--half {
    .ds-grid-column {
      &:nth-child(1) {
        order: 0;
      }
      &:nth-child(2) {
        order: 1;
      }
    }
  }

  &--half-reverse {
    .ds-grid-column {
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 0;
      }
    }
  }

  .ds-grid-container {
    gap: 0;
  }

  .ds-grid-column {
    &:nth-child(1) {
      background-color: brand.$green;
    }

    &:nth-child(2) {
      background-color: #c9cf0f;
    }
  }

  &--black {
    .ds-grid-column {
      &:nth-child(1) {
        background-color: brand.$green;
      }

      &:nth-child(2) {
        background-color: brand.$grey-max-power;

        .ds-how-to-5050__classification-title,
        .ds-how-to-5050__step-title,
        .ds-how-to-5050__step-content {
          color: brand.$white;
        }
      }
    }
  }

  &--green {
    .ds-grid-column {
      &:nth-child(1) {
        background-color: brand.$green;
      }

      &:nth-child(2) {
        background-color: brand.$green;
      }
    }
  }

  &--dark-green {
    .ds-grid-column {
      &:nth-child(1) {
        background-color: brand.$green;
      }

      &:nth-child(2) {
        background-color: #c9cf0f;
      }
    }
  }

  &__container {
    padding: global.vr(10) global.vr(7);

    @include mq($until: bp-xs) {
      padding: global.vr(5) global.vr(3);
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: global.vr(2);
    background-color: brand.$green;
  }

  &__icon {
    @include animations.fade-up($mode: parent, $delay: 0.2s);

    svg {
      width: 54px;
      height: 50px;
    }
  }

  &__content-title {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-black();
    font-size: 32px;
    line-height: 34px;
    color: brand.$grey-max-power;
    padding: global.vr(3) 0;
  }

  &__content {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    @include fonts.lato-regular();
    font-size: 18px;
    line-height: 28px;
    color: brand.$grey-max-power;
    padding-bottom: global.vr(5);
    max-width: 820px;
    text-align: center;
  }

  &__media-container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    img {
      @include mq(bp-sm) {
        height: 100%;
        object-fit: cover;
        max-height: 820px;
        font-family: 'object-fit: cover;';
        object-position: center left;
      }
    }
  }

  &__title-container {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
    display: flex;
    padding-bottom: global.vr(4);
  }

  &__icon {
    top: 4px;
    position: relative;
    svg {
      width: 36px;
      height: 33px;
    }
  }

  &__title {
    @include fonts.lato-black();
    display: flex;
    align-items: center;
    font-size: 40px;
    line-height: 40px;
    color: brand.$white;
    padding-left: global.vr(2);
    &--black {
      color: brand.$grey-max-power;
    }

    @include mq($until: bp-xs) {
      font-size: 30px;
      line-height: 34px;
    }
  }

  &__classification-container {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
  }

  &__classification-title {
    @include fonts.lato-regular();
    color: brand.$grey-max-power;
    font-size: 16px;
    line-height: 26px;
    padding-bottom: global.vr(2);
  }

  &__classification-items {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: global.vr(3);
  }

  &__classification-item {
    @include fonts.lato-black();
    font-size: 18px;
    color: brand.$grey-max-power;
    background-color: brand.$white;
    padding: global.vr(1) global.vr(3);
    border-radius: 50px;
    margin-bottom: global.vr(2);
    margin-right: global.vr(2);
  }

  &__steps {
  }

  &__step {
    padding-bottom: global.vr(5);
    position: relative;

    &:nth-child(1) {
      @include animations.fade-up($mode: parent, $delay: 0.6s);
    }

    &:nth-child(2) {
      @include animations.fade-up($mode: parent, $delay: 1s);
    }

    &:nth-child(3) {
      @include animations.fade-up($mode: parent, $delay: 1.4s);
    }

    &:nth-child(4) {
      @include animations.fade-up($mode: parent, $delay: 1.8s);
    }

    @include mq($until: bp-xs) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-container {
      display: flex;
      width: 100%;
      align-items: flex-start;
    }

    &-number {
      @include fonts.lato-black();
      font-size: 60px;
      line-height: 50px;
      color: brand.$white;
      padding-right: global.vr(2);

      @include mq($until: bp-xs) {
        font-size: 40px;
        line-height: 40px;
      }
    }

    &-icon {
      padding-top: global.vr(1);
      padding-right: global.vr(2);
    }

    &-inner {
      display: flex;
      flex-direction: column;
    }

    &-title {
      @include fonts.lato-black();
      font-size: 24px;
      line-height: 28px;
      color: brand.$grey-max-power;
      padding-bottom: global.vr(2);

      @include mq($until: bp-xs) {
        font-size: 21px;
        line-height: 26px;
      }

      .ds-how-to-5050__step--media & {
        @include mq(bp-xs) {
          max-width: 360px;
        }
      }
    }

    &-content {
      @include fonts.lato-regular();
      font-size: 16px;
      line-height: 24px;
      color: brand.$grey-max-power;

      .ds-how-to-5050__step--media & {
        @include mq(bp-xs) {
          max-width: 360px;
        }
      }
    }

    &-media-mobile {
      padding-bottom: global.vr(2);
      @include mq(bp-xs) {
        display: none;
      }
      img {
        max-width: 105px;
      }
    }

    &-media-container {
      position: relative;
      right: -30px;

      @include mq($until: bp-xs) {
        display: none;
      }

      img {
        max-height: 124px;
      }
    }
  }

  &__image-title-container {
    display: flex;
    justify-content: space-between;

    @include mq($until: bp-sm) {
      flex-direction: column;
    }
  }

  &__image-title {
    width: 48%;

    @include mq($until: bp-sm) {
      width: 100%;
    }
  }

  &__video-container {
    position: relative;
  }

  &__video {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 100%;

    &.animated {
      opacity: 1;
    }
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out;
    pointer-events: all;
    opacity: 1;

    &.is-playing {
      opacity: 0;
      pointer-events: none;
    }

    svg {
      width: 80px;
      height: 80px;
      fill: brand.$white;
      cursor: pointer;

      &:hover {
        fill: brand.$green;
      }
    }
  }
}
