@use '~sass-mq/mq' as *;
@use './settings/brand';
@use './settings/layers';
@use './helpers/fonts';
@use './helpers/global';

.mp-icons {
  background-color: brand.$grey-max-power;

  &__inner {
    padding: 40px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding: global.vr(5) 0px;
  }

  &__item {
    width: 100%;
    flex: 1 0 auto;
    color: brand.$white;
    text-align: center;
    margin-bottom: global.vr(2);

    @include mq($from: bp-xxs) {
      width: 50%;
      margin-bottom: 0px;

      &:nth-child(-n + 2) {
        margin-bottom: global.vr(5);
      }
    }

    @include mq($from: bp-md) {
      width: 25%;
      margin-bottom: 0px;
    }

    h3 {
      @include fonts.lato-black;
      color: brand.$brand-cddb00;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }

  &__copy {
    max-width: 290px;
    margin: 0 auto;
  }

  &__media {
    margin-bottom: global.vr(2);

    @include mq($from: bp-md) {
      margin-bottom: global.vr(2);
    }

    svg,
    img {
      display: block;
      margin: 0 auto;
      max-height: 50px;
      pointer-events: none;

      @include mq($from: bp-xs) {
        max-height: 75px;
      }

      @include mq($from: bp-md) {
        max-height: 100px;
      }
    }

    &--spring {
      svg {
        transition: transform 375ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transform-origin: 50% 50%;
      }

      &:hover svg {
        transform: scaleX(1.25) scaleY(0.4);
      }
    }

    &--volume {
      .line {
        opacity: 1;

        &--2 {
          transition: opacity 375ms 100ms ease;
        }

        &--3 {
          transition: opacity 375ms 300ms ease;
        }
      }

      &:hover {
        .line--2 {
          transition-delay: 300ms;
          opacity: 0;
        }

        .line--3 {
          transition-delay: 100ms;
          opacity: 0;
        }
      }
    }

    &--engine {
      svg {
        overflow: visible;
        will-change: transform;
        transform-origin: center;

        .inner-bolt {
          will-change: transform;
          transform-origin: 60% 74%;
        }
      }

      &:hover svg {
        animation: engineWobble 375ms forwards cubic-bezier(0.68, -0.55, 0.265, 1.55);

        .inner-bolt {
          animation: boltScale 375ms forwards cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
      }

      @keyframes engineWobble {
        0% {
          transform: translateX(0px) scale(1);
        }
        25% {
          transform: translateX(6px);
        }
        50% {
          transform: translateX(0px) scale(1.1);
        }
        75% {
          transform: translateX(-6px);
        }
        100% {
          transform: translateX(0px) scale(1);
        }
      }

      @keyframes boltScale {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.8);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    &--click-start {
      svg {
        transition: transform 375ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transform-origin: 50% 50%;
        overflow: visible;

        .pointer-curve {
          transition: transform 375ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 325ms,
            opacity 375ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 325ms;
          transform-origin: 44px 18px;
        }
      }

      &:hover svg {
        transform: scale(0.8);

        .pointer-curve {
          transform: scale(2);
          opacity: 0;
        }
      }
    }

    &--emissions {
      svg {
        overflow: visible;
      }

      .ring {
        transition: transform 375ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 500ms ease;
        transform-origin: 50% 50%;
      }

      .car {
        opacity: 0;
        transition: transform 275ms 200ms ease-out, opacity 175ms 200ms ease;
        transform: translateX(-100px);
      }

      &:hover {
        .car {
          opacity: 1;
          transform: translateX(0px);
        }

        .ring {
          transform: scale(1.15);
        }
      }
    }

    &--warranty {
      svg {
        overflow: visible;
      }

      .ring {
        transition: transform 375ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 500ms ease;
        transform-origin: 50% 50%;
      }

      .shield {
        transition: transform 375ms 0ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transform-origin: 50% 50%;
      }

      &:hover {
        .ring {
          transform: scale(1.15);
          opacity: 0;
        }

        .shield {
          transform: scale(1.25);
          transition-delay: 200ms;
        }
      }
    }
  }
}
