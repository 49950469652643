@use '~tiny-slider/src/tiny-slider.scss';

@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';
@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-carousel {
  position: relative;

  &__title {
    @include animations.fade-up($mode: parent);
    @include fonts.lato-regular();
    display: block;
    color: brand.$grey-20;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: global.vr(3);

    @include mq(bp-sm) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: global.vr(6);
    }
  }

  &__item {
    @include animations.fade-up($mode: parent, $delay: 0.2s);
  }

  .tns-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: global.vr(3);
    outline: none;

    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s animation.$ease-in-out-cubic,
      transform 0.6s animation.$ease-in-out-cubic;

    @include mq(bp-sm) {
      position: absolute;
      top: 0;
      right: 0;
    }

    @at-root {
      .ds-carousel.animated .tns-controls {
        opacity: 1;
        transform: translateY(0);
      }
    }

    [data-controls] {
      display: block;
      background: #cddb00;
      border-radius: 50%;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      height: 40px;
      transition: box-shadow 0.3s animation.$ease-in-out-cubic,
        background-color 0.3s animation.$ease-in-out-cubic,
        transform 0.3s animation.$ease-in-out-cubic;
      transform: translateY(0);
      width: 40px;
      font-size: 13px;

      &:focus,
      &:hover {
        background: brand.$white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        text-decoration: none;
        transform: translateY(-2px);
      }

      svg {
        display: block;
        height: 12px;
        width: 16px;
        margin: 0 auto;
      }

      @include mq(bp-sm) {
        height: 50px;
        width: 50px;

        svg {
          display: block;
          height: 15px;
          width: 20px;
        }
      }
    }

    [data-controls='prev'] {
      svg {
        transform: rotate(180deg);
      }
    }

    [data-controls='next'] {
      margin-left: global.vr(2);
    }
  }

  .tns-ovh {
    padding-right: 100px;

    @include mq(bp-sm) {
      padding-right: 0;
    }
  }

  .tns-slider {
    transition-timing-function: animation.$ease-in-out-cubic;

    // fixing height issues
    display: flex;
    flex-wrap: nowrap;
  }

  .tns-visually-hidden {
    @include global.sr-only();
    left: auto !important; // remove style causing weird body height issues
  }
}
