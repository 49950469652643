@use '../../settings/breakpoints' as *;
@use '../../settings/brand' as brand;
@use '../../helpers/fonts';
@use '../../helpers/mixins';

.ds-modal-product {
  position: fixed;
  display: flex;
  align-items: center;
  background: rgba(brand.$black, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 300ms ease;

  &.open {
    opacity: 1;
    height: 100%;
    padding: 125px 20px;
    z-index: 11;

    .ds-modal-product__container {
      opacity: 1;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 44px;
    right: 0;
    width: 17px;
    height: 17px;
    background-image: url('~@img/icons/icon-cross--black.svg');
  }

  &__caption {
    display: block;
    font-size: 14px;
    line-height: 14px;
    color: brand.$black;
    margin-bottom: 14px;
    @include fonts.lato-regular-italic();
  }

  &__product-code {
    font-size: 13px;
    letter-spacing: 1px;
    color: brand.$grey-45;
    margin-bottom: 2px;
    @include fonts.lato-semi-bold();
  }

  &__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 22px;
    @include fonts.lato-regular();
  }

  &__container {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 796px;
    width: 100%;
    max-height: 100vh;
    max-height: calc(100vh - 194px);
    height: auto;
    padding: 0 20px;
    background: brand.$white;
    border: 10px solid brand.$green;
    overflow-y: auto;
    opacity: 0;
    transition: opacity 300ms ease 150ms;
    @include mixins.custom-scrollbar(transparent, brand.$white, brand.$grey-70);
  }

  &__inner {
    position: relative;
    padding: 44px 0 38px;
    margin: 0 auto;
    max-width: 510px;
    overflow: hidden;

    .ds-modal-split {
      @include mq($from: bp-xs) {
        display: flex;
        flex-direction: row;
        margin-bottom: 22px;
      }

      &--45-55 {
        .ds-modal-split__item {
          &:nth-child(1) {
            flex: 1 1 45%;
          }
          &:nth-child(2) {
            flex: 1 1 55%;
          }
        }
      }

      &__item {
        flex: 1 1 50%;

        @include mq($until: bp-xs) {
          &:first-child {
            margin-bottom: 20px;
          }
        }

        label {
          display: block;
          font-size: 13px;
          line-height: 13px;
          margin-bottom: 10px;
          color: brand.$black;
          @include fonts.lato-regular();
        }

        .ds-stars {
          color: brand.$green;

          &__item {
            height: 28px;
            width: 29px;
          }
        }
      }
    }

    p {
      font-size: 14px;
      line-height: 24px;
      @include fonts.lato-regular();
    }
  }

  &__confirm {
    display: inline-block;
    margin-top: 20px;
    width: 102px;
    height: 102px;
  }

  .ds-btn {
    margin-top: 40px;
    margin-left: auto;
    display: inherit;
  }

  .text-center .ds-btn {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }

  small {
    display: block;
    font-size: 11px;
    color: brand.$grey-30;
    @include fonts.lato-regular-italic();
  }
}
