@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';
@use '../helpers/mixins';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-typeahead {
  @include mixins.custom-scrollbar(transparent, brand.$white, brand.$grey-70);
  display: flex;
  flex-direction: column;
  margin: global.vr(1) 0;
  max-height: calc(86px * 4);
  overflow-y: scroll;

  &__item {
    display: flex;
    align-items: center;
    background: brand.$grey-95;
    cursor: pointer;
    flex-direction: row;
    margin-bottom: global.vr(1);
    padding: global.vr(0.5) global.vr(2) global.vr(0.5) global.vr(0.5);

    &.selected {
      order: -1;
      background: brand.$green;
    }

    &.added {
      background: brand.$grey-90;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-image {
      display: block;
      height: 70px;
      margin-right: global.vr(2);
      object-fit: contain;
      object-position: center center;
      font-family: 'object-fit: contain; object-position: center center;';
      width: 70px;
      flex-shrink: 0;
    }

    &-title-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    &-title {
      display: block;
      color: brand.$grey-20;
      @include fonts.lato-bold();
      font-size: 14px;
      line-height: 1em;
      margin-bottom: global.vr(1);
    }

    &-subtitle {
      display: block;
      color: brand.$grey-45;
      @include fonts.lato-regular();
      font-size: 13px;
      line-height: 1em;
      margin-bottom: 0;
    }

    &-icon {
      display: block;
      height: 16px;
      margin-left: auto;
      transform: rotate(0deg);
      transition: transform animation.$ease-in-out-cubic 0.3s;
      width: 16px;
      flex: 0 0 auto;

      .selected & {
        // full rotation + 45 degrees
        transform: rotate(405deg);
      }
    }
  }
}

.ds-typeahead-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin: global.vr(1) 0;

  &__item {
    padding: 0px 0px 10px 0px;
    width: 100%;

    @include mq($from: bp-md) {
      width: 33.33%;
      padding: 10px 10px 0px 0px;

      &:nth-child(3n) {
        padding-right: 0px;
      }
    }
  }
}
