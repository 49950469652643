@use '../../helpers/animations';
@use '../../helpers/global';

@use '../../settings/brand';
@use '../../settings/breakpoints' as *;

.ds-intro-content {
  & + #more-details {
    padding-top: global.vr(6);
  }

  &__inner {
    @include animations.fade-up();
  }

  p {
    font-size: 16px;

    @include mq(bp-md) {
      font-size: 18px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
