@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';
@use '../helpers/mixins';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ng-product-card {
  padding: 16px;
  background: brand.$white;
  border: 1px solid brand.$grey-90;

  @include mq($until: bp-md) {
    padding: 8px;
  }

  &__name {
    text-transform: uppercase;
    @include fonts.lato-bold();
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 0px;

    height: 42px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mq($until: bp-md) {
      height: 85px;
      -webkit-line-clamp: 4;
    }
  }

  &__model {
    @include fonts.lato-regular();
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 8px;
    line-height: 1.5;
    color: hsl(0, 0, 50);

    &:hover,
    &:focus {
      text-decoration: none;
    }

    @include mq(bp-lg) {
      font-size: 15px;
    }
  }

  &__tag {
    height: 25px;
    padding: 5px;
    display: inline-block;
    position: relative;
    line-height: 1;

    span {
      @include fonts.lato-bold();
      text-transform: uppercase;
      font-size: 9px;
      line-height: 1;
      vertical-align: middle;

      @include mq(bp-xs) {
        font-size: 13px;
      }

      @include mq(bp-lg) {
        font-size: 15px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 99%;
      width: 18px;
      height: 100%;
      clip-path: polygon(0 0, 100% 0, 0% 100%, 0% 100%);
    }

    &--red {
      background-color: brand.$error;
      color: brand.$white;

      &:after {
        background-color: brand.$error;
      }
    }

    &--green {
      background-color: brand.$green;
      color: #333;

      &:after {
        background-color: brand.$green;
      }
    }
  }

  &__price {
    @include fonts.lato-bold();
    line-height: 22px;
    font-size: 21px;
    margin-bottom: 0px;
    height: 22px;

    &.on-sale {
      color: brand.$error;

      &:hover {
        color: brand.$error;
      }
    }
  }

  &__link {
    display: block;
    color: inherit;

    &:hover {
      .ng-product-card__media-holder {
        &.ng-product-card__media-holder--flip {
          .ng-product-card__application {
            transform: rotateY(0deg);
            display: block !important;
          }

          .ng-product-card__hero {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }

  &__price,
  &__link {
    &:hover,
    &:focus {
      text-decoration: none;
      color: inherit;
    }
  }

  &__media-holder {
    position: relative;
    height: 0px;
    padding-bottom: 100%;

    &--flip {
      transform-style: preserve-3d;
      position: relative;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &.ng-product-card__application,
      &.ng-product-card__hero {
        backface-visibility: hidden;
      }

      &.ng-product-card__hero {
        z-index: 2;
        transform: rotateY(0deg);
      }

      &.ng-product-card__application {
        transform: rotateY(-180deg);
        display: none;
      }
    }
  }

  &__pricing {
    display: flex;
    flex-direction: column;

    span {
      @include fonts.lato-bold();
      font-size: 14px;
      height: 22px;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    em {
      text-decoration: line-through;
      font-style: normal;
    }
  }

  hr {
    margin: 16px 0px;
    border: none;
    outline: none;
    height: 1px;
    background: brand.$grey-85;
  }

  // A bit hacky but this will get removed when we restyle the listing pages anyway
  .ds-form__field--checkbox {
    input[type='checkbox']:checked + label.ds-form__label:before {
      background-color: #54585a;
    }

    label.ds-form__label {
      color: #313131;
      font-size: 14px;
      @include fonts.lato-bold();
      user-select: none;
      margin-bottom: 0px;

      &:before {
        border: 1px solid #54585a !important;
      }
    }
  }
}
