@use '../helpers/animations';
@use '../helpers/fonts';
@use '../helpers/global';

@use '../settings/animation';
@use '../settings/brand';
@use '../settings/breakpoints' as *;

.ds-media-list {
  display: flex;
  flex-direction: column;
}

.ds-media {
  display: flex;
  align-items: center;
  background: brand.$brand-cddb00;
  flex-direction: row;
  padding: global.vr(0.5) global.vr(2) global.vr(0.5) global.vr(0.5);

  & + & {
    margin-top: global.vr(1);
  }

  &__thumb {
    display: block;
    height: 70px;
    width: 70px;
  }

  &__title-block {
    display: flex;
    flex-direction: column;
    margin-left: global.vr(2);
  }

  &__title {
    color: brand.$grey-20;
    @include fonts.lato-bold();
    font-size: 14px;
    line-height: 1em;
    margin-bottom: global.vr(1);
    text-transform: uppercase;
  }

  &__subtitle {
    color: brand.$grey-50;
    @include fonts.lato-regular();
    font-size: 13px;
    line-height: 1em;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &__link {
    color: brand.$grey-40;
    @include fonts.lato-bold();
    font-size: 13px;
    line-height: 1em;
    margin-left: auto;
    margin-bottom: 0;

    svg {
      display: inline-block;
      height: 10px;
      transform: rotate(-90deg);
      width: 12px;
    }
  }
}
