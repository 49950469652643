@use '../../helpers/animations';
@use '../../helpers/fonts';
@use '../../helpers/global';

@use '../../settings/animation';
@use '../../settings/brand';
@use '../../settings/breakpoints' as *;

.ds-most-popular {
  @include mq($until: bp-sm) {
    padding-top: global.vr(4);
  }

  &__title {
    display: block;
    @include fonts.lato-black();
    font-size: 24px;
    @include fonts.ls(100);
    line-height: 45px;
    margin-bottom: 18px;
  }
}

.fan-de-section {
  padding-top: 0;
  margin-top: 0;

  @include mq($until: bp-sm) {
    margin-top: 0;

    h2 {
      font-size: 24px;
    }
  }

  .ds-section__title {
    margin-top: global.vr(6);
  }

  .ds-form {
    &__field {
      display: block;
    }

    &__label {
      display: inline-flex;
      max-width: none;
      text-transform: none;
      font-size: 15px;
      @include fonts.lato-bold;
      color: brand.$grey-35;
      letter-spacing: 0.5px;
    }

    &__control {
      display: inline-flex;
      @include fonts.lato-bold;
      font-size: 15px;
      max-width: none;
      width: auto;
      outline: none;
      color: brand.$grey-35;
      border-color: brand.$grey-35;
      padding-right: 30px;
      background: url('~@img/icons/icon-angle-down.svg') no-repeat right 10px center;

      &::-ms-expand {
        display: none;
      }
    }
  }
}
