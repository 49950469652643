@use './settings/breakpoints' as *;
@use './settings/brand' as brand;
@use './helpers/fonts';
@use './helpers/global';
@use './helpers/animations';

.ds-oneplus-categories {
  padding: global.vr(6) 0;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content-container {
    max-width: 610px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: global.vr(4);
  }

  &__title {
    @include animations.fade-up($mode: parent);

    @include fonts.lato-bold();
    font-size: 20px;
    color: brand.$grey-max-power;
  }

  &__content {
    @include animations.fade-up($mode: parent);

    @include fonts.lato-regular();
    font-size: 16px;
    color: brand.$grey-max-power;
    text-align: center;
  }

  &__items {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__item {
    @include animations.fade-up($mode: self, $delay: 0.4s);
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: global.vr(3);

    &-container {
      max-width: 290px;
    }

    &-media-container {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
        background-color: brand.$grey-max-power;
      }
    }

    &-logo {
      position: absolute;
      width: 94px;
      height: 29px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &-icon {
      width: 80px;
      height: 80px;
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }

    &-content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: brand.$brand-primary;
      overflow: hidden;
      min-height: 170px;
      padding: global.vr(5) 0 global.vr(2);

      &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 150%;
      }

      &::before {
        background: #abb92f;
        height: 270px;
        opacity: 0.5;
        transform: skewY(-14deg) translateY(60%);
        transform-origin: bottom right;
        transition-delay: 0.1s;
        z-index: 2;
      }

      &::after {
        background: #c7d63c;
        height: 180px;
        transform: skewY(10deg) translateY(50%);
        transform-origin: bottom left;
        transition-delay: 0.2s;
        z-index: 1;
      }
    }

    &-content {
      position: relative;
      padding: 0 global.vr(2);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 5;
    }

    &-title {
      font-size: 22px;
      @include fonts.lato-regular();
      color: brand.$grey-max-power;
      padding-bottom: global.vr(1);
      text-align: center;
    }
  }
}
